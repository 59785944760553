import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P69-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page69/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/3.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page69/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/5.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page69/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/7.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page69/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/11.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page69/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/13.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page69/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/15.jpg', input: true, answer: "U" },
        { url: 'img/FriendsPlus/Page69/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page69/E1/17.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page69/E1/18.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E1/19.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P69-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 5,
    exerciseKey: 'img/FriendsPlus/Page69/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page69/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page69/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page69/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page69/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page69/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page69/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page69/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page69/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page69/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page69/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page69/E2/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page69/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page69/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/25.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page69/E2/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page69/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/30.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/31.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page69/E2/32.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page69/E2/33.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/34.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/35.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/36.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page69/E2/37.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page69/E2/38.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/39.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/40.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/41.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page69/E2/42.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page69/E2/43.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/44.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page69/E2/45.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/46.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page69/E2/47.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page69/E2/48.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/49.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page69/E2/50.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/51.jpg' },
        { url: 'img/FriendsPlus/Page69/E2/52.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page69/E2/53.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P69-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page69/E3/Key/answerKey.png',
    inputSize: 120,
    titleImage: "img/FriendsPlus/Page69/E3/1.jpg",
    // titleQuestion: [{ num: '3', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div>“Quick!” says Dad. “We don't have <sup>1</sup><u style="color: gray;"> much </u> time.” We all pick up our bags. We have</div>
          <div><sup>2</sup> #  luggage. There are some carts, but you need money to use them. Dad</div>
          <div>looks in his pocket. “I don't have <sup>3</sup> #  money,” he says. Luckily, Mom has</div>
          <div><sup>4</sup> #  money. Then we run to the departure gate. When we get there, there</div>
          <div>aren’t <sup>5</sup> #  passengers. A man says, “Sorry, flight A A387 is late.”</div>
        </div>
        `,
        answer: [
          "lots of",
          "much",
          "lots of",
          "many",
        ],
      },
    ],
  },
}

export default json;