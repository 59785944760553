import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P62-E1',
    audio: 'img/FriendsPlus/Page62/Audio/audio-e1-p62.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page62/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Listen and draw lines from the day to the correct picture.<headphone name="18" src="img/FriendsPlus/Page62/Audio/tieude-e1-p62.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '63px', left: '78px', width: 130, height: 120, }, }, //0
          { boxMatchStyle: { position: 'absolute', top: '191px', left: '77px', width: 130, height: 120, }, }, //1

          { boxMatchStyle: { position: 'absolute', top: '99px', left: '370px', width: 150, height: 40, }, }, //2
          { boxMatchStyle: { position: 'absolute', top: '157px', left: '372px', width: 150, height: 40, }, }, //3
          { boxMatchStyle: { position: 'absolute', top: '213px', left: '371px', width: 150, height: 40, }, }, //4
          { boxMatchStyle: { position: 'absolute', top: '272px', left: '373px', width: 150, height: 40, }, }, //5
          { boxMatchStyle: { position: 'absolute', top: '327px', left: '375px', width: 150, height: 40, }, }, //6

          { boxMatchStyle: { position: 'absolute', top: '63px', left: '663px', width: 130, height: 120, }, }, //7
          { boxMatchStyle: { position: 'absolute', top: '188px', left: '664px', width: 130, height: 120, }, }, //8
          { boxMatchStyle: { position: 'absolute', top: '308px', left: '664px', width: 130, height: 120, }, }, //9

        ],
        answers: ['0-2', '1-3', '6-7', '4-8', '5-9'],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page62/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />

          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />

          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P62-E2',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page62/E2/Key/answerKey.png',
    inputSize: 700,
    // titleImage: "img/FriendsPlus/Page62/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Where will you be at these times?', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="  line-height: 58px;">
          <div><b>1</b> (This evening) <u style="color: gray;">&emsp; This evening, I will be &emsp;</u></div>
          <div><b>2</b> (Four years) #</div>
          <div><b>3</b> (Tomorrow) #</div>
          <div><b>4</b> (Next vacation) #</div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P62-E3',
    audio: 'img/FriendsPlus/Page62/Audio/audio-e3-p62.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page62/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page62/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page62/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page62/Audio/tieude-e3-p62.mp3' },
        { url: 'img/FriendsPlus/Page62/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page62/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page62/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page62/E3/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page62/E3/7.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page62/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page62/E3/9.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page62/E3/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page62/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page62/E3/12.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page62/E3/13.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page62/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page62/E3/15.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page62/E3/16.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page62/E3/17.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P62-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page62/E4/Key/answerKey.png',
    inputSize: 700,
    // titleImage: "img/FriendsPlus/Page62/E4/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="  line-height: 58px;">
          <div><b>1</b> (sauce) #</div>
          <div><b>2</b> (jigsaw) #</div>
          <div><b>3</b> (straw) #</div>
          <div><b>4</b> (August) #</div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },

}

export default json;