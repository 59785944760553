import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
	1: { // Exercise num
		unit: 'Unit 2',
		id: 'WB5-U2-P19-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		totalInput: 5,
		exerciseKey: 'img/FriendsPlus/Page19/E1/Key/answerKey.png',
		titleImage: '',
		isAllowSubmit: true,
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page19/E1/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page19/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page19/E1/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page19/E1/4.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page19/E1/5.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page19/E1/6.jpg' },
				{ url: 'img/FriendsPlus/Page19/E1/7.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page19/E1/8.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page19/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page19/E1/10.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page19/E1/11.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page19/E1/12.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page19/E1/13.jpg' },
				{ url: 'img/FriendsPlus/Page19/E1/14.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page19/E1/15.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page19/E1/16.jpg' },
				{ url: 'img/FriendsPlus/Page19/E1/17.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page19/E1/18.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page19/E1/19.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 2',
		id: 'WB5-U2-P19-E2',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page19/E2/Key/answerKey.png',
		component: D1,
		padding: 0,
		textAlign: 'center',
		maxLength: 1,
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page19/E2/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page19/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page19/E2/3.jpg', input: true, answer: "4" },
				{ url: 'img/FriendsPlus/Page19/E2/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page19/E2/5.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page19/E2/6.jpg' },
				{ url: 'img/FriendsPlus/Page19/E2/7.jpg', input: true, answer: "2" },
				{ url: 'img/FriendsPlus/Page19/E2/8.jpg' },
				{ url: 'img/FriendsPlus/Page19/E2/9.jpg', input: true, answer: "3" },
				{ url: 'img/FriendsPlus/Page19/E2/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page19/E2/11.jpg' },
			],
		],
	},
	3: { // Exercise num
		unit: 'Unit 2',
		id: 'WB5-U2-P19-E3',
		audio: '',
		video: '',
		component: T6,
		// exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
		hideBtnFooter: true,
		inputSize: 750,
		// titleImage: 'img/FriendsPlus/Page18/E1/1.jpg',
		titleQuestion: [{ num: '3', title: 'What are you going to do on the weekend? Write an email to Tom.', color: "#5B5A5D" }],
		questionImage: [],
		questions: [
			{
				title:
					`
					<div style='line-height: 58px;'>
						<div>#</div>
						<div>#</div>
						<div>#</div>
						<div>#</div>
						<div>#</div>
						<div>#</div>
						<div>#</div>
						<div>#</div>
					</div>   
            `,
				answer: [
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
				],
			},
		]
	},
}

export default json;