import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P76-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page76/E1/Key/answerKey.png',
    inputSize: 80,
    textAlign: 'center',
    maxLength: 1,
    // titleImage: "img/FriendsPlus/Page76/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read the conversation between Tam and Nga and choose the best answer.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;width: 1000px;">
          <div>
            <div><b>1</b> You like swimming, don’t you, Nga? <u style="color: gray;">&ensp; e &ensp;</u></div>
            <div><b>2</b> Wow! Does your sister like swimming too?</div>
            <div style=" margin-left: 20px; ">#</div>
            <div><b>3</b> How often does she play soccer?</div>
            <div style=" margin-left: 20px; ">#</div>
            <div><b>4</b> How often do they win?#</div>
            <div><b>5</b> She’s very busy. And how about you? How</div>
            <div style=" margin-left: 20px; ">often do you have swimming competitions?</div>
            <div style=" margin-left: 20px; ">#</div>
            <div><b>6</b> Good luck! #</div>
          </div>
          <div>
            <div><b>a</b> Twice a week. Her team practices</div>
            <div style=" margin-left: 20px; ">on Thursdays and plays a match on</div>
            <div style=" margin-left: 20px; ">Saturdays.</div>
            <div><b>b</b> I have competitions twice a month</div>
            <div style=" margin-left: 20px; ">usually. I have a race tomorrow.</div>
            <div><b>c</b> I never go swimming.</div>
            <div><b>d</b> No. She never goes swimming. She</div>
            <div style=" margin-left: 20px; ">likes soccer.</div>
            <div><b>e</b> Yes. I go every day.</div>
            <div><b>f</b> Usually three times a month. They</div>
            <div style=" margin-left: 20px; ">are quite good.</div>
            <div><b>g</b> Thank you.</div>
          </div>
        </div>
        `,
        answer: [
          "d",
          "a",
          "f",
          "b",
          "g",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P76-E2',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page76/E2/Key/answerKey.png',
    inputSize: 450,
    // titleImage: "img/FriendsPlus/Page76/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write about you.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; ">
          <div style=" line-height: 48px; ">
            <div><b>1</b> How often do you have math at school?</div>
            <div><b>2</b> How often do you make your bed?</div>
            <div><b>3</b> How often do you go on a plane?</div>
          </div>
          <div style=" line-height: 48px; ">
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P76-E3',
    audio: 'img/FriendsPlus/Page76/Audio/audio-e1-p76.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page76/E3/Key/answerKey.png',
    inputSize: 80,
    // titleImage: "img/FriendsPlus/Page76/E3/1.jpg",
    titleQuestion: [{ num: '1', title: 'Listen and write.<headphone name="23" src="img/FriendsPlus/Page76/Audio/tieude-e1-p76.mp3"></headphone>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="display: flex;flex-direction: row;justify-content: space-between;width: 650px; line-height: 58px;">
          <div>
            <div><b>1</b> moth <u style="color: gray;">&ensp; er &ensp;</u></div>
            <div><b>3</b> fath #</div>
            <div><b>5</b> teach #</div>
          </div>
          <div>
            <div><b>2</b> visit#</div>
            <div><b>4</b> act#</div>
            <div><b>6</b> Decemb#</div>
          </div>
        </div>
        `,
        answer: [
          "er",
          "er",
          "or",
          "or",
          "er",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P76-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page76/E4/Key/answerKey.png',
    inputSize: 700,
    // titleImage: "img/FriendsPlus/Page76/E4/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence for each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="  line-height: 58px;">
            <div><b>1</b> (father) #</div>
            <div><b>2</b> (doctor) #</div>
            <div><b>3</b> (October) #</div>
          </div>
        `,
        answer: [
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;