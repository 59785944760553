import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MatchText1 from '../../components/ExcerciseTypes/LineTo/MatchText1';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  // 1: { // Exercise num
  //   unit: 'Unit 2',
  //   id: 'WB5-U2-P16-E1',
  //   audio: '',
  //   video: '',
  //   exerciseKey: 'img/FriendsPlus/Page16/E1/Key/answerKey.png',
  //   component: D1,
  //   padding: 0,
  //   textAlign: 'center',
  //   maxLength: 1,
  //   questionImage: [ // Row
  //     [
  //       // Column1
  //       { url: 'img/FriendsPlus/Page16/E1/1.jpg' },
  //     ],
  //     [
  //       // Column2
  //       { url: 'img/FriendsPlus/Page16/E1/2.jpg' },
  //       { url: 'img/FriendsPlus/Page16/E1/3.jpg', input: true, answer: "c" },
  //       { url: 'img/FriendsPlus/Page16/E1/4.jpg' },
  //     ],
  //     [
  //       // Column3
  //       { url: 'img/FriendsPlus/Page16/E1/5.jpg' },
  //       { url: 'img/FriendsPlus/Page16/E1/6.jpg', input: true, answer: "a" },
  //       { url: 'img/FriendsPlus/Page16/E1/7.jpg' },
  //     ],
  //     [
  //       // Column4
  //       { url: 'img/FriendsPlus/Page16/E1/8.jpg' },
  //       { url: 'img/FriendsPlus/Page16/E1/9.jpg', input: true, answer: "e" },
  //       { url: 'img/FriendsPlus/Page16/E1/10.jpg' },
  //     ],
  //     [
  //       // Column5
  //       { url: 'img/FriendsPlus/Page16/E1/11.jpg' },
  //       { url: 'img/FriendsPlus/Page16/E1/12.jpg', input: true, answer: "d" },
  //       { url: 'img/FriendsPlus/Page16/E1/13.jpg' },
  //     ],
  //     [
  //       // Column6
  //       { url: 'img/FriendsPlus/Page16/E1/14.jpg' },
  //     ],

  //   ],
  // },
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P16-E1',
    audio: '',
    video: '',
    component: MatchText1,
    exerciseKey: 'img/FriendsPlus/Page16/E1/Key/answerKey.png',
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Look at the picture in the Student Book. Match.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        right: [
          { id: 1, text: "1. Is Nam going to take a towel?", },
          { id: 2, text: "2. Is Kim going to take a toothbrush?", },
          { id: 3, text: "3. Are Kim and Duy going to take some sunscreen?", },
          { id: 4, text: "4. Are Nam and Lam going to take some toothpaste?", },
          { id: 5, text: "5. Is Lam going to take some soap?", },
        ],
        left: [
          { id: 1, text: "a. Yes, they are.", },
          { id: 2, text: "b. Yes, he is.", },
          { id: 3, text: "c. No, she isn’t.", },
          { id: 4, text: "d. Yes, he is", },
          { id: 5, text: "e. No, they aren’t.", },
        ],
        answer: [
          { right: 1, left: 2, },
          { right: 2, left: 3, },
          { right: 3, left: 1, },
          { right: 4, left: 5, },
          { right: 5, left: 4, },
        ],
        type: ''
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P16-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page16/E2/Key/answerKey.png',
    inputSize: 400,
    // titleImage: 'img/FriendsPlus/Page15/E1/title.jpg',
    titleQuestion: [{ num: '2', title: 'Write a sentence with <i>is, isn’t, are,</i> or <i>aren’t</i>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div> <img src='img/FriendsPlus/Page16/E2/1.jpg' /> </div>
            <div style=" margin: auto 0px ; ">Nhan <u style=" color: gray; ">is going to take some shampoo</u>.</div>
          </div>        
          <div style=" display: flex; flex-direction: row; ">
            <div> <img src='img/FriendsPlus/Page16/E2/2.jpg' /> </div>
            <div style=" margin: auto 0px ; ">Nga and Hoang #.</div>
          </div>        
          <div style=" display: flex; flex-direction: row; ">
            <div> <img src='img/FriendsPlus/Page16/E2/3.jpg' /> </div>
            <div style=" margin: auto 0px ; ">Hoang #.</div>
          </div>        
          <div style=" display: flex; flex-direction: row; ">
            <div> <img src='img/FriendsPlus/Page16/E2/4.jpg' /> </div>
            <div style=" margin: auto 0px ; ">Nhan #.</div>
          </div>        
          `,
        answer: [
          // "",
          "are going to take a camera",
          "is going to take some sunscreen",
          "isn't going to take any toothbrush",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P16-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page16/E3/Key/answerKey.png',
    inputSize: 120,
    titleImage: 'img/FriendsPlus/Page16/E3/1.jpg',
    // titleQuestion: [{ num: '3', title: 'Write', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div>I’m going to pack my <sup>1</sup> <u style="color:gray;">suitcase</u> ,</div>
              <div>I’m going to the station.</div>
              <div>I’m going to have a great <sup>2</sup># ,</div>
              <div>I’m going on <sup>3</sup># !</div>
              <div>I’m going to <sup>4</sup># some sunscreen,</div>
              <div>And also some shampoo.</div>
              <div>I’m <sup>5</sup># to take my toothbrush,</div>
              <div>And <sup>6</sup># toothpaste, too.</div>
              <div>I’m going to take some <sup>7</sup>#,</div>
              <div>Of everything I see.</div>
              <div>I’m going <sup>8</sup># swim and windsurf,</div>
              <div>I’m going to waterski!</div>
            </div>
            <div> <img src='img/FriendsPlus/Page16/E3/2.jpg' /> </div>
          </div>        
          
          `,
        answer: [
          // "suitcase",
          "time",
          "vacation",
          "take",
          "going",
          "some",
          "photos",
          "to",
        ],
      },
    ]
  },

}

export default json;