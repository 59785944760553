import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import TextEditer from '../../components/ExcerciseTypes/TextEditer';


const json = {

  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P24-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page24/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page24/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page24/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/3.jpg', input: true, answer: "coffee/noodles/server/breakfast" },
        { url: 'img/FriendsPlus/Page24/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/5.jpg', input: true, answer: "umbrella/customer/volleyball/banana" },
        { url: 'img/FriendsPlus/Page24/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/7.jpg', input: true, answer: "motorcycle/supermarket/helicopter" },
        { url: 'img/FriendsPlus/Page24/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page24/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/10.jpg', input: true, answer: "toast/plate/bean" },
        { url: 'img/FriendsPlus/Page24/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/12.jpg', input: true, answer: "coffee/noodles/server/breakfast" },
        { url: 'img/FriendsPlus/Page24/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/14.jpg', input: true, answer: "umbrella/customer/volleyball/banana" },
        { url: 'img/FriendsPlus/Page24/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/16.jpg', input: true, answer: "motorcycle/supermarket/helicopter" },
        { url: 'img/FriendsPlus/Page24/E1/17.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page24/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/19.jpg', input: true, answer: "toast/plate/bean" },
        { url: 'img/FriendsPlus/Page24/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/21.jpg', input: true, answer: "coffee/ noodles/ server/ breakfast" },
        { url: 'img/FriendsPlus/Page24/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/23.jpg', input: true, answer: "umbrella/customer/volleyball/banana" },
        { url: 'img/FriendsPlus/Page24/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/25.jpg', input: true, answer: "motorcycle/supermarket/helicopter" },
        { url: 'img/FriendsPlus/Page24/E1/26.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page24/E1/27.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/28.jpg', input: true, answer: "toast/plate/bean" },
        { url: 'img/FriendsPlus/Page24/E1/29.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/30.jpg', input: true, answer: "coffee/ noodles/ server/ breakfast" },
        { url: 'img/FriendsPlus/Page24/E1/31.jpg' },
        { url: 'img/FriendsPlus/Page24/E1/32.jpg', input: true, answer: "umbrella/customer/volleyball/banana" },
        { url: 'img/FriendsPlus/Page24/E1/33.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page24/E1/34.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P24-E2',
    audio: '',
    video: '',
    component: TextEditer,
    exerciseKey: 'img/FriendsPlus/Page24/E2/Key/answerKey.png',
    titleImage: "img/FriendsPlus/Page24/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Read the text. Change to capital letters. Add periods, commas, and question marks', color: "#5B5A5D" }],
    questionImage: [],
    question: {
      editer: {
        editerStyle: {},
        initialValue: 'I always eat lunch at school i usually have cheese \n and tomato sandwiches fruit and a yogurt \n During the week my grandma usually cooks dinner \n at home i always help her i like cooking do you \n like cooking \n On weekends we often go to a restaurant for \n lunch the restaurant is called the food garden \n What food do you like',
        answer: 'I always eat lunch at school. I usually have \n cheese and tomato sandwiches, fruit, and a \n yogurt. \n During the week my grandma usually cooks \n dinner at home. I always help her. I like cooking. \n Do you like cooking? \n On weekends we often go to a restaurant for \n lunch. The restaurant is called The Food Garden. \n What food do you like?',
        // initialValue: `1 "Goodbye," says the teacher.\n2 I'm taking a photo, says the boy.\n3 Open your books, she says. Read the story.\n4 The monkeys are my favorite animals, says the girl.`,
        // answer: `1 "Goodbye," says the teacher.\n2 "I'm taking a photo," says the boy.\n3 "Open your books," she says. "Read the story".\n4 "The monkeys are my favorite animals," says the girl.`,
      },
      Layout: `
      <div>
        <div style=" display: flex; flex-direction: row; line-height: 48px;">
          <div >
            <div><img src='img/FriendsPlus/Page24/E2/2.jpg' /></div>
            <div style=" width: 700px; "> <editer></editer> </div>
          </div>          
          <div><img src='img/FriendsPlus/Page24/E2/3.jpg' /></div>
        </div>
      </div>
      `
    },
  }
}

export default json;