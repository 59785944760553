import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P60-E1',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page60/E1/Key/answerKey.png',
    inputSize: 30,
    // titleImage: "img/FriendsPlus/Page60/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read the sentences and number the picture.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page60/E1/1.jpg' /> </div>
          <div style=" position: absolute; top: 66px; left: 112px; "><input id='0' /></div>
          <div style=" position: absolute; top: 68px; left: 359px; "><input id='1' /></div>
          <div style=" position: absolute; top: 98px; left: 509px; "><input id='2' /></div>
          <div style=" position: absolute; top: 138px; left: 622px; "><input id='3' /></div>
          <div style=" position: absolute; top: 211px; left: 324px; "><input id='4' /></div>
          <div style=" position: absolute; top: 327px; left: 262px; "><input id='5' /></div>
          <div style=" position: absolute; top: 326px; left: 661px; "><input id='6' /></div> 
        </div>
        `,
        answer: [
          "3",
          "8",
          "5",
          "4",
          "7",
          "2",
          "6",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P60-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page60/E2/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page60/E2/1.jpg",
    // titleQuestion: [{ num: '2', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div>In the (1) <u style='color: gray;'> future </u> , you won’t have to be an (2) # to (3) # in space.</div>
          <div>Long, thin (4) # will take off from Earth with people inside. Some people will</div>
          <div>travel in big (5) # . The people will look out of the windows and see the big</div>
          <div>round (6) # , and lots of little shining (7) # . But they won’t go</div>
          <div>near the (8) # . It’s too hot.</div>
        </div> 
        `,
        answer: [
          "astronaut",
          "travel",
          "rockets",
          "spaceships",
          "planets",
          "stars",
          "sun",
        ],
      },
    ],
  },
}

export default json;