import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P22-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page22/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Circle the time markers. Write <i>PP</i> (present progressive) or <i>SP</i> (simple present).', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: ['PP', 'PP', 'SP', 'PP', 'SP'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          'She’s working at_the_moment.', //0
          'Today we’re eating at a café.', //1
          'I never go to school by car.', //2
          'They’re watching TV right_now.', //3
          'He always wears a hat.', //4
        ],
        answers: ['0-4', '1-0', '2-2', '3-6', '4-2'],
        // initialValue: ['0-4', '1-0', '2-2', '3-6', '4-2'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;font-size: 22px;justify-content: space-between;width: 950px; line-height: 58px;">
        <div>
          <div><b>1</b> I <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">sometimes</span> visit my cousins. <u style="color:gray"> SP &ensp;</u> </div>
          <div><b>3</b> <input id='1' type='Circle' /><input id='1' /></div>
          <div><b>5</b> <input id='3' type='Circle' /><input id='3' /></div>
        </div>
        <div>
          <div><b>2</b> <input id='0' type='Circle' /><input id='0' /></div>
          <div><b>4</b> <input id='2' type='Circle' /><input id='2' /></div>
          <div><b>6</b> <input id='4' type='Circle' /><input id='4' /></div>
        </div>
      </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P22-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page22/E2/Key/answerKey.png',
    inputSize: 500,
    // titleImage: 'img/FriendsPlus/Page22/E2/title.jpg',
    titleQuestion: [{ num: '2', title: 'Write sentences.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div style='line-height: 58px;'>
              <div><b>1</b> (right now / play volleyball) </div>
              <div>&ensp;<u style='color: gray;'> Giang is playing volleyball right now. </u></div>              
              <div><b>2</b> (now / walk to school) </div>
              <div>&ensp; He # </div>              
              <div><b>3</b> (visit my grandma / always / on Fridays </div>
              <div>&ensp; We # </div>              
              <div><b>4</b> (listen to radio / rarely) </div>
              <div>&ensp;  Khang and Sue # </div>              
              <div><b>5</b> (read new book / today) </div>
              <div>&ensp; I # </div>              
            </div>            
          `,
        answer: [
          "is walking to school now.",
          "always visit my grandma on Fridays.",
          "rarely listen to the radio.",
          "'m reading a new book today.",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P22-E3',
    audio: 'img/FriendsPlus/Page22/Audio/audio-e3-p22.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page22/E3/Key/answerKey.png',
    component: D1,
    checkUppercase: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page22/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page22/Audio/tieude-e3-p22.mp3' },
        { url: 'img/FriendsPlus/Page22/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page22/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page22/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/6.jpg', input: true, answer: "train/rain/tray/cake/race" },
        { url: 'img/FriendsPlus/Page22/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/8.jpg', input: true, answer: "train/rain/tray/cake/race" },
        { url: 'img/FriendsPlus/Page22/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/10.jpg', input: true, answer: "happy/feet/leaves/tree/please/key" },
        { url: 'img/FriendsPlus/Page22/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/12.jpg', input: true, answer: "happy/feet/leaves/tree/please/key" },
        { url: 'img/FriendsPlus/Page22/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/14.jpg', input: true, answer: "happy/feet/leaves/tree/please/key" },
        { url: 'img/FriendsPlus/Page22/E3/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page22/E3/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page22/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/18.jpg', input: true, answer: "train/rain/tray/cake/race" },
        { url: 'img/FriendsPlus/Page22/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/20.jpg', input: true, answer: "train/rain/tray/cake/race" },
        { url: 'img/FriendsPlus/Page22/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/22.jpg', input: true, answer: "train/rain/tray/cake/race" },
        { url: 'img/FriendsPlus/Page22/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/24.jpg', input: true, answer: "happy/feet/leaves/tree/please/key" },
        { url: 'img/FriendsPlus/Page22/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/26.jpg', input: true, answer: "happy/feet/leaves/tree/please/key" },
        { url: 'img/FriendsPlus/Page22/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page22/E3/28.jpg', input: true, answer: "happy/feet/leaves/tree/please/key" },
        { url: 'img/FriendsPlus/Page22/E3/29.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page22/E3/30.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P22-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page22/E4/Key/answerKey.png',
    inputSize: 700,
    // titleImage: 'img/FriendsPlus/Page22/E2/title.jpg',
    titleQuestion: [{ num: '4', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div style='line-height: 58px;'>
              <div><b>1</b> (train) #</div>
              <div><b>2</b> (key) #</div>
              <div><b>3</b> (cake) #</div>
              <div><b>4</b> (tree) #</div>              
            </div>            
          `,
        answer: [
          "is walking to school now.",
          "always visit my grandma on Fridays.",
          "rarely listen to the radio.",
          "'m reading a new book today.",
        ],
      },
    ]
  },
}

export default json;