import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import CreateImageCode from '../../components/ExcerciseTypes/Design/CreateImageCode'

const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P75-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page75/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Circle the infinitives.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 2, border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        // limitSelect: 1,
        listWords: [
          "She went into the kitchen to_eat some food.", //0
          "My dad came into my room to_help with my homework.", //1
          "I put my books on the shelves to_tidy my room.", //2
          "I phoned my grandma to_say “Happy birthday!”", //3
          "My dad used the computer to_write an email.", //4
        ],
        answers: ['0-10', '1-12', '3-8', '2-14', '4-10'],
        initialValue: [],
      },
      Layout: `      
      <div style=" line-height: 48px; ">
          <div><b>1</b> I went to the movie theater <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">to watch</span> a movie. </div>
          <div><b>2</b> <input id='0' type='Circle' /> </div>
          <div><b>3</b> <input id='1' type='Circle' /> </div>
          <div><b>4</b> <input id='2' type='Circle' /> </div>
          <div><b>5</b> <input id='3' type='Circle' /> </div>
          <div><b>6</b> <input id='4' type='Circle' /> </div>
        </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P75-E2',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    exerciseKey: 'img/FriendsPlus/Page75/E2/Key/answerKey.png',
    inputSize: 260,
    // titleImage: "img/FriendsPlus/Page75/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Complete the sentences using an infinitive.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" position: relative; ">
            <div> <img src='img/FriendsPlus/Page75/E2/1.jpg' /> </div>
            <div style="position: absolute; top: 148px ; left: 318px ;">#</div>
            <div style="position: absolute; top: 147px ; left: 658px ;">#</div>
            <div style="position: absolute; top: 348px ; left: 13px ;">#</div>
            <div style="position: absolute; top: 348px ; left: 317px ;">#</div>
            <div style="position: absolute; top: 348px ; left: 649px ;">#</div> 
          </div>
        `,
        answer: [
          "to learn about spaces",
          "to ride bikes",
          "to look up words",
          "to cook",
          "to buy some food",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P75-E3',
    audio: 'img/FriendsPlus/Page75/Audio/audio-e3-p75.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page75/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page75/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/2.jpg', audioUrl: "img/FriendsPlus/Page75/Audio/tieude-e3-p75.mp3" },
        { url: 'img/FriendsPlus/Page75/E3/3.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/4.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/7.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page75/E3/10.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/12.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page75/E3/13.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/15.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page75/E3/16.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/17.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/19.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page75/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/22.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/24.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page75/E3/25.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/26.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page75/E3/27.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/28.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/29.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page75/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/31.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page75/E3/32.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/33.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page75/E3/36.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page75/E3/37.jpg' }
      ],
      [
        { url: 'img/FriendsPlus/Page75/E3/38.jpg' }
      ]

    ],
  },

}

export default json;