import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P86-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page86/E1/Key/answerKey.png',
    inputSize: 220,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    // titleImage: "img/FriendsPlus/Page86/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read the underlined words. Write them in the correct boxes.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page86/E1/1.jpg' /> </div>
          <div style=" position: absolute; top: 316px; left: 45px; ">#</div>
          <div style=" position: absolute; top: 352px; left: 45px; ">#</div>
          <div style=" position: absolute; top: 279px; left: 351px; ">#</div>
          <div style=" position: absolute; top: 316px; left: 351px; ">#</div>
          <div style=" position: absolute; top: 352px; left: 351px; ">#</div>
          <div style=" position: absolute; top: 279px; left: 660px; ">#</div>
          <div style=" position: absolute; top: 315px; left: 660px; ">#</div>
          <div style=" position: absolute; top: 352px; left: 660px; ">#</div>
        </div>
        `,
        answer: [
          "played a trick on",
          "slept",

          "disappointed",
          "tired",
          "crowded",

          "email",
          "cartoon",
          "stadium",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P86-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page86/E2/Key/answerKey.png',
    textareaStyle: { width: 440 },
    // titleImage: "img/FriendsPlus/Page86/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page86/E2/1.jpg' /> </div>
          <div style="position: absolute;top: 148px;left: 470px;"><textarea id="0" rows="2"></textarea></div>
          <div style="position: absolute;top: 391px;left: 0px;"><textarea id="1" rows="2"></textarea></div>
          <div style="position: absolute;top: 391px;left: 470px;"><textarea id="2" rows="2"></textarea></div>
        </div>
        `,
        answer: [
          "Last year Bill watched the animals at the zoo.",
          "One month ago Bill rode his bike in the park.",
          "Yesterday Bill had a birthday party.",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P86-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page86/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Circle the odd-one-out.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "passport robot luggage passenger",	//0
          "TV coin cell phone MP3 player",	//1
          "game poor soccer stadium",	//2
          "fresh first second third",	//3
          "cartoon documentary the news hotel",	//4
        ],
        answers: ['0-2', '1-2', '2-2', '3-0', '4-8'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;font-size: 22px;justify-content: space-between;width: 980px; line-height: 58px; word-spacing: 8px ;">
        <div>
          <div><b>1</b> card candles party  <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">departure</span></div>
          <div><b>3</b> <input id='1' type='Circle' /></div>
          <div><b>5</b> <input id='3' type='Circle' /></div>
        </div>
        <div>
          <div><b>2</b> <input id='0' type='Circle' /></div>
          <div><b>4</b> <input id='2' type='Circle' /></div>
          <div><b>6</b> <input id='4' type='Circle' /></div>
        </div>
      </div>
      `,
    },
  },
}

export default json;