import React from 'react'
import ReactQuill from 'react-quill'
import "react-quill/dist/quill.snow.css"

function Editer({ question, Data, setData, setState }) {
  const refQuill = React.useRef(null)

  React.useEffect(() => {
    const cloneQuestion = JSON.parse(JSON.stringify(question));
    const text = cloneQuestion.initialValue;
    const content = textToContent(text);

    setData(pre => ({ ...pre, text, content, isDoing: true }))
  }, [question, setData]);
  //set style
  React.useEffect(() => {
    const ql_editor = refQuill.current.editingArea.getElementsByClassName('ql-editor')[0];
    if (!ql_editor) return null;
    // ql_editor.className = '';
    Object.assign(ql_editor.style, { color: '#302E2F', border: 'none', ...question.editerStyle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  const handleChange = React.useCallback((content, delta, source, editor) => {
    const text = editor.getText();
    setData(pre => ({ ...pre, content, text }));
  }, [setData]);
  //
  const checkSubmit = React.useCallback(async (e) => {
    const { text } = Data;
    const { answer } = question;
    const regex = /[\.\?\!]/g;  //dấu kết thúc câu
    const isSubmit = answer.match(regex).length === text.match(regex)?.length; // số câu input == số câu trong answer
    setState(pre => ({ ...pre, isDisabledSubmit: !isSubmit }));
  }, [Data, question, setState]);

  if (!Data) return null;

  return (
    <ReactQuill
      ref={refQuill}
      theme={null}
      style={{}}
      value={Data.content}
      readOnly={!Data.isDoing}
      onChange={handleChange}
      onKeyUp={checkSubmit}
    />
  )
}

export default Editer;
//
export function calculateResultText(text, answer) {
  // const araySentence = text.split('.');
  // const araySentenceAnswer = answer.split('.');
  // //removes the last element
  // araySentence.pop();
  // araySentenceAnswer.pop();

  //lọc theo dấu câu
  const punctuation_regex = /[\s\S]*?[\.\?\!]/g
  const araySentence = text.match(punctuation_regex);
  const araySentenceAnswer = answer.match(punctuation_regex);

  const listText = araySentence.map((item, index) => {
    const [input, answer] = [item, araySentenceAnswer[index]]?.map(x => x.replace(/\s/g, ''));
    const isCorrect = input === answer;
    return { text: item, isCorrect }
  })
  const booleanArray = listText.map(x => x.isCorrect);
  const total = booleanArray.length;
  // params
  const correct = booleanArray.filter(x => x).length;
  const percent = parseInt(correct * 100 / total) // tính %
  const resultString = `${correct}/${total}`  // điểm / tổng
  const star = percent / 20;
  //
  const content = converText(listText);

  return { content, listText, booleanArray, percent, resultString, star }
}
//
function textToContent(text) {
  return text.replaceAll('\n', '<br/>')
}
//
function converText(listText) {
  const newText = listText.map(item => {
    const { text, isCorrect } = item;
    const color = isCorrect ? 'green' : 'red';
    // if (/^(\n+)$/.test(text) || text === '') return '';
    if (!/\w/.test(text)) return '';  //text ko có chữ hoặc số
    // return `<span style="color:${color}">${text}.</span>`.replaceAll('\n', '<br/>');
    return `<span style="color:${color}">${text}</span>`.replaceAll('\n', '<br/>');
  }).join('');
  return newText;
}

