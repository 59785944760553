import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
	4: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P47-E4',
		audio: '',
		video: '',
		component: T6,
		checkUppercase: true,
		exerciseKey: "img/FriendsPlus/Page47/E4/Key/answerKey.png",
		inputSize: 400,
		// titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
		titleQuestion: [{ num: '4', title: 'How do you do these things? Answer the question using adverb.', color: "#5B5A5D" }],
		questionImage: [],
		questions: [
			{
				title: `
        <div style=" line-height: 58px;">
          			<div>&ensp; How do you play soccer? <u style="color:gray;"> &ensp; I play soccer well. &ensp;</u> </div>
					<div><b>1</b> How do you run? (fast / slow) #</div>
					<div><b>2</b> How do you swim? (good / bad) #</div>
					<div><b>3</b> How do you sing? (loud / quiet) #</div>
        </div>
        `,
				answer: [
					"I run fast. / I run slowly.",
					"I swim well. / I swim badly.",
					"I sing loudly. / I sing quietly.",
				],
			},
		],
	},
	5: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P47-E5',
		audio: '',
		video: '',
		component: D1,
		padding: 0,
		textAlign: 'center',
		maxLength: 2,
		exerciseKey: 'img/FriendsPlus/Page47/E5/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page47/E5/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page47/E5/2.jpg' },
				{ url: 'img/FriendsPlus/Page47/E5/3.jpg', input: true, answer: "rr" },
				{ url: 'img/FriendsPlus/Page47/E5/4.jpg' },
				{ url: 'img/FriendsPlus/Page47/E5/5.jpg', input: true, answer: "ll" },
				{ url: 'img/FriendsPlus/Page47/E5/6.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page47/E5/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page47/E5/8.jpg' },
				{ url: 'img/FriendsPlus/Page47/E5/9.jpg', input: true, answer: "rr" },
				{ url: 'img/FriendsPlus/Page47/E5/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page47/E5/11.jpg' },
				{ url: 'img/FriendsPlus/Page47/E5/12.jpg', input: true, answer: "ll" },
				{ url: 'img/FriendsPlus/Page47/E5/13.jpg' },
				{ url: 'img/FriendsPlus/Page47/E5/14.jpg', input: true, answer: "ll" },
				{ url: 'img/FriendsPlus/Page47/E5/15.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page47/E5/16.jpg' },
				{ url: 'img/FriendsPlus/Page47/E5/17.jpg', input: true, answer: "rr" },
				{ url: 'img/FriendsPlus/Page47/E5/18.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page47/E5/19.jpg' },
			],
		],
	},
	6: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P47-E6',
		audio: '',
		video: '',
		component: T6,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page47/E6/Key/answerKey.png',
		inputSize: 800,
		titleImage: "img/FriendsPlus/Page47/E6/1.jpg",
		titleQuestion: [{ num: '6', title: 'When did you do these things? Write sentence.', color: "#5B5A5D" }],
		questionImage: [],
		questions: [
			{
				title: `
				<div style="line-height: 48px;">
					<div><b>1</b> # </div>
					<div><b>2</b> # </div>
					<div><b>3</b> # </div>
					<div><b>4</b> # </div>
        </div>
				`,
				answer: [
					"",
					"",
					"",
					"",
				],
			},
		],
	},
	7: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P47-E7',
		audio: '',
		video: '',
		component: D1,
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page47/E7/1.jpg' },
			],
		],
	},
}

export default json;