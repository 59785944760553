import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P50-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page50/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page50/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page50/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/3.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page50/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page50/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/6.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page50/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page50/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/9.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page50/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page50/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page50/E1/12.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page50/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page50/E1/14.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P50-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page50/E2/Key/answerKey.png',
    inputSize: 450,
    // titleImage: "img/FriendsPlus/Page50/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a question with Why and an answer with Because for each picture.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; width: 900px ">
            <div><img src='img/FriendsPlus/Page50/E2/1.jpg' /></div>
            <div style="display: flex; flex-direction: column; justify-content: center; ">
              <div><u style=" color: gray; ">&ensp; Why are you tired? &ensp;</u></div>
              <div>#</div>
              <br/>
              <div>#</div>
              <div>#</div>
            </div>
          </div>    
        `,
        answer: [
          "Because I was reading all night.",
          "Why are you happy?",
          "Because I won the race.",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P50-E3',
    audio: 'img/FriendsPlus/Page50/Audio/audio-e1-p50.mp3',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page50/E3/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Listen and repeat. Circle the sound the s makes.<headphone name="15" src="img/FriendsPlus/Page50/Audio/tieude-e1-p50.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "s z iz",//0
          "s z iz",//1
          "s z iz",//2
          "s z iz",//3
          "s z iz",//4
        ],
        answers: ['0-4', '1-2', '2-4', '3-0', '4-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;justify-content: space-between;width: 700px;line-height: 58px;flex-wrap: wrap;">
        <div style=" word-spacing: 10px; margin: 0 40px; "><b>1</b> <span style="border: 2px solid rgb(66, 133, 244); border-radius: 50%; padding: 3px;">s</span> z iz</div>
        <div style=" word-spacing: 10px; margin: 0 40px; "><b>2</b> <input id='0' type='Circle' /> </div>
        <div style=" word-spacing: 10px; margin: 0 40px; "><b>3</b> <input id='1' type='Circle' /> </div>
        <div style=" word-spacing: 10px; margin: 0 40px; "><b>4</b> <input id='2' type='Circle' /> </div>
        <div style=" word-spacing: 10px; margin: 0 40px; "><b>5</b> <input id='3' type='Circle' /> </div>
        <div style=" word-spacing: 10px; margin: 0 40px; "><b>6</b> <input id='4' type='Circle' /> </div>
      </div>
      `,
    },
  },
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P50-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page50/E4/Key/answerKey.png',
    inputSize: 380,
    // titleImage: 'img/FriendsPlus/Page15/E1/title.jpg',
    titleQuestion: [{ num: '2', title: 'Look at the picture and write the word. Write a sentence using the word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style="display: flex;flex-flow: row wrap;justify-content: space-between;width: 1100px;height: 320px;">
            <div style=" display: flex; flex-direction: row; ">
              <div> <img src='img/FriendsPlus/Page50/E4/1.jpg' /> </div>
              <div>
                <div style=" margin: auto 0px ; "><u style=" color: gray; "> boxes. </u></div>
                <div>#</div>
                <div>#</div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; ">
              <div> <img src='img/FriendsPlus/Page50/E4/2.jpg' /> </div>
              <div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; ">
              <div> <img src='img/FriendsPlus/Page50/E4/3.jpg' /> </div>
              <div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
              </div>
            </div>        
            <div style=" display: flex; flex-direction: row; ">
              <div> <img src='img/FriendsPlus/Page50/E4/4.jpg' /> </div>
              <div>
                <div>#</div>
                <div>#</div>
                <div>#</div>
              </div>
            </div> 
          </div>       
          `,
        answer: [
          "", "",
          "zebras", "", "",
          "bikes", "", "",
          "brushes", "", "",
        ],
      },
    ]
  },
}

export default json;