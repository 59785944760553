import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P77-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page77/E1/Key/answerKey.png',
    inputSize: 30,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    textAlign: 'center',
    // titleImage: "img/FriendsPlus/Page77/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Match the words with the pictures.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page77/E1/1.jpg' /> </div>
          <div style=" position: absolute; top: 118px; left: 318px;"><input id='0' /></div>
          <div style=" position: absolute; top: 118px; left: 586px;"><input id='0' /></div>
          <div style=" position: absolute; top: 118px; left: 870px;"><input id='0' /></div>
        </div>
        `,
        answer: [
          "b",
          "a",
          "d",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P77-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page77/E2/Key/answerKey.png',
    inputSize: 260,
    // titleImage: "img/FriendsPlus/Page77/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Read the text in the Student Book. Write some words to complete the sentence.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px; ">
          <div><b>1</b> Gru plans to <u style="color: gray;"> steal the moon </u>.</div>
          <div><b>2</b> Everything changes for Gru because of #.</div>
          <div><b>3</b> Tom likes chasing #.</div>
          <div><b>4</b> Jerry is brave and clever, but he is #.</div>
          <div><b>5</b> Tonight Viet Nam is playing soccer against #.</div>
          <div><b>6</b> The activities on Viet Nam’s Brainiest Kid are fun but #.</div>
          <div><b>7</b> To win Viet Nam’s Brainiest Kid, you have to think #.</div>
        </div>
        `,
        answer: [
          "three little girls",
          "a mouse called Jerry",
          "small",
          "the Philippines",
          "difficult",
          "fast",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P77-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page77/E3/Key/answerKey.png',
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page77/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Read the text in the Student Book. Answer the question.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" line-height: 48px; ">
            <div><b>1</b> Which two shows start at the same time?</div>
            <div><u style="color: gray;">&emsp; The soccer game and the game show &emsp;</u></div>
            <div><b>2</b> What does Gru want to have?</div>
            <div>#</div>
            <div><b>3</b> Who is clever?</div>
            <div>#</div>
            <div><b>4</b> Where is the soccer game played?</div>
            <div>#</div>
            <div><b>5</b> Which two teams are playing soccer?</div>
            <div>#</div>
            <div><b>6</b> Who are in the game show?</div>
            <div>#</div>
          </div>
        `,
        answer: [
          "He wants the moon.",
          "Jerry",
          "At the Singapore National Stadium",
          "Viet Nam and the Philippines",
          "Children play in the game show.",
        ],
      },
    ],
  },
}

export default json;