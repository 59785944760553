import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P32-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page32/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page32/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page32/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/3.jpg', input: true, answer: "sitting" },
        { url: 'img/FriendsPlus/Page32/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/5.jpg', input: true, answer: "reading" },
        { url: 'img/FriendsPlus/Page32/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page32/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/8.jpg', input: true, answer: "getting" },
        { url: 'img/FriendsPlus/Page32/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/10.jpg', input: true, answer: "drawing" },
        { url: 'img/FriendsPlus/Page32/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/12.jpg', input: true, answer: "swimming" },
        { url: 'img/FriendsPlus/Page32/E1/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page32/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/15.jpg', input: true, answer: "shouting" },
        { url: 'img/FriendsPlus/Page32/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/17.jpg', input: true, answer: "shopping" },
        { url: 'img/FriendsPlus/Page32/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page32/E1/19.jpg', input: true, answer: "listening" },
        { url: 'img/FriendsPlus/Page32/E1/20.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page32/E1/21.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P32-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page32/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page32/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page32/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/3.jpg', input: true, answer: "on the left" },
        { url: 'img/FriendsPlus/Page32/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/5.jpg', input: true, answer: "on the right" },
        { url: 'img/FriendsPlus/Page32/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page32/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page32/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page32/E2/9.jpg', input: true, answer: "at the bottom" },
        { url: 'img/FriendsPlus/Page32/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page32/E2/11.jpg' },
      ],
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P32-E3',
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page32/E3/Key/answerKey.png",
    inputSize: 180,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Read the description and write <i>at the top, on the left, on the right, at the bottom.</i>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div>This is a picture of an outdoor concert. <sup>1</sup> <u style="color: gray;">&ensp; At the top &ensp;</u> of the picture you can see the sun.</div>
          <div>It’s a very warm and sunny day. There is a stage in the picture. <sup>2</sup> # </div>
          <div>is a boy. He’s smiling and playing the drums. <sup>3</sup> #  is a girl. She’s</div>
          <div>sitting on a chair and she’s playing the guitar. In the middle of the stage is another girl.</div>
          <div>She’s singing. <sup>4</sup> #  of the picture you can see the audience. They are</div>
          <div>clapping and cheering. </div>
        </div>
        `,
        answer: [
          "On the left",
          "On the right",
          "At the bottom",
        ],
      },
    ],
  },
}

export default json;