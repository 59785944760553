import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  4: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P65-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    stylesTextInput: { fontSize: 22, height: 24 },
    exerciseKey: 'img/FriendsPlus/Page65/E4/Key/answerKey.png',
    inputSize: 400,
    // titleImage: "img/FriendsPlus/Page65/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Read and complete these notes about floating cities in the future.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page65/E4/1.jpg' /> </div>
          <div style="position: absolute;top: 396px;left: 255px;"><input id='0' /></div>
        </div>
        `,
        answer: [
          "",
        ],
      },
    ],
  },
  5: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P65-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page65/E5/Key/answerKey.png',
    textareaStyle: { width: 800 },
    // titleImage: "img/FriendsPlus/Page65/E5/1.jpg",
    titleQuestion: [{ num: '5', title: ' Describe your own floating city of the future. Use the notes from <b style="color:gray;">4</b>.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div> <textarea id="0" rows="12"> In two hundred years, the oceans will be bigger. </textarea> </div> 
        `,
        answer: [
          "",
        ],
      },
    ],
  },
}

export default json;