import TextEditer from "../../components/ExcerciseTypes/TextEditer";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P13-E1',
    audio: '',
    video: '',
    component: TextEditer,
    exerciseKey: 'img/FriendsPlus/Page13/E1/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page13/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Draw * at the start of each new paragraph.', color: "#5B5A5D" }],
    questionImage: [],
    question: {
      editer: {
        editerStyle: {},
        initialValue: `* The Mayans had brown eyes and black hair. Men and women had long hair. They were not very tall. There were lots of cities in Central America and lots of roads between the cities. People walked on these roads. They also traveled to different cities by river. The Mayans didn’t have shops and they didn’t use money. There was a market in town. They exchanged food and drink and other things.`,
        answer: `* The Mayans had brown eyes and black hair. Men and women had long hair. They were not very tall. * There were lots of cities in Central America and lots of roads between the cities. People walked on these roads. They also traveled to different cities by river. * The Mayans didn’t have shops and they didn’t use money. There was a market in town. They exchanged food and drink and other things.`,
        // initialValue: `1 "Goodbye," says the teacher.\n2 I'm taking a photo, says the boy.\n3 Open your books, she says. Read the story.\n4 The monkeys are my favorite animals, says the girl.`,
        // answer: `1 "Goodbye," says the teacher.\n2 "I'm taking a photo," says the boy.\n3 "Open your books," she says. "Read the story".\n4 "The monkeys are my favorite animals," says the girl.`,
      },
      Layout: `
        <div style=" display: flex; flex-direction: row; ">
          <div style=" width: 700px; "><editer></editer></div>
          <div><img src='img/FriendsPlus/Page13/E1/1.jpg' /></div>
        </div>
      `
    }
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P13-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page13/E2/Key/answerKey.png',
    inputSize: 120,
    titleImage: 'img/FriendsPlus/Page13/E2/1.jpg',
    // titleQuestion: [{ num: '2', title: 'Write about your bed.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div style=" display: flex; flex-direction: row; ">
              <div>
                <img src='img/FriendsPlus/Page13/E2/2.jpg' />
              </div>
            <div>
              <div>There <sup>1</sup> <u style='color: gray;'>was</u> only one room in a Mayan home.</div>
              <div>Everyone <sup>2</sup># this room for eating</div>
              <div>and sleeping. There <sup>3</sup># no chairs or</div>
              <div>tables. There <sup>4</sup># one door.</div>
              <div>Girls <sup>5</sup># at home and helped</div>
              <div>their mothers. They didn’t go to school. They</div>
              <div><sup>6</sup># the food and cleaned the home.</div>
              <div>Boys <sup>7</sup># their fathers on the land.</div>
              <div>Only boys from rich families <sup>8</sup># at</div>
              <div>school. They studied reading, writing, and math.</div>
            </div>
          </div>        
          `,
        answer: [
          // "was",
          "used",
          "were",
          "was",
          "stayed",
          "cooked",
          "helped",
          "studied",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P13-E3',
    audio: '',
    video: '',
    component: T6,
    // exerciseKey: 'img/FriendsPlus/Page13/E2/Key/answerKey.png',
    inputSize: 800,
    textareaStyle: { width: 760 },
    hideBtnFooter: true,
    // titleImage: 'img/FriendsPlus/Page13/E2/1.jpg',
    titleQuestion: [{ num: '3', title: 'Write two paragraphs about homes and families in Viet Nam today.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
           <div> <textarea id="0" rows="7"> </textarea> </div>  
          `,
        answer: [
          "",
        ],
      },
    ]
  },
}
export default json;