import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  4: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P67-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page67/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/3.jpg', input: true, answer: "The red team" },
        { url: 'img/FriendsPlus/Page67/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/6.jpg', input: true, answer: "The blue team" },
        { url: 'img/FriendsPlus/Page67/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/9.jpg', input: true, answer: "The orange team" },
        { url: 'img/FriendsPlus/Page67/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page67/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/12.jpg', input: true, answer: "The purple team" },
        { url: 'img/FriendsPlus/Page67/E4/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page67/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page67/E4/15.jpg', input: true, answer: "The yellow team" },
        { url: 'img/FriendsPlus/Page67/E4/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page67/E4/17.jpg' },
      ],
    ],
  },
  5: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P67-E5',
    audio: '',
    video: '',
    component: D1,
    checkDuplicated: true,
    exerciseKey: 'img/FriendsPlus/Page67/E5/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page67/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/3.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/5.jpg', input: true, answer: "zebras/plays/he's/animals" },
        { url: 'img/FriendsPlus/Page67/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/7.jpg', input: true, answer: "watches/foxes/boxes/noses" },
        { url: 'img/FriendsPlus/Page67/E5/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page67/E5/9.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/10.jpg', input: true, answer: "laughs/it's/goats" },
        { url: 'img/FriendsPlus/Page67/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/12.jpg', input: true, answer: "zebras/plays/he's/animals" },
        { url: 'img/FriendsPlus/Page67/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/14.jpg', input: true, answer: "watches/foxes/boxes/noses" },
        { url: 'img/FriendsPlus/Page67/E5/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page67/E5/16.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/17.jpg', input: true, answer: "laughs/it's/goats" },
        { url: 'img/FriendsPlus/Page67/E5/18.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/19.jpg', input: true, answer: "zebras/plays/he's/animals" },
        { url: 'img/FriendsPlus/Page67/E5/20.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/21.jpg', input: true, answer: "watches/foxes/boxes/noses" },
        { url: 'img/FriendsPlus/Page67/E5/22.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page67/E5/23.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/24.jpg', input: true, answer: "laughs/it's/goats" },
        { url: 'img/FriendsPlus/Page67/E5/25.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/26.jpg', input: true, answer: "zebras/plays/he's/animals" },
        { url: 'img/FriendsPlus/Page67/E5/27.jpg' },
        { url: 'img/FriendsPlus/Page67/E5/28.jpg', input: true, answer: "watches/foxes/boxes/noses" },
        { url: 'img/FriendsPlus/Page67/E5/29.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page67/E5/30.jpg' },
      ],
    ],
  },
  6: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P67-E6',
    audio: '',
    video: '',
    component: UI,
    // recorder: true,
    // titleQuestion: [{ num: '6', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page67/E6/1.jpg' },
      ],
    ],
  },
}

export default json;