import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';


const json = {
  4: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P87-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page87/E4/Key/answerKey.png',
    inputSize: 450,
    titleImage: "img/FriendsPlus/Page87/E4/1.jpg",
    // titleQuestion: [{ num: '4', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative;line-height: 48px;">
          <div><b>1</b> How often does Laura have music?</div>
          <div><u style="color:gray;"> She has music once a week.</u></div>
          <div><b>2</b> How often does she have English?</div>
          <div>#</div>
          <div><b>3</b> How often does she have math?</div>
          <div>#</div>
          <div><b>4</b> How often does she have science? #</div>
          <div><b>5</b> How often does she have Spanish? #</div>
          <div style="position: absolute;top: 46px;left: 514px;"><img src='img/FriendsPlus/Page87/E4/2.jpg' /></div>
        </div>
        `,
        answer: [
          "She has English every day.",
          "She has math three times a week.",
          "She has science twice a week.",
          "She never has Spanish.",
        ],
      },
    ],
  },
  5: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P87-E5',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page87/E5/Key/answerKey.png',
    inputSize: 120,
    titleImage: "img/FriendsPlus/Page87/E5/1.jpg",
    // titleQuestion: [{ num: '5', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative;line-height: 48px;">
          <div><b>1</b> I’m buying tomatoes and cheese <u style="color:gray;"> to make </u> a pizza.</div>
          <div><b>2</b> I’m going to the store # a newspaper.</div>
          <div><b>3</b> Jack went to the park # basketball.</div>
          <div><b>4</b> I bought this book # at home.</div>
          <div><b>5</b> We went to the stadium # a game.</div>
          <div style="position: absolute;top: 2px;left: 567px;"><img src='img/FriendsPlus/Page87/E5/2.jpg' /></div>
        </div>
        `,
        answer: [
          "to buy",
          "to play",
          "to read",
          "to watch",
        ],
      },
    ],
  },
  6: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P87-E6',
    audio: '',
    video: '',
    component: DrawColor,
    exerciseKey: 'img/FriendsPlus/Page87/E6/Key/answerKey.png',
    titleQuestion: [{ num: '6', title: 'Circle the ed words that say t in red.<br/>Circle the ed words that say id in blue.<br/>Circle the ed words that say d in green.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        listStyle: {
          normal: {},
          red: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'red' },
          blue: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'blue' },
          green: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'green' },
          // underline: { borderBottom: '2px solid #4285F4' },
          // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
        },
        limitSelect: 1,
        listWords: [
          "Yesterday, after I finished my homework,",	//0
          "I cleaned my room. Then I walked to the",	//1
          "park and played. Then it rained. At home,",	//2
          "I painted a picture and showed it to my",	//3
          "mom. She really liked it. Then we waited",	//4
          "for my dad to come home.",	//5
        ],
        answers: { '0-6': 'red', '1-12': 'red', '4-6': 'red', '3-2': 'blue', '4-14': 'blue', '1-2': 'green', '2-4': 'green', '2-10': 'green', '3-10': 'green' },
        initialValue: {},
      },
      Layout: `
        <div style="line-height: 38px;border: 1px solid;padding: 20px;transform: rotate(2deg);">
          <div><input id='0' type='Circle' /></div>
          <div><input id='1' type='Circle' /></div>
          <div><input id='2' type='Circle' /></div>
          <div><input id='3' type='Circle' /></div>
          <div><input id='4' type='Circle' /></div>
          <div><input id='5' type='Circle' /></div>
        </div>
        `,
    },
  },
  7: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P87-E7',
    audio: '',
    video: '',
    component: UI,
    // titleQuestion: [{ num: '7', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page87/E7/1.jpg' },
      ],
    ],
  },
}

export default json;