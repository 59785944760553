import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  5: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P39-E5',
    audio: '',
    video: '',
    component: D1,
    checkUppercase: true,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page39/E5/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/3.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page39/E5/4.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/5.jpg', input: true, answer: "M" },
        { url: 'img/FriendsPlus/Page39/E5/6.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/7.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page39/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/9.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page39/E5/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/12.jpg', input: true, answer: "M" },
        { url: 'img/FriendsPlus/Page39/E5/13.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/14.jpg', input: true, answer: "M" },
        { url: 'img/FriendsPlus/Page39/E5/15.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/16.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page39/E5/17.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/18.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page39/E5/19.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/20.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page39/E5/21.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page39/E5/22.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/23.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page39/E5/24.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/25.jpg', input: true, answer: "M" },
        { url: 'img/FriendsPlus/Page39/E5/26.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/27.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page39/E5/28.jpg' },
        { url: 'img/FriendsPlus/Page39/E5/29.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page39/E5/30.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page39/E5/31.jpg' },
      ],
    ],
  },
  6: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P39-E6',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "",
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '6', title: 'Answer the questions about your trip.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 48px;">
          <div>When did you go? # </div>
          <div>Where did you go? # </div>
          <div>Did you go by bus? # </div>
          <div>What did you see? # </div>
          <div>What did you learn? # </div>
          <div>What did you eat? # </div>
          <div>What did you like?  # </div>
        </div>
        `,
        answer: [
          "", "",
          "", "",
          "", "",
          "",
        ],
      },
    ],
  },
  7: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P39-E7',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    exerciseKey: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page39/E7/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page39/E7/2.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page39/E7/5.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page39/E7/8.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page39/E7/11.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page39/E7/14.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page39/E7/17.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page39/E7/20.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/21.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page39/E7/23.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/24.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/25.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page39/E7/26.jpg' },
        { url: 'img/FriendsPlus/Page39/E7/27.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page39/E7/28.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page39/E7/29.jpg' },
      ],

    ],
  },
}

export default json;