import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P59-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page59/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page59/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page59/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page59/E4/3.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page59/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page59/E4/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page59/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page59/E4/7.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page59/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page59/E4/9.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page59/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page59/E4/11.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page59/E4/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page59/E4/13.jpg' },
      ],
    ],
  },
  5: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P59-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page59/E5/Key/answerKey.png',
    textareaStyle: { width: 760 },
    // titleImage: "img/FriendsPlus/Page59/E5/1.jpg",
    titleQuestion: [{ num: '5', title: 'Write the story of The Girl and the Snake. Use the words from 4.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
           <div> <textarea id="0" rows="11">Once there was a girl in her backyard. The girl was bored.</textarea> </div>
           <div style=" font-size: 20px;">Remember you need a beginning, a middle, and an end. Check your paragraphs too!</div>  
          `,
        answer: [
          "",
        ],
      },
    ],
  },
}

export default json;