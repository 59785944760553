import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P58-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page58/E1/Key/answerKey.png',
    inputSize: 110,
    titleImage: "img/FriendsPlus/Page58/E1/1.jpg",
    // titleQuestion: [{ num: '1', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="display: flex;flex-direction: row;justify-content: space-between;width: 950px; line-height: 58px;">
          <div>
            <div><b>1</b> My shoes are too small for my <u style="color:gray;"> feet. </u> </div>
            <div><b>3</b> I can see three # in the river.</div>
            <div><b>5</b> Wool comes from #.</div>
          </div>
          <div>
            <div><b>2</b> I brush my # twice a day.</div>
            <div><b>4</b> There are six # in my family.</div>
            <div><b>6</b> There are two # in the store.</div>
          </div>
        </div>
        `,
        answer: [
          "fish",
          "sheep",
          "teeth",
          "people",
          "women",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P58-E2',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page58/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Read the story of The Boy Who Cried Wolf.', color: "#5B5A5D" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page58/E2/1.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P58-E3',
    audio: '',
    video: '',
    component: D1,
    fontSize: 20,
    exerciseKey: 'img/FriendsPlus/Page58/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page58/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page58/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/3.jpg', input: true, answer: "Once there was a boy." },
        { url: 'img/FriendsPlus/Page58/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page58/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page58/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/7.jpg', input: true, answer: "They didn't laugh at his joke." },
        { url: 'img/FriendsPlus/Page58/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page58/E3/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page58/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page58/E3/11.jpg', input: true, answer: "The wolf ate all the sheep." },
        { url: 'img/FriendsPlus/Page58/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page58/E3/13.jpg' },
      ],

    ],
  },


}

export default json;