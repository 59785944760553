import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P42-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 8,
    exerciseKey: 'img/FriendsPlus/Page42/E1/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page42/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page42/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/4.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/8.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page42/E1/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page42/E1/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/12.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page42/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/14.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E1/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E1/17.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E1/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page42/E1/19.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/20.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page42/E1/21.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page42/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/23.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/24.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/25.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/26.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/27.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/28.jpg', input: 6 },
        { url: 'img/FriendsPlus/Page42/E1/29.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/30.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/31.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/32.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/33.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page42/E1/34.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page42/E1/35.jpg' },
        { url: 'img/FriendsPlus/Page42/E1/36.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/37.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/38.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/39.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/40.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/41.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/42.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/43.jpg', input: 7, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/44.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page42/E1/45.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page42/E1/46.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page42/E1/47.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page42/E1/48.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P42-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page42/E2/Key/answerKey.png",
    inputSize: 400,
    // titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
    titleQuestion: [{ num: '2', title: 'Now answer the questions. Use adverbs.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px;">
          <div><b>1</b> How did the teams walk onto the court? <u style="color:gray;"> &ensp; They walked quietly. &ensp;</u> </div>
          <div><b>2</b> How did the parents cheer? # </div>
          <div><b>3</b> How did Henry watch the ball? # </div>
          <div><b>4</b> How did the other team play? # </div>
          <div><b>5</b> How did Henry’s team play?  # </div>
        </div>
        `,
        answer: [
          "They cheered loudly.",
          "He watched it carefully.",
          "They played badly.",
          "They played well.",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P42-E3',
    audio: 'img/FriendsPlus/Page42/Audio/audio-e3-p42.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 2,
    exerciseKey: 'img/FriendsPlus/Page42/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page42/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page42/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page42/Audio/tieude-e3-p42.mp3' },
        { url: 'img/FriendsPlus/Page42/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page42/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page42/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page42/E3/6.jpg', input: true, answer: "rr" },
        { url: 'img/FriendsPlus/Page42/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page42/E3/8.jpg', input: true, answer: "rr" },
        { url: 'img/FriendsPlus/Page42/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page42/E3/10.jpg', input: true, answer: "ll" },
        { url: 'img/FriendsPlus/Page42/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page42/E3/12.jpg', input: true, answer: "rr" },
        { url: 'img/FriendsPlus/Page42/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page42/E3/14.jpg', input: true, answer: "ll" },
        { url: 'img/FriendsPlus/Page42/E3/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page42/E3/16.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P42-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page42/E4/Key/answerKey.png",
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px;">
          <div><b>1</b> (smell) <u style="color:gray;"> &ensp; I like to smell flowers. &ensp;</u> </div>
          <div><b>2</b> (mirror) # </div>
          <div><b>3</b> (carrot) # </div>
          <div><b>4</b> (shell) # </div>
          <div><b>5</b> (bell) # </div>
          <div><b>6</b> (cherry) # </div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;