import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P5-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 9,
    exerciseKey: 'img/FriendsPlus/Page5/E1/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E1/3.jpg', input: 1 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E1/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page5/E1/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page5/E1/6.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page5/E1/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page5/E1/8.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/9.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page5/E1/10.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E1/11.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page5/E1/12.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page5/E1/13.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page5/E1/14.jpg', input: 11, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/15.jpg', input: 12 },
        { url: 'img/FriendsPlus/Page5/E1/16.jpg', input: 13, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/17.jpg', input: 14 },
        { url: 'img/FriendsPlus/Page5/E1/18.jpg', input: 15 },
        { url: 'img/FriendsPlus/Page5/E1/19.jpg', input: 16, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/20.jpg', input: 17 },
        { url: 'img/FriendsPlus/Page5/E1/21.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E1/22.jpg', input: 18, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/23.jpg', input: 19 },
        { url: 'img/FriendsPlus/Page5/E1/24.jpg', input: 20 },
        { url: 'img/FriendsPlus/Page5/E1/25.jpg', input: 21 },
        { url: 'img/FriendsPlus/Page5/E1/26.jpg', input: 22 },
        { url: 'img/FriendsPlus/Page5/E1/27.jpg', input: 23, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/28.jpg', input: 24 },
        { url: 'img/FriendsPlus/Page5/E1/29.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E1/30.jpg', input: 25 },
        { url: 'img/FriendsPlus/Page5/E1/31.jpg', input: 26 },
        { url: 'img/FriendsPlus/Page5/E1/32.jpg', input: 27 },
        { url: 'img/FriendsPlus/Page5/E1/33.jpg', input: 28 },
        { url: 'img/FriendsPlus/Page5/E1/34.jpg', input: 29, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/35.jpg', input: 30 },
        { url: 'img/FriendsPlus/Page5/E1/36.jpg', input: 31 },
        { url: 'img/FriendsPlus/Page5/E1/37.jpg', input: 32 },
        { url: 'img/FriendsPlus/Page5/E1/38.jpg', input: 33, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/39.jpg', input: 34 },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E1/40.jpg', input: 35 },
        { url: 'img/FriendsPlus/Page5/E1/41.jpg', input: 36 },
        { url: 'img/FriendsPlus/Page5/E1/42.jpg', input: 37, isCorrect: true },
        { url: 'img/FriendsPlus/Page5/E1/43.jpg', input: 38 },
        { url: 'img/FriendsPlus/Page5/E1/44.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P5-E2',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/8.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/11.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/16.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/21.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/23.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/24.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/26.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/28.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/29.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page5/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/31.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/32.jpg' },
        { url: 'img/FriendsPlus/Page5/E2/33.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page5/E2/34.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page5/E2/35.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P5-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page5/E3/Key/answerKey.png',
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page5/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page5/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/3.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page5/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/5.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page5/E3/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page5/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page5/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/9.jpg', input: true, answer: "an" },
        { url: 'img/FriendsPlus/Page5/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/11.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page5/E3/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page5/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/14.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page5/E3/15.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page5/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/17.jpg', input: true, answer: "some" },
        { url: 'img/FriendsPlus/Page5/E3/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page5/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/20.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page5/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page5/E3/22.jpg', input: true, answer: "an" },
        { url: 'img/FriendsPlus/Page5/E3/23.jpg' },
      ],
    ],
  },

}

export default json;