import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Starter",
    id: "WB5-S-P6-E1",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.jpg",
    inputSize: 700,
    titleImage: "img/FriendsPlus/Page6/E1/title.jpg",
    // titleQuestion: [{ num: '3', title: 'Write the missing word. ', color: "#203c8e"}],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height: 58px;'>
            <div><span style='font-weight:600'>1</span> What is Katie going to do tomorrow morning? </div>
            <div><u style='color: gray;'><i>She’s going to visit her aunt.</i></u></div>            
            <div><span style='font-weight:600'>2</span> What is she going to do tomorrow evening? </div> <div>#</div>
            <div><span style='font-weight:600'>3</span> What are Carmen and Stuart going to do tomorrow morning?</div> <div>#</div>
            <div><span style='font-weight:600'>4</span> What are they going to do tomorrow evening? </div> <div>#</div>
            <div><span style='font-weight:600'>5</span> What is Bobby going to do tomorrow morning? </div> <div>#</div>
            <div><span style='font-weight:600'>6</span> What is he going to do tomorrow evening? </div> <div>#</div>
          </div>
        `,
        answer: [
          // "She's going to visit her aunt.",
          "She's going to go to the movies.",
          "They're going to make a cake.",
          "They're going to go to a party.",
          "He's going to do his homework.",
          "He's going to play volleyball.",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "WB5-S-P6-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page6/E2/Key/answerKey.png",
    component: D1,
    // checkUppercase: true,
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page6/E2/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page6/E2/2.jpg" },
        { url: "img/FriendsPlus/Page6/E2/3.jpg", input: true, answer: "sound" },
        { url: "img/FriendsPlus/Page6/E2/4.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page6/E2/5.jpg" },
        { url: "img/FriendsPlus/Page6/E2/6.jpg", input: true, answer: "toy" },
        { url: "img/FriendsPlus/Page6/E2/7.jpg" },
        { url: "img/FriendsPlus/Page6/E2/8.jpg", input: true, answer: "oil" },
        { url: "img/FriendsPlus/Page6/E2/9.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page6/E2/10.jpg" },
        { url: "img/FriendsPlus/Page6/E2/11.jpg", input: true, answer: "down" },
        { url: "img/FriendsPlus/Page6/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/13.jpg",
          input: true,
          answer: "light",
        },
        { url: "img/FriendsPlus/Page6/E2/14.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page6/E2/15.jpg" },
        { url: "img/FriendsPlus/Page6/E2/16.jpg", input: true, answer: "tall" },
        { url: "img/FriendsPlus/Page6/E2/17.jpg" },
        {
          url: "img/FriendsPlus/Page6/E2/18.jpg",
          input: true,
          answer: "shark",
        },
        { url: "img/FriendsPlus/Page6/E2/19.jpg" },
      ],
    ],
  },
};

export default json;