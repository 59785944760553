import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {

  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P41-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page41/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Read and circle the time markers. Write Xuan’s diary.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "her / hers",//0
          "your / yours",//1
          "Her / Hers",//2
          "my / mine",//3
          "our / ours",//4
        ],
        answers: ['0-4', '1-4', '2-0', '3-4', '4-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;font-size: 22px;justify-content: space-between;width: 850px; line-height: 58px;">
        <div>
          <div><b>1</b> I can’t find <b style="border: 2px solid gray; border-radius: 50%; padding: 3px;">my</b> <b>/ mine</b> jacket.</div>
          <div><b>3</b> Which backpack is <b><input id='1' type='Circle' /></b>?</div>
          <div><b>5</b> Don’t touch that! It’s <b><input id='3' type='Circle' /></b>.</div>
        </div>
        <div>
          <div><b>2</b> Yes, she won. The trophy is <b><input id='0' type='Circle' /></b>.</div>
          <div><b>4</b> <b><input id='2' type='Circle' /></b> sneakers are red and white.</div>
          <div><b>6</b> That isn’t our ball. This one’s <b><input id='4' type='Circle' /></b>.</div>
        </div>
      </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P41-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page41/E2/Key/answerKey.png",
    inputSize: 300,
    // titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use possessive pronouns.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; line-height: 58px; justify-content: space-between;width: 800px;">
        <div>
          <div><b>1</b> This is your sandwich.</div>
          <div><b>2</b> This is my jacket.</div>
          <div><b>3</b> This is my dad’s basketball trophy.</div>
          <div><b>4</b> This is Lucy’s backpack.</div>
          <div><b>5</b> These are our sneakers.</div>
          <div><b>6</b> These are their T-shirts.</div>
        </div>
        <div>
          <div><u style="color:gray;">It’s yours. &ensp;</u></div>
          <div> # </div>
          <div> # </div>
          <div> # </div>
          <div> # </div>
          <div> # </div>
        </div>
      </div>
        `,
        answer: [
          "It's mine.",
          "It's his.",
          "It's hers.",
          "They're ours.",
          "They're theirs.",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P41-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page41/E3/Key/answerKey.png",
    inputSize: 120,
    titleImage: "img/FriendsPlus/Page41/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Read the text. Choose the right word and write it on the line.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 48px;">
          <div>Vy, Lan, Kim, and Vinh went to the park. Vy took same sneakers out of her backpack. She</div>
          <div>put on the blue ones. “These ones are (1) <u style="color:gray;"> &ensp; mine &ensp;</u>,” she said.</div>
          <div>Then she gave the green ones to her sister Lan. “These sneakers are (2) #,”</div>
          <div>she said. “Now where’s Kim?” she said. “These white sneakers are (3) #.”</div>
          <div>“And are these black sneakers Vinh’s?” asked Lan. “Yes, they’re (4) #,” said Vy.</div>
          <div>Kim and Vinh were kicking a ball with some friends. “We don’t have a ball,” said Kim. “But</div>
          <div>this ball is (5) #. They say we can borrow it.” “That’s great,” said Vy. “Do they</div>
          <div>want a frisbee? They can borrow (6) #.”</div>
          <div> <img src='img/FriendsPlus/Page41/E3/2.jpg' /> </div>
        </div>
        `,
        answer: [
          "yours",
          "hers",
          "his",
          "theirs",
          "ours",
        ],
      },
    ],
  },

}

export default json;