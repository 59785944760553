import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {

	1: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P43-E1',
		audio: '',
		video: '',
		component: D1,
		exerciseKey: '',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page43/2.jpg' },

			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P43-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: "img/FriendsPlus/Page43/E2/Key/answerKey.png",
		inputSize: 150,
		// titleImage: "img/FriendsPlus/Page41/E2/title.jpg",
		titleQuestion: [{ num: '2', title: 'Write. Use the words above.', color: "#5B5A5D" }],
		questionImage: [],
		questions: [
			{
				title: `
        <div style=" line-height: 58px;">
          <div><b>1</b> We play basketball or tennis here. <u style="color:gray;"> &ensp; court &ensp;</u> </div>
					<div><b>2</b> In a basketball game, you score these. # </div>
					<div><b>3</b> The ball falls through this to come back down to the ground. # </div>
					<div><b>4</b> This is when a ball hits the ground and comes back up again. # </div>
					<div><b>5</b> When someone thinks of something that is completely new. # </div>
        </div>
        `,
				answer: [
					"points",
					"basket",
					"bounce",
					"invent",
				],
			},
		],
	},
	3: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P43-E3',
		audio: '',
		video: '',
		component: DesignUnderLine,
		totalInput: 3,
		exerciseKey: 'img/FriendsPlus/Page43/E3/Key/answerKey.png',
		titleImage: '',
		isAllowSubmit: true,
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page43/E3/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page43/E3/2.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page43/E3/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page43/E3/5.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/6.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page43/E3/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page43/E3/8.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/9.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page43/E3/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page43/E3/11.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page43/E3/12.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/13.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page43/E3/14.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/15.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page43/E3/16.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page43/E3/17.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/18.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page43/E3/19.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/20.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page43/E3/21.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page43/E3/22.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/23.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page43/E3/24.jpg' },
				{ url: 'img/FriendsPlus/Page43/E3/25.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page43/E3/26.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page43/E3/27.jpg' },
			],

		],
	},

}

export default json;