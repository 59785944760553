import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P54-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page54/E1/Key/answerKey.png',
    inputSize: 180,
    // titleImage: "img/FriendsPlus/Page54/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read and circle the words below.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 48px;">
          <div>1 It changes its shape if you sit on it. #</div>
          <div>2 This is the opposite of soft. #</div>
          <div>3 It doesn’t cost a lot of money. #</div>
          <div>4 You need a lot of money to buy this, it’s . . . #</div>
          <div>5 When a bed or chair makes you feel relaxed, it is this. #</div>
          <div>6 Something that looks new. #</div>
          <div>7 When something is broken, you need to do this. #</div>
          <div>8 If you jump on a chair, it can. . . #</div>
        </div>
        <div><img src='img/FriendsPlus/Page54/E1/1.jpg' /></div>
        `,
        answer: [
          "soft",
          "hard",
          "cheap",
          "expensive",
          "comfortable",
          "modern",
          "repair",
          "break",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P54-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page54/E2/Key/answerKey.png',
    inputSize: 160,
    // titleImage: "img/FriendsPlus/Page54/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use the words above.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 44px; ">
          <div style=" display: flex; flex-direction: row; ">
            <div><img src='img/FriendsPlus/Page54/E2/1.jpg' /></div>
            <div>
              <div>Nga has a desk and chair in her bedroom. Yesterday her</div>
              <div>chair <sup>1</sup> <u style="color: gray;">broke</u>. Her parents cannot <sup>2</sup> #  it, so</div>
              <div>they are buying a new chair today. Nga likes things that are</div>
              <div>new so she wants a <sup>3</sup> #  chair. Her dad wants her</div>
              <div>to have an old chair because he thinks it will be stronger.</div>
              <div>But Nga thinks that the old chair is too <sup>4</sup> #  . She </div>
            </div>            
          </div>
          <div>
            <div>thinks the new one will be more <sup>5</sup> #  . There is a chair Nga likes, but her mom</div>
            <div>thinks this is too <sup>6</sup> #  . There is another chair which is quite <sup>7</sup> #  .</div>
            <div>Mom shows Nga a cushion to put on the chair, to make the chair <sup>8</sup> #  . Nga likes</div>
            <div>her new chair and cushion very much.</div>
          </div>
        </div>
        `,
        answer: [
          "repair",
          "modern",
          "hard",
          "comfortable",
          "expensive",
          "cheap",
          "soft",
        ],
      },
    ],
  },
}

export default json;