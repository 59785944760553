import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P56-E1',
    audio: 'img/FriendsPlus/Page56/Audio/audio-e1-p56.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page56/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Listen and draw lines. <headphone name="16" src="img/FriendsPlus/Page56/Audio/tieude-e1-p56.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '145px', left: '126px', width: 180, height: 50 }, }, //0
          { boxMatchStyle: { position: 'absolute', top: '235px', left: '126px', width: 180, height: 50 }, }, //1

          { boxMatchStyle: { position: 'absolute', top: '76px', left: '422px', width: 130, height: 40 }, }, //2 
          { boxMatchStyle: { position: 'absolute', top: '123px', left: '422px', width: 130, height: 40 }, }, //3
          { boxMatchStyle: { position: 'absolute', top: '168px', left: '422px', width: 130, height: 40 }, }, //4
          { boxMatchStyle: { position: 'absolute', top: '213px', left: '422px', width: 130, height: 40 }, }, //5
          { boxMatchStyle: { position: 'absolute', top: '257px', left: '422px', width: 130, height: 40 }, }, //6

          { boxMatchStyle: { position: 'absolute', top: '56px', left: '720px', width: 180, height: 50 }, }, //7
          { boxMatchStyle: { position: 'absolute', top: '148px', left: '720px', width: 180, height: 50 }, }, //8
          { boxMatchStyle: { position: 'absolute', top: '235px', left: '720px', width: 180, height: 50 }, }, //9  
        ],
        answers: ['2-7', '0-3', '4-8', '5-9', '1-6'],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page56/E1/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P56-E2',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    exerciseKey: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page56/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page56/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page56/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/5.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page56/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page56/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/8.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page56/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/10.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page56/E2/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page56/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/13.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page56/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page56/E2/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page56/E2/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page56/E2/17.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P56-E3',
    audio: 'img/FriendsPlus/Page56/Audio/audio-e3-p56.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page56/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page56/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/2.jpg', audioUrl: "img/FriendsPlus/Page56/Audio/tieude-e3-p56.mp3" },
        { url: 'img/FriendsPlus/Page56/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page56/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page56/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/6.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page56/E3/7.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/8.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page56/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/10.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page56/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/12.jpg', input: true, answer: "g" },
        { url: 'img/FriendsPlus/Page56/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page56/E3/14.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page56/E3/15.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page56/E3/16.jpg' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P56-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page56/E4/Key/answerKey.png',
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page56/E4/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div><b>1</b> (city) #</div>
          <div><b>2</b> (finger) #</div>
          <div><b>3</b> (giraffe) #</div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;