import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {

  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P29-E1',
    audio: '',
    video: '',
    component: D1,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page29/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/3.jpg', input: true, answer: "C" },
        { url: 'img/FriendsPlus/Page29/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page29/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page29/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/7.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page29/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page29/E1/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page29/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/11.jpg', input: true, answer: "A" },
        { url: 'img/FriendsPlus/Page29/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page29/E1/13.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page29/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/15.jpg', input: true, answer: "D" },
        { url: 'img/FriendsPlus/Page29/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page29/E1/17.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page29/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/19.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page29/E1/20.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page29/E1/21.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P29-E2',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page29/E2/Key/answerKey.png",
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page6/E1/title.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use the simple past.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
        <div>
          <div><b>Giang’s Saturday</b></div>
          <div>On Saturday, Giang and his friend Son <sup>1</sup> <u style="color: gray;">played</u> (play)</div>
          <div>soccer in the park for two hours. After the game, they</div>
          <div><sup>2</sup># (be) hungry. They <sup>3</sup># (have)</div>
          <div>their lunch at the park. After lunch, Giang <sup>4</sup># (be)</div>
          <div>tired. Giang and Son <sup>5</sup># (finish) their lunch and</div>
          <div><sup>6</sup># (walk) home.</div>
          <div>At home, Giang <sup>7</sup>#</div>
          <div>(have) a shower and</div>
          <div><sup>8</sup># (wash) his hair. Then he <sup>9</sup># (watch)</div>
          <div>TV and <sup>10</sup># (clean) the living room.</div>
        </div>
        <div><img src='img/FriendsPlus/Page29/E2/1.jpg' /></div>
      </div>
        `,
        answer: [
          "were",
          "had",
          "was",
          "finished",
          "walked",
          "had",
          "washed",
          "watched",
          "cleaned",
        ],
      },
    ],
  },

}

export default json;