import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P84-E1',
    audio: '',
    video: '',
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page84/E1/1.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P84-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page84/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page84/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page84/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page84/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page84/E2/4.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page84/E2/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page84/E2/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page84/E2/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page84/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page84/E2/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page84/E2/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page84/E2/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page84/E2/12.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page84/E2/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page84/E2/14.jpg' },
      ],

    ],
  },

}

export default json;