import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P55-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page55/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Circle the adjectives that have two or more syllables.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        // limitSelect: 1,
        listWords: [
          "hard soft popular modern expensive", //0
          "generous relaxed young pretty difficult dry big",  //1
        ],
        answers: ['0-4', '0-6', '0-8', '1-0', '1-2', '1-6', '1-8'],
        initialValue: [],
      },
      Layout: `
      <div style="line-height: 58px; word-spacing: 30px ;">
        <div><span style="border: 2px solid rgb(66, 133, 244); border-radius: 50%; padding: 3px;">comfortable</span> <input id='0' type='Circle' /></div>
        <div><input id='1' type='Circle' /></div>
      </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P55-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page55/E2/Key/answerKey.png',
    inputSize: 80,
    // titleImage: "img/FriendsPlus/Page55/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Look and read. Write <b>yes</b> or <b>no</b>.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; ">
            <div style=" line-height: 48px; ">
              <div><b>1</b> The computer game is the most expensive.<u style=" color: gray; "> yes </u> </div>
              <div><b>2</b> The MP3 player is more expensive than the computer</div>
              <div>game. #</div>
              <div><b>3</b> The frisbee is smaller than the computer game. #</div>
              <div><b>4</b> The MP3 player is less expensive than the frisbee. #</div>
              <div><b>5</b> The MP3 player is the smallest. #</div>
              <div><b>6</b> The frisbee is the least expensive. #</div>
            </div>
            <div> <img src='img/FriendsPlus/Page55/E2/1.jpg' /> </div>
          </div>   
        `,
        answer: [
          "no",
          "no",
          "no",
          "yes",
          "yes",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P55-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page55/E3/Key/answerKey.png',
    inputSize: 210,
    // titleImage: "img/FriendsPlus/Page55/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; ">
            <div style=" line-height: 48px; ">
              <div>My name’s Giang. I have two brothers and two sisters: Mi, Mai, Phong,</div>
              <div>and Long. Mi is <sup>1</sup> <u style=" color: gray; ">&ensp; taller </u> (tall) than Mai. But Mai is <sup>2</sup> # </div>
              <div>(generous) and always shares her toys. Long is <sup>3</sup> #  (young)</div>
              <div>than Phong. But Long is <sup>4</sup> #  (friendly) than his brother and</div>
              <div><sup>5</sup> #  (relaxed). Phong is <sup>6</sup> #  (quiet) than his</div>
              <div>brother.</div>
            </div>
            <div> <img src='img/FriendsPlus/Page55/E3/1.jpg' /> </div>
          </div> 
        `,
        answer: [
          "more generous",
          "younger",
          "friendlier",
          "more relaxed",
          "quieter",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P55-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page55/E4/Key/answerKey.png',
    inputSize: 400,
    // titleImage: "img/FriendsPlus/Page55/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Write about you.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; justify-content: space-between; line-height: 48px; width:1150px; ">
            <div>
              <div><b>My school subjects</b></div>
              <div><b>1</b> (easy) <u style=" color: gray; ">&ensp; My easiest subject is &ensp;</u></div>
              <div><b>My family</b></div>
              <div><b>3</b> (tall) #</div>
              <div><b>My friends</b></div>
              <div><b>5</b> (popular) #</div>
              <div><b>My toys</b></div>
              <div><b>7</b> (expensive) #</div>
            </div>
            <div>
              <br/>
              <div><b>2</b> (difficult) #</div>
              <br/>
              <div><b>4</b> (short) #</div>
              <br/>
              <div><b>6</b> (kind) #</div>
              <br/>
              <div><b>8</b> (modern) # </div>
            </div>
          </div> 
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;