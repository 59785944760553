import React from 'react'
import { Container, Row } from 'reactstrap'
import { Input } from 'antd';
import TitleQuestion from '../../../TitleQuestion'
import FormItem from 'antd/lib/form/FormItem';

const { TextArea } = Input;

const CreateImageCode = (props) => {
  const [state, setState] = React.useState({
    data: null,
    inputs: [],
    isDoing: true,
    audioUrl: null,
    audioImgUrl: null,
    videoUrl: null,
    timeStamp: null,
    isDisabledRetry: true,
    isDisabledSubmit: true,
    openedCollapses: true,
  });

  React.useEffect(() => {
    const clonedProps = JSON.parse(JSON.stringify(props));

    const data = clonedProps?.questionImage;

    const audioUrl = clonedProps.audio;

    const videoUrl = clonedProps.video

    setState((prevState) => ({ ...prevState, data, audioUrl, videoUrl }));
  }, [props]);

  const onClickImage = React.useCallback((item, index, rowIndex) => () => {
    setState((prevState) => {
      const data = Array.from(prevState.data);
      data[rowIndex][index].input = true;
      data[rowIndex][index].answer = `''`;
      return { ...prevState, data }
    })
  }, []);

  const renderImage = React.useCallback((images, rowIndex) => {

    return images.map((item, index) => {
      let imageSrc = item.url;
      let cursor = '';
      if (item.audioUrl) {
        cursor = `url(img/volume.png) 12 12, auto`
      }
      if (item.videoUrl) {
        cursor = `url(img/video.png) 12 12, auto`
      }
      return (
        <div key={index} style={{ display: 'grid', position: 'relative' }}>
          <img
            alt="..."
            src={imageSrc}
            style={{ cursor, width: '100%' }}
            onClick={onClickImage(item, index, rowIndex)}
          />
          {item.input && (
            <FormItem
              style={{ display: 'inline-block', position: 'absolute', right: 0, left: 0, padding: 0 }}
              name={index}
            >
              <Input bordered={false} style={{ paddingInline: 2, backgroundColor: 'rgb(124,252,0, 0.1)' }} /> <i onClick={() => setState(pre => {
                const data = Array.from(pre.data);
                delete data[rowIndex][index]['input'];
                delete data[rowIndex][index]['answer'];
                return { ...pre, data }
              })} className="fa fa-window-close" style={{ position: 'absolute', right: 0 }} />
            </FormItem>
          )}
        </div>
      );
    });
  }, [onClickImage]);

  const renderContent = React.useMemo(() => {
    return state.data?.map((rowItem, rowIndex) => {
      return (
        <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {renderImage(rowItem, rowIndex)}
        </div>
      )
    })
  }, [renderImage, state.data]);

  if (!state.data) return null;
  console.log(state.data);
  return (
    <Container className='fluid'>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <TitleQuestion titleQuestion={state.data?.titleQuestion} />
      </Row>

      <Row style={{ minWidth: 985, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {renderContent}
        <TextArea rows={15} style={{ color: 'green' }} value={arrayToString(state.data)} />
      </Row>

    </Container>
  )
}
export default CreateImageCode

const arrayToString = (array) => {
  const rs = array.map(item => {
    const result = item.map(i => objectToString(i));
    return `[\n${result.join(', \n')}\n]`
  })
  return `[\n${rs.join(', \n')}\n]`
}

const objectToString = (object) => {
  let array = [];
  for (const key in object) {
    const value = typeof object[key] === 'string' ? `'${object[key]}'` : object[key];
    array.push(`${key}: ${value}`)
  }
  return `{ ${array.join(', ')} }`;
}
