import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  3: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P73-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page73/E3/Key/answerKey.png',
    inputSize: 300,
    titleImage: "img/FriendsPlus/Page73/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Write an alternative sentence.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="  line-height: 58px;">
            <div><b>1</b> Hi Lucy. <u style="color: gray;"> Dear Lucy,</u></div>
            <div><b>2</b> How are you? #</div>
            <div><b>3</b> Write soon. #</div>
            <div><b>4</b> From, Thi. #</div>
          </div>
        `,
        answer: [
          "I hope you are well.",
          "Tell me your news.",
          "Best wishes, Thi.",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P73-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page73/E4/Key/answerKey.png',
    textareaStyle: { width: 626 },
    // titleImage: "img/FriendsPlus/Page73/E4/1.jpg",
    // titleQuestion: [{ num: '4', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page73/E4/1.jpg' /> </div>
          <div style="position: absolute;top: 119px;left: 258px;"><textarea id="0" rows="19"></textarea></div> 
        </div>
        `,
        answer: [
          "",
        ],
      },
    ],
  },
}

export default json;