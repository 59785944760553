import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  4: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P33-E4',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 3,
    fontSize: 22,
    exerciseKey: 'img/FriendsPlus/Page33/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page33/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page33/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/3.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page33/E4/4.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/5.jpg', input: true, answer: "L" },
        { url: 'img/FriendsPlus/Page33/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/7.jpg', input: true, answer: "T-L" },
        { url: 'img/FriendsPlus/Page33/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/9.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page33/E4/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page33/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/12.jpg', input: true, answer: "L" },
        { url: 'img/FriendsPlus/Page33/E4/13.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/14.jpg', input: true, answer: "T-R" },
        { url: 'img/FriendsPlus/Page33/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/16.jpg', input: true, answer: "T-L" },
        { url: 'img/FriendsPlus/Page33/E4/17.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/18.jpg', input: true, answer: "B" },
        { url: 'img/FriendsPlus/Page33/E4/19.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/20.jpg', input: true, answer: "L" },
        { url: 'img/FriendsPlus/Page33/E4/21.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page33/E4/22.jpg' },
        { url: 'img/FriendsPlus/Page33/E4/23.jpg', input: true, answer: "T-R" },
        { url: 'img/FriendsPlus/Page33/E4/24.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page33/E4/25.jpg' },
      ],
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P33-E5',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    hideBtnFooter: true,
    inputSize: 800,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '5', title: 'Describe the picture. Use the words from 4 and your own ideas.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div>#</div>
          <div><small>Now check your ing spellings!</small></div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },

}

export default json;