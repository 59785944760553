import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
	3: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P45-E3',
		audio: '',
		video: '',
		component: D1,
		padding: 2,
		fontSize: 20,
		exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page45/E3/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page45/E3/2.jpg' },
				{ url: 'img/FriendsPlus/Page45/E3/3.jpg', input: true, answer: "court" },
				{ url: 'img/FriendsPlus/Page45/E3/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page45/E3/5.jpg' },
				{ url: 'img/FriendsPlus/Page45/E3/6.jpg', input: true, answer: "11 players" },
				{ url: 'img/FriendsPlus/Page45/E3/7.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page45/E3/8.jpg' },
				{ url: 'img/FriendsPlus/Page45/E3/9.jpg', input: true, answer: "hit the ball" },
				{ url: 'img/FriendsPlus/Page45/E3/10.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page45/E3/11.jpg' },
				{ url: 'img/FriendsPlus/Page45/E3/12.jpg', input: true, answer: "mustn't touch the ball with hands" },
				{ url: 'img/FriendsPlus/Page45/E3/13.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page45/E3/14.jpg' },
				{ url: 'img/FriendsPlus/Page45/E3/15.jpg', input: true, answer: "ball lands on other side of the court" },
				{ url: 'img/FriendsPlus/Page45/E3/16.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page45/E3/17.jpg' },
				{ url: 'img/FriendsPlus/Page45/E3/18.jpg', input: true, answer: "90 minutes" },
				{ url: 'img/FriendsPlus/Page45/E3/19.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page45/E3/20.jpg' },
			],
		],
	},
	4: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P45-E4',
		audio: '',
		video: '',
		component: D1,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page45/E3/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page45/E4/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page45/E4/2.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/3.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page45/E4/5.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page45/E4/6.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/7.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/8.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page45/E4/9.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/10.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/11.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page45/E4/12.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/13.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/14.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page45/E4/15.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/16.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/17.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page45/E4/18.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/19.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/20.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page45/E4/21.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/22.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/23.jpg' },
			],
			[
				// Column10
				{ url: 'img/FriendsPlus/Page45/E4/24.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/25.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/26.jpg' },
			],
			[
				// Column11
				{ url: 'img/FriendsPlus/Page45/E4/27.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/28.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/29.jpg' },
			],
			[
				// Column12
				{ url: 'img/FriendsPlus/Page45/E4/30.jpg' },
				{ url: 'img/FriendsPlus/Page45/E4/31.jpg', input: true, answer: "" },
				{ url: 'img/FriendsPlus/Page45/E4/32.jpg' },
			],
			[
				// Column13
				{ url: 'img/FriendsPlus/Page45/E4/33.jpg' },
			],

		],
	},


}

export default json;