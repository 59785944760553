import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  4: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P79-E4',
    audio: '',
    video: '',
    component: T6,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    exerciseKey: 'img/FriendsPlus/Page79/E4/Key/answerKey.png',
    inputSize: 220,
    // titleImage: "img/FriendsPlus/Page79/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Write each TV show in the correct box.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page79/E4/1.jpg' /> </div>
          <div style=" position: absolute; top: 84px; left: 38px; "><input id='0' /></div>
          <div style=" position: absolute; top: 84px; left: 347px; "><input id='1' /></div>
          <div style=" position: absolute; top: 84px; left: 653px; "><input id='2' /></div>
        </div>
        `,
        answer: [
          "Documentary",
          "Movie",
          "Cartoon",
        ],
      },
    ],
  },
  5: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P79-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page79/E5/Key/answerKey.png',
    textareaStyle: { width: 820 },
    // titleImage: "img/FriendsPlus/Page79/E5/1.jpg",
    // titleQuestion: [{ num: '5', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page79/E5/1.jpg' /> </div>
          <div style="position: absolute;top: 200px;left: 66px;"><textarea id="0" rows="13"></textarea></div> 
        </div>
        `,
        answer: [
          "",
        ],
      },
    ],
  },
}

export default json;