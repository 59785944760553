import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DrawColor from '../../components/ExcerciseTypes/DrawColor';


const json = {
  // 5: { // Exercise num
  //   unit: 'Unit 12',
  //   id: 'WB5-U12-P81-E1',
  //   audio: '',
  //   video: '',
  //   component: DrawColor,
  //   exerciseKey: 'img/FriendsPlus/Page81/E1/Key/answerKey.png',
  //   titleQuestion: [{ num: '1', title: 'Circle the correct form of the verb.', color: "#5B5A5D" }],
  //   question: {
  //     Write: {
  //       underlineStyle: {},
  //       inputStyle: {},
  //       answers: [],
  //       initialValue: [],
  //     },
  //     Circle: {
  //       listStyle: {
  //         normal: {},
  //         circle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: '#4285F4' },
  //         underline: { borderBottom: '2px solid #4285F4' },
  //         square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
  //       },
  //       limitSelect: 1,
  //       listWords: [
  //         "open / opening",	//0
  //         "play / playing",	//1
  //         "dance / dancing",	//2
  //         "sing / singing",	//3
  //         "blow / blowing",	//4
  //       ],
  //       answers: { '0-4': "circle", '1-4': "underline", '2-4': "square", '3-4': "circle", '4-4': "circle" },
  //       initialValue: {},
  //     },
  //     Layout: `
  //     <div style="line-height: 58px;">
  //       <div><b>1</b> Let’s <b style="border: 2px solid gray; border-radius: 50%; padding: 3px;">have</b><b>/ having</b> a bowling party!</div>
  //       <div><b>2</b> How about <b><input id='0' type='Circle' /></b> your presents now?</div>
  //       <div><b>3</b> Why don’t you <b><input id='1' type='Circle' /></b> with your new car?</div>
  //       <div><b>4</b> Let’s <b><input id='2' type='Circle' /></b> to some music!</div>
  //       <div><b>5</b> How about <b><input id='3' type='Circle' /></b> karaoke?</div>
  //       <div><b>6</b> Why don’t you <b><input id='4' type='Circle' /></b> out the candles on the cake?</div>
  //     </div>
  //     `,
  //   },
  // },
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P81-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page81/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Circle the correct form of the verb.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "open / opening",	//0
          "play / playing",	//1
          "dance / dancing",	//2
          "sing / singing",	//3
          "blow / blowing",	//4
        ],
        answers: ['0-4', '1-0', '2-0', '3-4', '4-0'],
        initialValue: [],
      },
      Layout: `
      <div style="line-height: 58px;">
        <div><b>1</b> Let’s <b style="border: 2px solid gray; border-radius: 50%; padding: 3px;">have</b><b>/ having</b> a bowling party!</div>
        <div><b>2</b> How about <b><input id='0' type='Circle' /></b> your presents now?</div>
        <div><b>3</b> Why don’t you <b><input id='1' type='Circle' /></b> with your new car?</div>
        <div><b>4</b> Let’s <b><input id='2' type='Circle' /></b> to some music!</div>
        <div><b>5</b> How about <b><input id='3' type='Circle' /></b> karaoke?</div>
        <div><b>6</b> Why don’t you <b><input id='4' type='Circle' /></b> out the candles on the cake?</div>
      </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P81-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page81/E2/Key/answerKey.png',
    inputSize: 240,
    titleImage: "img/FriendsPlus/Page81/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Look and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 48px; ">
          <div><b>Sarah &ensp;</b> It’s my birthday soon. I don’t know what to do for my party. Any ideas?</div>
          <div><b>Leo &ensp;</b> <sup>1</sup> <u style="color:gray;"> Why don’t you </u> have a bowling party? It’s really fun.</div>
          <div><b>Sarah &ensp;</b> I did that last year.</div>
          <div><b>Dad &ensp;</b> <sup>2</sup> #  hav# fireworks in our backyard?</div>
          <div><b>Sarah &ensp;</b> That’s a great idea! <sup>3</sup> #  make the party invitations.</div>
          <div><b>Dad &ensp;</b> <sup>4</sup> #  draw pictures of fireworks on the invitations?</div>
          <div><b>Sarah &ensp;</b> OK. <sup>5</sup> #  invite Sam and Kate to the party.</div>
          <div><b>Leo &ensp;</b> Good idea. And <sup>6</sup> #  invit# Grandma, too?</div>
          <div><b>Sarah &ensp;</b> Of course! I’ll invite all my family.</div>        
        </div>
        `,
        answer: [
          "How about",
          "ing",
          "Let's",
          "Why don't you",
          "Let's",
          "How about",
          "ing"
        ],
      },
    ],
  },
}

export default json;