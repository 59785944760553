import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P17-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page17/E1/Key/answerKey.png',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page17/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/3.jpg', input: true, answer: "h" },
        { url: 'img/FriendsPlus/Page17/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/5.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page17/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/7.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page17/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/9.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page17/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/11.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page17/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/13.jpg', input: true, answer: "k" },
        { url: 'img/FriendsPlus/Page17/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/15.jpg', input: true, answer: "w" },
        { url: 'img/FriendsPlus/Page17/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/17.jpg', input: true, answer: "l" },
        { url: 'img/FriendsPlus/Page17/E1/18.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page17/E1/19.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P17-E2',
    audio: 'img/FriendsPlus/Page17/Audio/audio-e2-p17.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page17/E2/Key/answerKey.png',
    inputSize: 150,
    // titleImage: 'img/FriendsPlus/Page15/E1/title.jpg',
    titleQuestion: [{ num: '2', title: 'Listen and write.<headphone name="04" src="img/FriendsPlus/Page17/Audio/tieude-e2-p17.mp3"></headphone>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div> <img src='img/FriendsPlus/Page17/E2/1.jpg' /> </div>
            <div>
              <div>It’s a very cold day.</div>
              <div>I have a coat with a <sup>1</sup> <u style='color: gray;'>hood</u>.</div>
              <div>I have my <sup>2</sup> # scarf,</div>
              <div>And I’m feeling <sup>3</sup> #.</div>
              <div>I’m sitting on some <sup>4</sup> #.</div>
              <div>I’m looking at my <sup>5</sup> #.</div>
              <div>The book’s very good.</div>
              <div>It tells me how to <sup>6</sup> #.</div>
            </div>
          </div>     
          `,
        answer: [
          // "hood",
          "wool",
          "good",
          "wood",
          "book",
          "cook",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P17-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page17/E3/Key/answerKey.png',
    component: D1,
    padding: 0,
    textAlign: 'center',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page17/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page17/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/3.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page17/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/5.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page17/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/7.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page17/E3/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page17/E3/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page17/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/11.jpg', input: true, answer: "cook" },
        { url: 'img/FriendsPlus/Page17/E3/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page17/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/14.jpg', input: true, answer: "hood" },
        { url: 'img/FriendsPlus/Page17/E3/15.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page17/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page17/E3/17.jpg', input: true, answer: "wool" },
        { url: 'img/FriendsPlus/Page17/E3/18.jpg' },
      ],

    ],
  },
}

export default json;