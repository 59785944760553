import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P51-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page51/E1/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page51/E1/1.jpg",
    // titleQuestion: [{ num: '1', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px; ">
          <div><b>1</b> When a lot of people like something or someone. <u style=" color: gray; ">&ensp; popular &ensp;</u></div>
          <div><b>2</b> Something long and thin, usually made from wood.#</div>
          <div><b>3</b> A material which makes many things, like spoons.#</div>
          <div><b>4</b> A light, thin wall that can be easily moved.#</div>
          <div><b>5</b> An area of land on which rice is grown. #</div>
        </div>
        `,
        answer: [
          "a stick",
          "plastic",
          "a screen",
          "a rice field",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P51-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page51/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page51/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page51/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page51/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page51/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page51/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page51/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page51/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page51/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page51/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page51/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page51/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page51/E2/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page51/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page51/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/25.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page51/E2/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page51/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/28.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page51/E2/30.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page51/E2/31.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page51/E2/32.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P51-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page51/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read the text in the Student Book. Circle the correct word.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "rice_field / water_pool.", //0
          "screen / band",  //1
          "costume / hair", //2
        ],
        answers: ['0-4', '1-0', '2-0'],
        initialValue: [],
      },
      Layout: `
      <div style="line-height: 58px;">
        <div><b>1</b> The water puppets are made from <b style="border: 2px solid rgb(66, 133, 244); border-radius: 50%; padding: 3px;">wood</b> <b>/ rice.</b></div>
        <div><b>2</b> Today, the stage is a <b><input id='0' type='Circle' /></b></div>
        <div><b>3</b> People hide behind a <b><input id='1' type='Circle' /></b> to act.</div>
        <div><b>4</b> Be careful not to get your puppets’ <b><input id='2' type='Circle' /></b> wet.</div>
      </div>
      `,
    },
  },

}

export default json;