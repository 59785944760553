
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P18-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page18/E1/Key/answerKey.png',
    inputSize: 150,
    titleImage: 'img/FriendsPlus/Page18/E1/1.jpg',
    // titleQuestion: [{ num: '1', title: 'Read the text in the Student Book and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div> <img src='img/FriendsPlus/Page18/E1/2.jpg' /> </div>
            <div style='line-height: 58px;' >
              <div><b>1</b> Lan is going to snorkel <u style='color: gray;'>this afternoon</u>.</div>
              <div><b>2</b> She is going to eat in a seafood restaurant</div>
              <div> #.</div>
              <div><b>3</b> She is going to visit a museum #.</div>
              <div><b>4</b> She is going to fly home #.</div>
              <div><b>5</b> She is going to see Xuan #.</div>
            </div>
          </div>     
          `,
        answer: [
          // "this afternoon",
          "tonight",
          "tomorrow",
          "next week",
          "soon",
        ],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P18-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page18/E2/Key/answerKey.png',
    inputSize: 250,
    // titleImage: 'img/FriendsPlus/Page18/E1/1.jpg',
    titleQuestion: [{ num: '2', title: 'Write a short answer', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row;">
            <div style='line-height: 58px;  width: 900px; ' >
              <div>1 Does Xuan live in Ho Chi Minh City? <u style='color: gray; margin-left: 230px;'>Yes, she does</u>.</div>
              <div style=" display: flex; justify-content: space-between; ">2 Is the weather in Nha Trang sunny?<span>#</span></div>
              <div style=" display: flex; justify-content: space-between; ">3 Is Lan’s brother going to waterski?<span>#</span></div>
              <div style=" display: flex; justify-content: space-between; ">4 Does Lan like the food?<span>#</span></div>
              <div style=" display: flex; justify-content: space-between; ">5 Is she going to fly home tomorrow?<span>#</span></div>
              <div style=" display: flex; justify-content: space-between; ">6 Does she want to buy a camera from Nha Trang? <span>#</span></div>
            </div>
          </div>     
          `,
        answer: [
          // "Yes, she does.",
          "Yes, it is.",
          "No, he isn't.",
          "Yes, she does.",
          "No, she isn't.",
          "No, she doesn't.",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P18-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page18/E3/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page18/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page18/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/4.jpg', input: 1 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page18/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/7.jpg', input: 1, isCorrect: true },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page18/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/10.jpg', input: 1 },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page18/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page18/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page18/E3/14.jpg', input: 3, isCorrect: true },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page18/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page18/E3/17.jpg', input: 3 },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page18/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page18/E3/19.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page18/E3/20.jpg', input: 3 },
      ],

    ],
  },
}

export default json;