import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P49-E1',
    audio: 'img/FriendsPlus/Page49/Audio/audio-e1-p49.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page49/E1/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page49/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page49/Audio/tieude-e1-p49.mp3' },
        { url: 'img/FriendsPlus/Page49/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page49/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page49/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page49/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page49/E1/8.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page49/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page49/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page49/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page49/E1/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page49/E1/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page49/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page49/E1/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page49/E1/17.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page49/E1/18.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page49/E1/19.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P49-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page49/E2/Key/answerKey.png',
    inputSize: 400,
    titleImage: "img/FriendsPlus/Page49/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Look at the map. Use the words in the box to write four directions to get to the <br/> train station.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px;">
					<div><b>1</b> <u style="color:gray;"> &ensp; Go straight. &ensp;</u></div>
          <div><b>2</b> #</div>
          <div><b>3</b> #</div>
          <div><b>4</b> #</div>
          <div><b>5</b> #</div>
        </div>
        `,
        answer: [
          "Turn left at the traffic lights.",
          "Take the road on your right.",
          "Go past the gas station.",
          "The station is on your right.",
        ],
      },
    ],
  },

}

export default json;