import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P64-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    // maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page64/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page64/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page64/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/3.jpg', input: true, answer: "supermarket" },
        { url: 'img/FriendsPlus/Page64/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page64/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/6.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page64/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page64/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/9.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page64/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/11.jpg', input: true, answer: "airplane" },
        { url: 'img/FriendsPlus/Page64/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page64/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/14.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page64/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/16.jpg', input: true, answer: "playground" },
        { url: 'img/FriendsPlus/Page64/E1/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page64/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/19.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page64/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/21.jpg', input: true, answer: "spaceship" },
        { url: 'img/FriendsPlus/Page64/E1/22.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page64/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/24.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page64/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page64/E1/26.jpg', input: true, answer: "homework" },
        { url: 'img/FriendsPlus/Page64/E1/27.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page64/E1/28.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P64-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page64/E2/Key/answerKey.png',
    // titleQuestion: [{ num: '2', title: 'Read the flyer and underline the imperative sentences.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        // limitSelect: 1,
        listWords: [
          "In 200 years, there will be lots more people, so it will be too crowded on", //0
          "Earth. I think we will have to build new cities in space. People will live", //1
          "on space stations in very tall apartments. There will be schools on the space stations", //2
          "for children. But there won’t be any playgrounds. Maybe children will have robots to", //3
          "help them with their homework and tell them when to go to bed.", //4
          " I think that the way we travel around the space stations will also be different. We", //5
          "will all travel in mini spaceships which will be very fast. You won’t be able to buy a", //6
          "spaceship because they will be very expensive. Instead, the spaceships will be like", //7
          "trains for everybody to use.", //8
        ],
        answers: ['3-14', '4-8', '6-10', '7-0', '7-18'],
        initialValue: [],
      },
      Layout: `
      
      <div style=" position: relative; ">
        <div> <img src='img/FriendsPlus/Page64/E2/1.jpg' /> </div>
        <div style="position: absolute;top: 140px;left: 64px;font-size: 21px;">
          <div> <input id='0' type='Circle' /> </div>
          <div> <input id='1' type='Circle' /> </div>
          <div> <input id='2' type='Circle' /> </div>
          <div> <input id='3' type='Circle' /> </div>
          <div> <input id='4' type='Circle' /> </div>
          <div> <input id='5' type='Circle' /> </div>
          <div> <input id='6' type='Circle' /> </div>
          <div> <input id='7' type='Circle' /> </div>
          <div> <input id='8' type='Circle' /> </div>
        </div>
      </div>
      `,
    },
  },
  3: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P64-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page64/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page64/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page64/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page64/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page64/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page64/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page64/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page64/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page64/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page64/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page64/E3/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page64/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/20.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page64/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page64/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/25.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page64/E3/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page64/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/28.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/31.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page64/E3/32.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/33.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page64/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page64/E3/36.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page64/E3/37.jpg' },
      ],

    ],
  },

}

export default json;