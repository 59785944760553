import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  1: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P40-E1',
    audio: 'img/FriendsPlus/Page40/Audio/audio-e1-p40.mp3',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page40/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude-e1-p40.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg', input: true, answer: "3" },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page40/E1/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page40/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/10.jpg', input: true, answer: "2" },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page40/E1/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page40/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/14.jpg', input: true, answer: "5" },
        { url: 'img/FriendsPlus/Page40/E1/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page40/E1/16.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page40/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/18.jpg', input: true, answer: "4" },
        { url: 'img/FriendsPlus/Page40/E1/19.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page40/E1/20.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'WB5-U6-P40-E2',
    audio: '',
    video: '',
    component: T6,
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page40/E2/Key/answerKey.png",
    titleImage: "img/FriendsPlus/Page40/E2/title.jpg",
    // titleQuestion: [{ num: '', title: 'Answer the questions about your trip.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 48px;">
          <div>Yesterday we had a sports competition at school. I played soccer. There were two</div>
          <div><sup>1</sup> <u style="color:gray;">&ensp; teams </u> , red and white. I played for the red team. I had my sports things in my</div>
          <div><sup>2</sup> # . I took off my school shoes and put on my <sup>3</sup> # .</div>
          <div>It was cold, so we had to wear our sports <sup>4</sup> # .</div>
          <div>The score was Red Team 4, White Team 2, so my team <sup>5</sup> # . My friend Jamie</div>
          <div>passed me the ball. Then I <sup>6</sup> # it hard and it went in the goal.</div>
          <div>We also played tennis and volleyball, but soccer was my favorite.</div>
          <div>At the end of the competition, the children in the red team got a <sup>7</sup> # . </div>
        </div>
        `,
        answer: [
          "backpack",
          "sneakers",
          "jacket",
          "won",
          "kicked",
          "trophy",
        ],
      },
    ],
  },
}

export default json;