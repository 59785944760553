/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
// import styles from './styles.module.css';
// import { Input, Form, Button } from 'antd'
import styles from './styles.module.css'
import wordsearch from 'wordsearch-generator'
import TitleQuestion from '../../TitleQuestion';
import { Row, Col, Container } from 'reactstrap';
import FooterIeltsMindset from '../../FooterModal';
import moment from 'moment';
import 'moment/locale/vi';
import _ from 'lodash';

moment.locale('vi');
// import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const mouseState = {
  UP: 'UP',
  DOWN: 'DOWN',
}

const WordsFind = (props) => {
  // const [form] = Form.useForm();
  // const refForm = React.useRef();
  // const inputCount = React.useRef(0);
  // const submitButton = React.useRef();

  const [state, setState] = React.useState({
    lines: props.array,
    keyword: [],
    wordArray: props.array,
    drawedArray: [],
    selectingArray: [],
    mouseEndPoint: null,
    mouseStartPoint: null,
    isDisabledRetry: true,
    isDisabledSubmit: true,
    isDisabledInput: false,
    currentMouseState: mouseState.UP,
    // isDisabledSubmit:false,
  });

  // Tạo mảng ô chữ
  React.useEffect(() => {
    const keyword = props.questions[0].answerInputs
    let puzzleGrid = wordsearch.createPuzzle(11, 11, 'en', keyword)
    puzzleGrid = wordsearch.hideWords(puzzleGrid, 'en')
    const lines = props.array

    const drawedArray = props.initialValues;
    setState((prevState) => ({ ...prevState, lines, keyword, drawedArray }))
  }, [props.array, props.initialValues, props.questions])

  const onSubmit = React.useCallback(() => {
    const { keyword, drawedArray } = state;

    const groupByTime = _.groupBy(drawedArray, 'timestamp')
    const arrayText = Object.values(groupByTime).map(item => {
      return item.map(x => x.itemWord).join('')
    })

    const booleanArray = keyword.map(item => {
      let check = false
      for (let index = 0; index < arrayText.length; index++) {
        if (arrayText[index].toLowerCase() === item || arrayText[index].toLowerCase().split("").reverse().join("") === item) {
          check = true;
          continue
        }
      }
      return check; //tìm key word trong text, ko phân biệt hoa thường
    })
    //
    const correctAnswer = booleanArray.filter(x => x === true).length;
    const percent = parseInt(correctAnswer / booleanArray.length * 100)
    const result_String = `${correctAnswer}/${booleanArray.length}`
    const studentAnswer = Array.from(drawedArray)
    //
    const params = {
      score: percent,
      unit: props.unit,
      results: result_String,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    props.postAnswerToApi(params)

    // submitButton.current?.click();
    setState((prevState) => ({ ...prevState, booleanArray, isDisabledInput: true, isDisabledRetry: false, isDisabledSubmit: true }));
  }, [props, state]);

  const onRetry = React.useCallback(() => {
    // form.resetFields();
    const drawedArray = props.initialValues;
    setState((preState) => ({
      ...preState,
      drawedArray,
      isPointed: false,
      selectingArray: [],
      isDisabledRetry: true,
      isDisabledSubmit: true,
      isDisabledInput: false,
    }))
  }, [props.initialValues])

  // Khi hoàn thành các field
  // const onFinish = React.useCallback((values) => {
  //   let booleanArray = []
  //   let isCorrect = false;
  //   const answerInputsPositive = props.questions[0].answerInputs;
  //   const answerInputsNegative = props.questions[1].answerInputs;
  //   for (let item in values) {
  //     const index = parseInt(item);
  //     if (values[item]) {
  //       if (index < 5) {
  //         isCorrect = answerInputsPositive.includes(values[index].trim().toLowerCase());
  //       } else {
  //         isCorrect = answerInputsNegative.includes(values[index].trim().toLowerCase());
  //       }
  //     }
  //     booleanArray.push(isCorrect);
  //   }
  //   state.booleanArray = booleanArray
  //   setState((preState) => ({ ...preState, sentences: state.sentences, isDisabledRetry: false, isPointed: true }))
  // }, [props.questions, state])

  // const findWord = React.useCallback(() => {
  //   const selection = getSelection();

  //   //Điểm bôi đầu tiên
  //   const startPoint = selection.anchorNode.parentElement.getAttribute('id');
  //   let rowStartIndex = parseInt(startPoint?.split('-')[0]);

  //   let startIndex = parseInt(startPoint?.split('-')[1]);

  //   //Điểm bôi cuối
  //   const endPoint = selection.focusNode.parentElement.getAttribute('id');

  //   let rowEndIndex = parseInt(endPoint?.split('-')[0]);

  //   let endIndex = parseInt(endPoint?.split('-')[1]);

  //   let stringSelected = null;
  //   let arraySelected = [];

  //   if (rowStartIndex === rowEndIndex) {
  //     // kiểm tra có cùng hàng hay không
  //     if (startIndex > endIndex) {
  //       [startIndex, endIndex] = [endIndex, startIndex];
  //     }
  //     stringSelected = state.wordArray[rowStartIndex].slice(startIndex, endIndex + 1).join('').toLowerCase()
  //     for (let i = startIndex; i <= endIndex; i++) {
  //       const id = `${rowStartIndex}-${i}`;
  //       arraySelected.push(id)
  //     }
  //   } else {
  //     // Kiểm tra có cùng cột hay không
  //     if (startIndex === endIndex) {

  //       if (rowStartIndex > rowEndIndex) {
  //         [rowStartIndex, rowEndIndex] = [rowEndIndex, rowStartIndex];
  //       }
  //       let arrayCharactor = []
  //       for (let i = rowStartIndex; i <= rowEndIndex; i++) {
  //         const id = `${i}-${startIndex}`;
  //         arraySelected.push(id)
  //         arrayCharactor.push(state.wordArray[i][startIndex])
  //       }
  //       stringSelected = arrayCharactor.join('').toLowerCase()

  //     } else {
  //       //kiểm tra đường chéo
  //       const rangeRow = rowEndIndex - rowStartIndex
  //       if (rangeRow > 0) {
  //         //Kiểm tra chéo từ trên xuống 
  //         const isStartLeft = startIndex < endIndex
  //         const isDiagonalLine = isStartLeft ? startIndex + rangeRow === endIndex : startIndex - rangeRow === endIndex

  //         if (isDiagonalLine) {
  //           let arrayCharactor = [];
  //           let currentIndex = startIndex
  //           for (let i = rowStartIndex; i <= rowEndIndex; i++) {
  //             const id = `${i}-${currentIndex}`;
  //             arraySelected.push(id)
  //             arrayCharactor.push(state.wordArray[i][currentIndex])
  //             isStartLeft ? currentIndex++ : currentIndex--
  //           }
  //           stringSelected = arrayCharactor.join('').toLowerCase()
  //         }
  //       } else {
  //         //Kiểm tra chéo từ dưới lên
  //         [startIndex, endIndex] = [endIndex, startIndex];
  //         [rowStartIndex, rowEndIndex] = [rowEndIndex, rowStartIndex];
  //         const isStartLeft = startIndex < endIndex
  //         const isDiagonalLine = isStartLeft ? startIndex - rangeRow === endIndex : startIndex + rangeRow === endIndex

  //         if (isDiagonalLine) {
  //           let arrayCharactor = [];
  //           let currentIndex = startIndex
  //           for (let i = rowStartIndex; i <= rowEndIndex; i++) {
  //             const id = `${i}-${currentIndex}`;
  //             arraySelected.push(id)
  //             arrayCharactor.push(state.wordArray[i][currentIndex])
  //             isStartLeft ? currentIndex++ : currentIndex--
  //           }
  //           stringSelected = arrayCharactor.join('').toLowerCase()
  //         }
  //       }
  //     }
  //   }

  //   const isCorrect = state.keyword.includes(stringSelected) || state.keyword.includes(stringSelected?.split('').reverse().join(''))

  //   return { isCorrect, stringSelected, arraySelected }
  // }, [state.keyword, state.wordArray])

  // const onMouseUp = React.useCallback(() => {
  //   const { isCorrect, arraySelected } = findWord();

  //   if (isCorrect) {
  //     arraySelected.forEach((item, index) => {
  //       document.getElementById(item).style.color = 'white';
  //       document.getElementById(item).style.backgroundColor = '#27ae60'
  //     })
  //   }

  // }, [findWord])

  // const onMouseDown = React.useCallback(async () => {
  //   // await new Promise((resolve) => setTimeout(resolve, 100));
  //   // const results =  findWord()

  // }, []);

  const onMouseUpOnWord = React.useCallback((itemWord, index, rowIndex) => () => {
    setState((prevState) => {
      let selectingArray = JSON.parse(JSON.stringify(prevState.selectingArray));

      const selectingString = selectingArray.map((x) => x.itemWord).join('');

      const selectingReverseString = selectingArray.map((x) => x.itemWord).reverse().join('');

      const correctIndex = prevState.keyword.findIndex((x) => {
        if (selectingString.toLowerCase().includes(x)) return true;

        if (selectingReverseString.toLowerCase().includes(x)) {
          return true;
        }

        return false;
      });

      if (correctIndex > -1) {
        const correctWord = prevState.keyword[correctIndex];
        selectingArray = selectingArray.map((x, wordIndex) => (
          {
            ...x,
            isCorrect: correctWord.indexOf(x.itemWord.toLowerCase()) > -1 && wordIndex < correctWord.length && selectingArray.length === correctWord.length,
          }
        ));
      }
      //chọn lần 2 sẽ xóa
      let drawedArray = prevState.drawedArray;
      const drawedArray_id = drawedArray.map(x => x.id);
      const selectingArray_id = selectingArray.map(x => x.id);

      if (selectingArray_id.every(x => drawedArray_id.includes(x))) {
        drawedArray = drawedArray.filter(x => !selectingArray_id.includes(x.id));
      }
      else {
        drawedArray = drawedArray.concat(selectingArray);
      }
      //
      return ({
        ...prevState,
        selectingArray: [],
        currentMouseState: mouseState.UP,
        mouseEndPoint: { index, rowIndex },
        // drawedArray: prevState.drawedArray.concat(selectingArray),
        drawedArray,
        isDisabledSubmit: false,
      });
    });
  }, []);

  const onMouseDownOnWord = React.useCallback((itemWord, index, rowIndex) => () => {
    setState((prevState) => ({
      ...prevState,
      currentMouseState: mouseState.DOWN,
      mouseStartPoint: { index, rowIndex, timestamp: Date.now() },
    }));
  }, []);

  const onMouseMoveOnWord = React.useCallback((itemWord, index, rowIndex) => () => {
    if (state.currentMouseState !== mouseState.DOWN) return;

    setState((prevState) => {
      const loopRowIndex = prevState.selectingArray[1]?.rowIndex;
      const loopColumnIndex = prevState.selectingArray[1]?.index;

      const isStraightLine = prevState.selectingArray.every((item, index) => {
        if (index === 0) return true;

        // Same row
        if (item.rowIndex === loopRowIndex) return true;

        // Same column
        if (item.index === loopColumnIndex) return true;

        return false;
      });

      const isCrossLine = prevState.selectingArray.every((item, index) => {
        if (index === 0) return true;

        // Not same row and not same column -> cross
        if (item.rowIndex !== loopRowIndex && item.index !== loopColumnIndex) return true;

        return false;
      });

      const id = `${rowIndex}-${index}`;

      const selectingArray = JSON.parse(JSON.stringify(prevState.selectingArray));

      const itemIndex = selectingArray.findIndex((x) => x.id === id);

      if (itemIndex === -1) {
        selectingArray.push({
          id,
          index,
          itemWord,
          rowIndex,
          timestamp: state.mouseStartPoint?.timestamp
        });
      }

      // If not same line and same column
      if (!isStraightLine && !isCrossLine) {
        const firstIndex = selectingArray[0].index;
        const lastIndex = selectingArray[selectingArray.length - 1].index;
        const isDiagonalLine = lastIndex === firstIndex + selectingArray.length - 1;
        if (!isDiagonalLine) {
          return ({
            ...prevState,
            selectingArray: [],
            currentMouseState: mouseState.UP,
          });
        }
      }

      // // If not same line and same column
      // if (!isStraightLine && !isCrossLine) {
      //   const firstIndex = selectingArray[0].index;
      //   const lastIndex = selectingArray[selectingArray.length - 1].index;
      //   const isDiagonalLine = lastIndex === firstIndex + selectingArray.length;

      //   // Diagonal line = đường chéo
      //   if (isDiagonalLine) {
      //     console.log('isDiagonalLine');
      //     // while (selectingArray.findIndex((x, sIndex) => {
      //     //   return sIndex > 0 && x.index
      //     // })) {

      //     // }
      //   }
      // }

      return ({ ...prevState, selectingArray });
    })
  }, [state.currentMouseState, state.mouseStartPoint?.timestamp]);

  const renderWord = React.useCallback((word, rowIndex) => {
    return (
      <div
        key={rowIndex}
        // onMouseUp={onMouseUp}
        // onMouseMove={onMouseDown}
        className={styles.contanerWordList}
      >
        {word.map((itemWord, index) => {
          const id = `${rowIndex}-${index}`;

          const customStyle = {};

          const drawIndex = state.drawedArray.findIndex((x) => x.id === id);

          const isSelecting = (
            drawIndex > -1
            || state.selectingArray.findIndex((x) => x.id === id) > -1
          );

          if (isSelecting) {
            customStyle.color = 'white';
            customStyle.backgroundColor = 'orange';
            if (drawIndex > -1 && state.drawedArray[drawIndex].isCorrect) {
              customStyle.backgroundColor = 'green';
            }
          }

          return (
            <span
              id={id}
              key={index}
              style={customStyle}
              className={styles.itemWord}
              onMouseUp={onMouseUpOnWord(itemWord, index, rowIndex)}
              onMouseDown={onMouseDownOnWord(itemWord, index, rowIndex)}
              onMouseMove={onMouseMoveOnWord(itemWord, index, rowIndex)}
            >
              {itemWord}
            </span>
          )
        })}
      </div>
    )
  }, [onMouseDownOnWord, onMouseMoveOnWord, onMouseUpOnWord, state.drawedArray, state.selectingArray])

  const renderWordSearch = React.useMemo(() => {
    return state.lines.map((item, index) => {
      // const word = item.split(' ');
      return renderWord(item, index)
    })
  }, [renderWord, state.lines])

  // const renderInput = React.useCallback((words) => {
  //   if (!words) return null;
  //   const className = words.title !== 'positive' ? "far fa-sad-tear ml-2" : "far fa-smile ml-2";
  //   let currentInputNo = 0;
  //   return (
  //     <>
  //       <div style={{ fontFamily: 'OneStrokeScriptStd-Bold', fontWeight: 500, fontSize: 25 }}>
  //         {words.title}
  //         <i className={className}></i>
  //       </div>
  //       {words.answerInputs.map((item, index) => {

  //         currentInputNo = inputCount.current;

  //         // const maxInput = state.sentences[0].answer.length
  //         inputCount.current++;
  //         if (inputCount.current >= 9) {
  //           inputCount.current = 0;
  //         }
  //         return (
  //           <Form.Item
  //             className='ml-2 mr-2'
  //             name={currentInputNo}
  //             style={{ display: 'inline-block', marginBottom: 0 }}
  //           // rules={[{ required: true, message: 'Please fill the answer' },]}
  //           >
  //             <div className='d-flex mt-1 mb-1'>
  //               <Input
  //                 style={{
  //                   fontSize: 25,
  //                   borderWidth: 0,
  //                   borderRadius: 0,
  //                   borderBottomWidth: 1,
  //                   width: 350,
  //                   backgroundColor: 'white',
  //                   borderBottomColor: '#bdc3c7',
  //                   fontFamily: 'OneStrokeScriptStd-Bold',
  //                   textAlign: 'center',
  //                   boxShadow: 'none',
  //                   borderRightColor: 'white',
  //                   color: state.isDisabledInput ? (state.booleanArray?.[currentInputNo] ? '#2ecc71' : '#e74c3c') : 'black',
  //                 }}
  //                 id={currentInputNo}
  //                 disabled={state.isPointed}
  //                 className={!state.isPointed ? styles.input : styles.checkInput}
  //               />
  //               {state.isPointed && state.booleanArray?.[currentInputNo] && (
  //                 <CheckCircleOutlined className='mt-2' style={{ fontSize: 25, color: '#2ecc71' }} />
  //               )}
  //               {state.isPointed && !state.booleanArray?.[currentInputNo] && (
  //                 <CloseCircleOutlined className='mt-2' style={{ fontSize: 25, color: '#e74c3c' }} />
  //               )}
  //             </div>
  //           </Form.Item>
  //         )
  //       })}
  //     </>
  //   )
  // }, [state.isDisabledInput, state.isPointed, state.booleanArray])

  if (!state.lines) return null;

  return (
    <Container className='fluid'>
      <Row style={{ display: 'flex', justifyContent: 'center', marginRight: 450, minWidth: 1100 }}>
        <TitleQuestion titleQuestion={props.titleQuestion} />
      </Row>

      <Row className='d-flex justify-content-center mb-3'>
        {!!props.titleImage && (
          <img src={props.titleImage} alt='...' style={{ width: '60%', marginBottom: 10 }} />
        )}
        <Col className='d-initial justify-content-center'>
          {renderWordSearch}
        </Col>
      </Row>
      {/* <Form
        autoComplete="off"
        form={form}
        ref={refForm}
        onFinish={onFinish}
      >
        <Row className='d-flex justify-content-center mt-5'>
          <Col className='d-flex flex-column align-items-center'>
            {renderInput(props.questions[0])}
          </Col>
          <Col className='d-flex flex-column align-items-center'>
            {renderInput(props.questions[1])}
          </Col>
        </Row>
        <Button style={{ display: 'none' }} ref={submitButton} id='submitButton' htmlType="submit"></Button>
      </Form> */}
      <FooterIeltsMindset
        exerciseKey={props.exerciseKey}
        isDisabledSubmit={state.isDisabledSubmit}
        isDisabledRetry={state.isDisabledRetry}
        result={state.booleanArray}
        onSubmit={onSubmit}
        onRetry={onRetry}
      // onPlayVideo={onPlayVideo}
      />
    </Container>
  );
};
WordsFind.propTypes = {
  // allowPress: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object),

}
export default WordsFind;