import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';



const json = {
  5: {
    // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P27-E5',
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page27/E5/Key/answerKey.png",
    inputSize: 420,
    titleImage: "img/FriendsPlus/Page27/E5/1.jpg",
    titleQuestion: [{ num: '5', title: 'Look and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 58px;'>
        <div style=" display: flex; justify-content: space-between; width: 940px; "><span style=" width: 200px; "><b>1</b> (Dad, work)</span> <u style="color: gray;">&emsp; Did Dad work today? &emsp;&emsp;&emsp;</u> <u style="color: gray;"> &emsp; No, he didn’t. &emsp;&emsp;</u></div>
        <div style=" display: flex; justify-content: space-between; width: 1160px; "><span style=" width: 250px; "><b>2</b> (Dad, listen)</span> # #</div>
        <div style=" display: flex; justify-content: space-between; width: 1160px; "><span style=" width: 250px; "><b>3</b> (the children, tennis)</span> # #</div>
        <div style=" display: flex; justify-content: space-between; width: 1160px; "><span style=" width: 250px; "><b>4</b> (Mom, cook)</span> # #</div>
      </div>
        `,
        answer: [
          // "Did Dad work today?", "No, he didn't.",
          "Did Dad listen to music today?", "Yes, he did.",
          "Did the children play tennis today?", "No, they didn't.",
          "Did Mom cook today?", "Yes, she did.",
        ],
      },
    ],
  },
  6: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P27-E6',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page27/E6/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E6/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page27/E6/2.jpg' },
        { url: 'img/FriendsPlus/Page27/E6/3.jpg', input: true, answer: "cook" },
        { url: 'img/FriendsPlus/Page27/E6/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page27/E6/5.jpg' },
        { url: 'img/FriendsPlus/Page27/E6/6.jpg', input: true, answer: "wool" },
        { url: 'img/FriendsPlus/Page27/E6/7.jpg' },
        { url: 'img/FriendsPlus/Page27/E6/8.jpg', input: true, answer: "wood" },
        { url: 'img/FriendsPlus/Page27/E6/9.jpg' },
        { url: 'img/FriendsPlus/Page27/E6/10.jpg', input: true, answer: "book" },
        { url: 'img/FriendsPlus/Page27/E6/11.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page27/E6/12.jpg' },
      ],
    ],
  },
  7: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P27-E7',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page27/E6/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page27/E7/1.jpg' },
      ],
    ],
  },

}

export default json;