import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P21-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page21/E1/Key/answerKey.png',
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page21/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/3.jpg', input: true, answer: "eat" },
        { url: 'img/FriendsPlus/Page21/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/5.jpg', input: true, answer: "eating" },
        { url: 'img/FriendsPlus/Page21/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page21/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/8.jpg', input: true, answer: "wears" },
        { url: 'img/FriendsPlus/Page21/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page21/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/11.jpg', input: true, answer: "wearing" },
        { url: 'img/FriendsPlus/Page21/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/13.jpg', input: true, answer: "sit" },
        { url: 'img/FriendsPlus/Page21/E1/14.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page21/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/16.jpg', input: true, answer: "sitting" },
        { url: 'img/FriendsPlus/Page21/E1/17.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/18.jpg', input: true, answer: "talking" },
        { url: 'img/FriendsPlus/Page21/E1/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page21/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page21/E1/21.jpg', input: true, answer: "saying" },
        { url: 'img/FriendsPlus/Page21/E1/22.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page21/E1/23.jpg' },
      ],

    ],
  },
  // 2: { // Exercise num
  //   unit: 'Unit 3',
  //   id: 'WB5-U3-P21-E2',
  //   audio: '',
  //   video: '',
  //   component: T6,
  //   // exerciseKey: 'img/FriendsPlus/Page19/E3/Key/answerKey.png',
  //   inputSize: 600,
  //   // titleImage: 'img/FriendsPlus/Page18/E1/1.jpg',
  //   titleQuestion: [{ num: '2', title: 'Read again. Write the sentences.', color: "#5B5A5D" }],
  //   questionImage: [],
  //   questions: [
  //     {
  //       title:
  //         `
  // 				<div style="display: flex;flex-direction: row;justify-content: space-between;width: 1340px;">
  //           <div style='line-height: 58px;'>
  //             <div style=" color: transparent; ">a</div>
  //             <div><b>1</b> (eat)</div>
  //             <div><b>2</b> (wear)</div>
  //             <div><b>3</b> (sit)</div>
  //           </div>
  //           <div style='line-height: 58px;'>
  //             <div><b>Today</b></div>
  //             <div style=" width: 600px; border-bottom: solid gray 2px; height: 46px; color: gray; ">She is eating sandwiches and cake.</div>
  //             <div>#</div>
  //             <div>#</div>
  //           </div>
  //           <div style='line-height: 58px;'>
  //             <div><b>Usually</b> </div>
  //             <div>#</div>
  //             <div style=" width: 600px; border-bottom: solid gray 2px; height: 46px; color: gray; ">She wears pants and a T-shirt.</div>
  //             <div>#</div>
  //           </div>
  //         </div>
  //           `,
  //       answer: [
  //         // "",
  //         "She is wearing a pretty dress.",
  //         "She is sitting at the biggest table in the restaurant.",

  //         "She eats pasta and salad.",
  //         // "",
  //         "She sits at a small table.",
  //       ],
  //     },
  //   ]
  // },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P21-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page21/E2/Key/answerKey.png',
    component: D1,
    checkUppercase: true,
    inputHeight: 50,
    fontSize: 22,
    padding: 0,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page21/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page21/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/3.jpg', input: true, answer: "She eats pasta and salad." },
        { url: 'img/FriendsPlus/Page21/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page21/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/6.jpg', input: true, answer: "She is wearing a pretty dress." },
        { url: 'img/FriendsPlus/Page21/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page21/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/9.jpg', input: true, answer: "She is sitting at the biggest table in the restaurant." },
        { url: 'img/FriendsPlus/Page21/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page21/E2/11.jpg', input: true, answer: "She sits at a small table." },
        { url: 'img/FriendsPlus/Page21/E2/12.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P21-E3',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page21/E3/Key/answerKey.png',
    inputSize: 800,
    titleImage: 'img/FriendsPlus/Page21/E3/title.jpg',
    titleQuestion: [{ num: '3', title: 'Look at the picture and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div style='line-height: 58px;'>
              <div><b>1</b> <u style='color: gray;'> He usually plays soccer. But today he’s reading.<span style=" letter-spacing: 85px; color: transparent; ">_ _</span></u></div>
              <div><b>2</b># </div>
              <div><b>3</b># </div>
              <div><b>4</b># </div>
            </div>            
          `,
        answer: [
          "He usually eats a sandwich. But today he's eating a bowl of soup.",
          "He usually does his homework. But today he's staying in bed.",
          "He usually drinks a glass of milk. But today he's taking medicine.",
        ],
      },
    ]
  },
}

export default json;