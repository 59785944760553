import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P71-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page71/E1/Key/answerKey.png',
    inputSize: 200,
    titleImage: "img/FriendsPlus/Page71/E1/1.jpg",
    // titleQuestion: [{ num: '1', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" line-height: 58px;">
            <div><b>1</b> A word for someone who smiles and talks to people they do not know.</div>
            <div><u style="color: gray">&ensp; friendly &ensp;</u></div>
            <div><b>2</b> The time when you are traveling from one place to another.</div>
            <div>#</div>
            <div><b>3</b> You feel this if something isn’t as good as you wanted.</div>
            <div>#</div>
            <div><b>4</b> A word for fruit and vegetables that are very new.</div>
            <div>#</div>
            <div><b>5</b> When something is different from what usually happens. </div>
            <div>#</div>
            <div><img src='img/FriendsPlus/Page71/E1/2.jpg' /></div>
          </div>
        `,
        answer: [
          "a journey",
          "disappointed",
          "fresh",
          "unusual",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P71-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page71/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page71/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page71/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page71/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page71/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page71/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page71/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page71/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page71/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page71/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page71/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page71/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page71/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page71/E2/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page71/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page71/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/25.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page71/E2/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page71/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/28.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page71/E2/29.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page71/E2/31.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page71/E2/32.jpg' },
      ],

    ],
  },

}

export default json;