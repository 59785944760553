import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import WordsFind from '../../components/ExcerciseTypes/WordsFind';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P63-E1',
    audio: '',
    video: '',
    component: WordsFind,
    titleImage: 'img/FriendsPlus/Page63/E1/title.png',
    exerciseKey: 'img/FriendsPlus/Page63/E1/key.png',
    // recorder: true,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [],
    array: [
      ["M", "I", "L", "L", "N", "E", "S", "S", "A"],
      ["A", "L", "T", "T", "U", "N", "K", "T", "D"],
      ["R", "R", "S", "C", "M", "G", "Z", "O", "F"],
      ["O", "O", "R", "S", "F", "Y", "U", "R", "G"],
      ["B", "A", "W", "H", "F", "Y", "U", "M", "G"],
      ["O", "D", "V", "Y", "O", "E", "D", "G", "S"],
      ["T", "F", "E", "E", "L", "I", "N", "G", "S"],
      ["C", "R", "O", "W", "D", "E", "D", "C", "B"],
    ],
    initialValues: [
      { id: '6-1', isCorrect: true, timestamp: Date.now(), itemWord: "F" },
      { id: '6-2', isCorrect: true, timestamp: Date.now(), itemWord: "E" },
      { id: '6-3', isCorrect: true, timestamp: Date.now(), itemWord: "E" },
      { id: '6-4', isCorrect: true, timestamp: Date.now(), itemWord: "L" },
      { id: '6-5', isCorrect: true, timestamp: Date.now(), itemWord: "I" },
      { id: '6-6', isCorrect: true, timestamp: Date.now(), itemWord: "N" },
      { id: '6-7', isCorrect: true, timestamp: Date.now(), itemWord: "G" },
      { id: '6-8', isCorrect: true, timestamp: Date.now(), itemWord: "S" },
    ],

    questions: [
      {
        answerInputs: ['illness', 'robot', 'road', 'storm', 'feelings', 'crowded']
      }
    ]
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P63-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page63/E2/Key/answerKey.png',
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page63/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use the words above.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div><b>1</b> A kind of computer that can move and work. <u style="color: gray;">&ensp; robot &ensp;</u></div>
          <div><b>2</b> When there are lots of people in a small space, the place is #.</div>
          <div><b>3</b> Heavy rain and thunder. #</div>
          <div><b>4</b> Robots do not have these. #</div>
          <div><b>5</b> Cars go along this. #</div>
          <div><b>6</b> Doctors help us with this. #</div>
        </div>
        `,
        answer: [
          "crowed",
          "storm",
          "feelings",
          "road",
          "illness",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P63-E3',
    audio: '',
    video: '',
    component: T6,
    textAlign: 'center',
    exerciseKey: 'img/FriendsPlus/Page63/E3/Key/answerKey.png',
    inputSize: 80,
    // titleImage: "img/FriendsPlus/Page63/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Read the text in the Student Book. Write T (True) or F (False).', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
          <div style=" line-height: 48px; ">
            <div><b>1</b> Robots will do everything for us. <u style="color: gray;">&emsp; T &emsp;</u></div>
            <div><b>2</b> There will be fewer people on Earth. #</div>
            <div><b>3</b> There will be roads in the sky. #</div>
            <div><b>4</b> It will be colder. #</div>
            <div><b>5</b> There will be more storms. #</div>
            <div><b>6</b> There will be a lot more water on Earth. #</div>
          </div>
          <div><img src='img/FriendsPlus/Page63/E3/1.jpg' /></div>
        </div>
        `,
        answer: [
          "F",
          "T",
          "F",
          "T",
          "T",
        ],
      },
    ],
  },
}

export default json;