import React from 'react'
import { Row, Input, Container, Modal, ModalBody, Button } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import TitleQuestion from '../../TitleQuestion';
import FooterModal from '../../FooterModal';
import StarModal from './StarModal';
import Editer, { calculateResultText } from './Editer';
import 'moment/locale/vi';
import moment from 'moment';
moment.locale('vi');

function TextEditer(props) {
  const [state, setState] = React.useState({
    booleanArray: [],
    isDisabledRetry: true,
    isDisabledSubmit: true,
  });
  const [DataEditer, setDataEditer] = React.useState({
    text: '',
    content: '',
    isDoing: true,
  });
  const [Question, setQuestion] = React.useState(null);
  const [modalStar, setModalStar] = React.useState(false);

  React.useEffect(() => {
    const clonedProps = JSON.parse(JSON.stringify(props));
    const question = clonedProps.question;
    //
    const audioUrl = clonedProps.audio;
    const videoUrl = clonedProps.video;

    setState((prevState) => ({ ...prevState, audioUrl, videoUrl }));
    setQuestion(question);
  }, [props]);
  // hiển thị ngôi sao
  const onModalStar = () => {
    setModalStar(true)
    setTimeout(() => { setModalStar(false) }, 3000);
  };
  //
  const onSubmit = React.useCallback(() => {
    const { text } = DataEditer;
    const answer = Question.editer.answer;
    const result = calculateResultText(text, answer);
    const { star, booleanArray, percent, resultString } = result;
    console.log("🚀 ~ result", result)
    const studentAnswer = DataEditer.text;
    const params = {
      score: percent,
      unit: props.unit,
      results: resultString,
      exerciseId: props.id,
      sreatedDate: moment().format(),
      studentAnswer: JSON.stringify(studentAnswer),
    }
    props.postAnswerToApi(params)
    console.log("🚀 ~ params", params)

    setDataEditer(pre => ({ ...pre, content: result.content, isDoing: false }))
    setState(pre => ({ ...pre, isDisabledRetry: false, isDisabledSubmit: true, booleanArray, star }));
    onModalStar();
  }, [DataEditer, Question, props]);
  //
  const onRetry = React.useCallback(() => {
    const clonedProps = JSON.parse(JSON.stringify(props));
    const question = clonedProps.question;

    setQuestion(question);
    setState(pre => ({ ...pre, isDisabledRetry: true }));
  }, [props]);
  //
  const transform = React.useCallback((node, index) => {
    const { editer } = Question;
    if (node.type === "tag" && node.name === 'editer') { //render tag editer
      return < Editer
        key={index}
        question={editer}
        Data={DataEditer}
        setData={setDataEditer}
        setState={setState}
      />
    }
  }, [DataEditer, Question]);
  //
  const renderContent = React.useCallback((value) => {
    const { Layout } = Question;
    return (
      <div style={{ position: 'relative', fontSize: 24, color: '#302E2F' }}>
        {ReactHtmlParser(Layout, { transform })}
      </div>
    );
  }, [Question, transform]);

  if (!Question) return null;

  return (
    <Container >
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
        <div>
          <Row>
            <TitleQuestion titleQuestion={props.titleQuestion} />
          </Row>
          <Row>
            <img src={props.titleImage} alt='' />
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
            {renderContent()}
          </Row>
          <StarModal isModal={modalStar} star={state.star} />
        </div>
      </div>

      {/* {testButton({ Question, setDataEditer })} */}

      <FooterModal
        onRetry={onRetry}
        onSubmit={onSubmit}
        audioUrl={state.audioUrl}
        result={state?.booleanArray}
        studentAnswer={state.studentAnswer}
        exerciseKey={props.exerciseKey}
        isDisabledRetry={state.isDisabledRetry}
        isDisabledSubmit={state.isDisabledSubmit}
        hideBtnFooter={props.hideBtnFooter}  //not answer
      />
    </Container >
  )
}

export default TextEditer;

// test
function testButton({ Question, setDataEditer }) {
  return (
    <div>
      <Button
        onClick={() => {
          setDataEditer(pre => {
            let { content } = pre;
            content = Question.editer.answer.replaceAll('\n', '<br/>');
            return { ...pre, content }
          });
        }}
      >
        điền đáp án test
      </Button>
    </div >
  )
}
