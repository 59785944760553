import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P66-E1',
    audio: '',
    video: '',
    component: D1,
    checkDuplicated: true,
    exerciseKey: 'img/FriendsPlus/Page66/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page66/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page66/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/3.jpg', input: true, answer: "hard/new/comfortable/old" },
        { url: 'img/FriendsPlus/Page66/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/5.jpg', input: true, answer: "suitcase/bed/chair/traffic lights" },
        { url: 'img/FriendsPlus/Page66/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page66/E1/7.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/8.jpg', input: true, answer: "went/broke/turn" },
        { url: 'img/FriendsPlus/Page66/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/10.jpg', input: true, answer: "hard/new/comfortable/old" },
        { url: 'img/FriendsPlus/Page66/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/12.jpg', input: true, answer: "suitcase/bed/chair/traffic lights" },
        { url: 'img/FriendsPlus/Page66/E1/13.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page66/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/15.jpg', input: true, answer: "went/broke/turn" },
        { url: 'img/FriendsPlus/Page66/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/17.jpg', input: true, answer: "hard/new/comfortable/old" },
        { url: 'img/FriendsPlus/Page66/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/19.jpg', input: true, answer: "suitcase/bed/chair/traffic lights" },
        { url: 'img/FriendsPlus/Page66/E1/20.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page66/E1/21.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/22.jpg', input: true, answer: "went/broke/turn" },
        { url: 'img/FriendsPlus/Page66/E1/23.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/24.jpg', input: true, answer: "hard/new/comfortable/old" },
        { url: 'img/FriendsPlus/Page66/E1/25.jpg' },
        { url: 'img/FriendsPlus/Page66/E1/26.jpg', input: true, answer: "suitcase/bed/chair/traffic lights" },
        { url: 'img/FriendsPlus/Page66/E1/27.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page66/E1/28.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P66-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page66/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Circle the odd-one-out.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "left right straight early", // 0
          "repair cheap expensive modern ", // 1
          "map worry prepare share", // 2
          "traffic light gas station lazy school ", // 3
          "plastic late puppet screen", // 4
        ],
        answers: ['0-6', '1-0', '2-0', '3-8', '4-2'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;font-size: 22px;justify-content: space-between;width: 850px; line-height: 58px; word-spacing: 10px ;">
        <div>
          <div><b>1</b> rocket <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">will</span> astronaut spaceship</div>
          <div><b>3</b> <input id='1' type='Circle' /></div>
          <div><b>5</b> <input id='3' type='Circle' /></div>
        </div>
        <div>
          <div><b>2</b> <input id='0' type='Circle' /></div>
          <div><b>4</b> <input id='2' type='Circle' /></div>
          <div><b>6</b> <input id='4' type='Circle' /></div>
        </div>
      </div>
      `,
    },
  },
  3: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P66-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page66/E3/Key/answerKey.png',
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page66/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Complete the sentences with had to or have to.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 58px; ">
          <div><b>1</b> Yesterday I <u style="color: gray;">had to</u> go home from school early because I was ill.</div>
          <div><b>2</b> To get to the store, you # turn right at the traffic lights.</div>
          <div><b>3</b> He didn’t do well in his homework and he # do it again.</div>
          <div><b>4</b> When they are at the bridge, they # turn left.</div>
        </div>
        `,
        answer: [
          "have to",
          "had to",
          "have to",
        ],
      },
    ],
  },
}

export default json;