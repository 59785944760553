import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P68-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page68/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Read and circle the correct word.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "passport / departures", //0
          "money / magazines", //1
          "magazine / newspaper", //2
          "money / departures", //3
          "arrivals / departures", //4
          "passenger / magazine", //5
          "luggage / passenger", //6
        ],
        answers: ['0-0', '1-0', '2-4', '3-4', '4-0', '5-4', '6-4'],
        initialValue: [],
      },
      Layout: `
      <div style="line-height: 58px;">
        <div><b>1</b> <b>Passport /</b><b style="border: 2px solid gray; border-radius: 50%; padding: 3px;">Luggage</b> is all the bags you take on vacation.</div>
        <div><b>2</b> Your <b><input id='0' type='Circle' /></b> is a little book with your name, photo, and information</div>
        <div> &ensp; about where you are from.</div>
        <div><b>3</b> You use <b><input id='1' type='Circle' /></b> to buy things from stores.</div>
        <div><b>4</b> You read a <b><input id='2' type='Circle' /></b> to see what is happening in the world.</div>
        <div><b>5</b> When you are going on vacation, you go through <b><input id='3' type='Circle' /></b> at the airport.</div>
        <div><b>6</b> When your plane lands, you go through <b><input id='4' type='Circle' /></b>.</div>
        <div><b>7</b> You can read a <b><input id='5' type='Circle' /></b> at the airport before you get on the plane.</div>
        <div><b>8</b> A <b><input id='6' type='Circle' /></b> is a person who travels on a plane.</div>
      </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P68-E2',
    audio: 'img/FriendsPlus/Page68/Audio/audio-e2-p68.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page68/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page68/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page68/Audio/tieude-e2-p68.mp3' },
        { url: 'img/FriendsPlus/Page68/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page68/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page68/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E2/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page68/E2/8.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page68/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page68/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page68/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page68/E2/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page68/E2/14.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page68/E2/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page68/E2/17.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page68/E2/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page68/E2/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page68/E2/20.jpg' },
      ],
    ],
  },

}

export default json;