import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P35-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page35/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/3.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page35/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/6.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page35/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/9.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page35/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page35/E1/12.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page35/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page35/E1/14.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P35-E2',
    audio: '',
    video: '',
    component: D1,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page35/E2/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page35/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page35/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/3.jpg', input: true, answer: "postcards." },
        { url: 'img/FriendsPlus/Page35/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page35/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/6.jpg', input: true, answer: "They heard old songs." },
        { url: 'img/FriendsPlus/Page35/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page35/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/9.jpg', input: true, answer: "They didn't make books about dinosaurs." },
        { url: 'img/FriendsPlus/Page35/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page35/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/12.jpg', input: true, answer: "They didn't eat ice cream." },
        { url: 'img/FriendsPlus/Page35/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page35/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page35/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/16.jpg', input: true, answer: "Class 5P didn't go to a museum." },
        { url: 'img/FriendsPlus/Page35/E2/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page35/E2/18.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/19.jpg', input: true, answer: "They didn't hear old songs." },
        { url: 'img/FriendsPlus/Page35/E2/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page35/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/22.jpg', input: true, answer: "They made books about dinosaurs." },
        { url: 'img/FriendsPlus/Page35/E2/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page35/E2/24.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/25.jpg', input: true, answer: "They bought postcards." },
        { url: 'img/FriendsPlus/Page35/E2/26.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page35/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page35/E2/28.jpg', input: true, answer: "They ate ice cream." },
        { url: 'img/FriendsPlus/Page35/E2/29.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page35/E2/30.jpg' },
      ],
    ],
  },

}

export default json;