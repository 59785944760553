import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';


const json = {
	1: { // Exercise num
		unit: 'Unit 7',
		id: 'WB5-U7-P48-E1',
		audio: '',
		video: '',
		component: DesignUnderLine,
		totalInput: 7,
		exerciseKey: 'img/FriendsPlus/Page48/E1/Key/answerKey.png',
		titleImage: '',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page48/E1/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page48/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/3.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/4.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page48/E1/5.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page48/E1/6.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/7.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page48/E1/8.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/9.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page48/E1/10.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/11.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page48/E1/12.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/13.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page48/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/15.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/16.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page48/E1/17.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page48/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/19.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page48/E1/20.jpg', input: 5, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/21.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page48/E1/22.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/23.jpg', input: 6, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/24.jpg', input: 6 },
				{ url: 'img/FriendsPlus/Page48/E1/25.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page48/E1/26.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page48/E1/27.jpg' },
				{ url: 'img/FriendsPlus/Page48/E1/28.jpg', input: 7, isCorrect: true },
				{ url: 'img/FriendsPlus/Page48/E1/29.jpg', input: 7 },
				{ url: 'img/FriendsPlus/Page48/E1/30.jpg' },
			],
			[
				// Column10
				{ url: 'img/FriendsPlus/Page48/E1/31.jpg' },
			],

		],
	},
	2: { // Exercise num
		unit: 'Unit 7',
		id: 'WB5-U7-P48-E2',
		audio: '',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page48/E2/Key/answerKey.png',
		titleQuestion: [{ num: '2', title: 'Read again. Draw the route on the map', color: "#5B5A5D" }],
		question: {
			DrawLines: {
				multipleLine: true,
				boxMatch: [
					{ boxMatchStyle: { position: 'absolute', top: '377px', left: '221px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//0
					{ boxMatchStyle: { position: 'absolute', top: '196px', left: '220px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//1
					{ boxMatchStyle: { position: 'absolute', top: '194px', left: '476px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//2
					{ boxMatchStyle: { position: 'absolute', top: '77px', left: '475px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//3
					{ boxMatchStyle: { position: 'absolute', top: '194px', left: '23px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//4
					{ boxMatchStyle: { position: 'absolute', top: '377px', left: '308px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//5
					{ boxMatchStyle: { position: 'absolute', top: '194px', left: '757px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//6
					{ boxMatchStyle: { position: 'absolute', top: '74px', left: '755px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//7
					{ boxMatchStyle: { position: 'absolute', top: '193px', left: '881px', width: 30, height: 30, backgroundColor: 'gray', borderRadius: '50%' }, },	//8
				],
				answers: ['0-1', '1-2', '2-3'],
				initialValue: [],
			},
			Layout: `
          <img src='img/FriendsPlus/Page48/E2/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
      `,
		},
	},

}

export default json;