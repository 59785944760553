import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P78-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page78/E1/Key/answerKey.png',
    inputSize: 150,
    titleImage: "img/FriendsPlus/Page78/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Write. Use the words with the prefix un.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 48px; ">
          <div><b>1</b> This bed is very hard. It is <u style="color: gray;">&emsp; uncomfortable &emsp;</u> .</div>
          <div><b>2</b> I’m feeling # because I’ve lost my favorite hat.</div>
          <div><b>3</b> When a day is not like other days it is # .</div>
          <div><b>4</b> The boy didn’t want to speak to me. He was very #.</div>
          <div><b>5</b> Something that no one has seen before is #.</div>
          <div><b>6</b> A TV show that people do not like is #.</div>
        </div>
        `,
        answer: [
          "unhappy",
          "unusual",
          "unfriendly",
          "unknown",
          "unpopular",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P78-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page78/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Read the flyer and underline the imperative sentences.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        // limitSelect: 1,
        listWords: [
          "Fido plays a trick on the girl. This makes the new girl unhappy, but soon she starts to laugh. Who is this unusual girl?", //0
          "The Red Rocket is lost in space and arrives on an unknown planet. Some of the astronauts start to feel bad and their skin turns blue. What will they do to survive?",	//1
          "Find out what events happened in the world today. Also includes a weather report.",	//2
          "Find out about school life 100 years ago. Were all teachers scary? Were all school chairs uncomfortable? Do you think children were unhappier than children today?",	//3
        ],
        answers: ['0-24', '0-44', '1-22', '3-32', '3-44'],
        initialValue: [],
      },
      Layout: `
      
      <div style=" position: relative; font-size: 20px; ">
        <div> <img src='img/FriendsPlus/Page78/E2/1.jpg' /> </div>
        <div style="position: absolute; top: 48px; left: 330px; width: 570px;">This week Sandy and Fido meet a new girl. At first she is <span style="border: 2px solid gray; border-radius: 50%; padding: 1px;">unfriendly.</span><input id='0' type='Circle' /></div>
        <div style="position: absolute; top: 198px; left: 330px; width: 570px;"><input id='1' type='Circle' /></div>
        <div style="position: absolute; top: 323px; left: 330px; width: 570px;"><input id='2' type='Circle' /></div>
        <div style="position: absolute; top: 417px; left: 330px; width: 570px;"><input id='3' type='Circle' /></div>
      </div>
      `,
    },
  },
  3: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P78-E3',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page78/E3/Key/answerKey.png',
    inputSize: 240,
    // titleImage: "img/FriendsPlus/Page78/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Read the last line of each description again. Write the shows in the order you would like to watch them. Put the best first.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div><b>1</b> # <b>2</b> # <b>3</b> # <b>4</b> #</div>
        `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;