import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P7-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page7/E1/Key/answerKey.png',
    maxLength: 1,
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/3.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page7/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/5.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page7/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/7.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page7/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/9.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page7/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page7/E1/11.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page7/E1/12.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page7/E1/13.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P7-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page7/E2/Key/answerKey.png',
    component: D1,
    checkUppercase: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page7/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page7/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/3.jpg', input: true, answer: "four fifteen." },
        { url: 'img/FriendsPlus/Page7/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page7/E2/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page7/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/7.jpg', input: true, answer: "It's eight fifty." },
        { url: 'img/FriendsPlus/Page7/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/9.jpg', input: true, answer: "It's three o'clock." },
        { url: 'img/FriendsPlus/Page7/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page7/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page7/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page7/E2/13.jpg', input: true, answer: "It's two fifty-five." },
        { url: 'img/FriendsPlus/Page7/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page7/E2/15.jpg' },
      ],

    ],
  },

}

export default json;