import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P34-E1',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page34/E1/Key/answerKey.png",
    inputSize: 150,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read and circle the words below.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div><b>1</b> You can go to this place to see things from the past. #</div>
          <div><b>2</b> These animals lived on Earth 46 million years ago. #</div>
          <div><b>3</b> Everyone in your class is this. #</div>
          <div><b>4</b> All of the dinosaurs are this. #</div>
          <div><b>5</b> A loud noise that some animals make. #</div>
          <div><b>6</b> Something that makes you feel scared is this. #</div>
          <div><b>7</b> People make these to show you what something looks like. #</div>
          <div><b>8</b> You make this noise when you are scared. #</div>
          <div><img src='img/FriendsPlus/Page34/E1/1.jpg' /></div>
        </div>
        `,
        answer: [
          "museum",
          "dinosaurs",
          "alive",
          "dead",
          "roar",
          "scary",
          "models",
          "scream",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P34-E2',
    audio: 'img/FriendsPlus/Page34/Audio/audio-e2-p34.mp3',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page34/E2/Key/answerKey.png",
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '2', title: 'Listen. Write the word from <b>1</b>.<headphone name="10" src="img/FriendsPlus/Page34/Audio/tieude-e2-p34.mp3"></headphone>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
          <div style=" line-height: 48px; ">
            <div><b>School trip</b></div>
            <div><b>1</b> Where did you go? The dinosaur <u style="color:gray;"> museum </u>.</div>
            <div><b>2</b> What did you see? # of dinosaurs.</div>
            <div><b>3</b> What was the most interesting? Listening to the</div>
            <div> &ensp; dinosaurs #.</div>
            <div><b>4</b> How did you feel? It was #.</div>
            <div><b>5</b> What did you learn? The last dinosaurs were</div>
            <div># 46 million years ago.</div>
          </div>
          <div><img src='img/FriendsPlus/Page34/E2/1.jpg' /></div>
        </div>
        `,
        answer: [
          "Models",
          "roar",
          "scary",
          "alive",
        ],
      },
    ],
  },

}

export default json;