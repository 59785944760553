import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P57-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page57/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page57/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page57/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/3.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page57/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/5.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page57/E1/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page57/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page57/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/9.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page57/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page57/E1/11.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page57/E1/12.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page57/E1/13.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P57-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page57/E2/Key/answerKey.png',
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page57/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use the words above.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div>
          <div><b>1</b> When something is very, very big, it is <u style="color:gray;"> enormous. </u></div>
          <div><b>2</b> When someone doesn’t want to work or study, the person is #.</div>
          <div><b>3</b> It is important to # your toys so that everyone can play.</div>
          <div><b>4</b> When you # about things, it’s difficult to sleep.</div>
          <div><b>5</b> You have to # for school. For example, get your school bag ready.</div>
        </div>
        `,
        answer: [
          "lazy",
          "share",
          "worry",
          "prepare",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 8',
    id: 'WB5-U8-P57-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page57/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page57/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page57/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page57/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page57/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page57/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page57/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page57/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page57/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page57/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page57/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page57/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page57/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page57/E3/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page57/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/25.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page57/E3/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page57/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/28.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page57/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/30.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/31.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page57/E3/32.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/33.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/34.jpg' },
        { url: 'img/FriendsPlus/Page57/E3/35.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page57/E3/36.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page57/E3/37.jpg' },
      ],

    ],
  },

}

export default json;