import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';



const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P30-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page30/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Read and circle the time markers. Write Xuan’s diary.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: { border: 'none' },
        inputStyle: { width: 480, fontSize: '20px' },
        answers: ['had a party', 'played in a basketball game', 'school, had dinner in a restaurant with my family'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        // limitSelect: 1,
        listWords: [
          "Four_days_ago she had her party. ",//0
          "Two_days_ago she played in a basketball",//1
          "game at school. Yesterday she was at",//2
          "school and last_night she had dinner",//3
          "in a restaurant with her family.",//4
        ],
        answers: ['0-0', '1-0', '2-6', '3-4'],
        initialValue: [],
      },
      Layout: `
        <div style="display: flex;flex-direction: row;justify-content: space-between;width: 1100px;">
          <div>
            <div>It was Xuan’s birthday <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;"> last Saturday. </span></div>
            <div> <input id='0' type='Circle' /> </div>
            <div> <input id='1' type='Circle' /> </div>
            <div> <input id='2' type='Circle' /> </div>
            <div> <input id='3' type='Circle' /> </div>
            <div> <input id='4' type='Circle' /> </div>
          </div>
          <div style=" position: relative; ">
            <img src='img/FriendsPlus/Page30/E1/1.jpg' />
            <div style=" position: absolute; top: 108px; left: 160px; "><input id='0' /></div>
            <div style=" position: absolute; top: 177px; left: 160px; "><input id='1' /></div>
            <div style=" position: absolute; top: 213px; left: 160px; "><input id='2' /></div> 
          </div>
        </div>
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P30-E2',
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "",
    inputSize: 600,
    titleImage: "",
    titleQuestion: [{ num: '2', title: 'Where were you at these times? Write sentences.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height: 58px;'>
          <div><b>1</b> (Yesterday) # </div>
          <div><b>2</b> (Two days ago) # </div>
          <div><b>3</b> (Last Saturday) # </div>
          <div><b>4</b> (Last summer)  # </div>
          </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P30-E3',
    audio: 'img/FriendsPlus/Page30/Audio/audio-e3-p30.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page30/E3/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tieude-e3-p30.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E3/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page30/E3/8.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page30/E3/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page30/E3/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page30/E3/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E3/12.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E3/13.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page30/E3/14.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page30/E3/15.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page30/E3/16.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page30/E3/17.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E3/18.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page30/E3/19.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page30/E3/20.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page30/E3/21.jpg' },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P30-E4',
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "",
    inputSize: 600,
    titleImage: "",
    titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height: 58px;'>
            <div><b>1</b> (bike) # </div>
            <div><b>2</b> (cry) # </div>
            <div><b>3</b> (blow) # </div>
            <div><b>4</b> (blue) # </div>
            <div><b>5</b> (light) # </div>
            <div><b>6</b> (room)  # </div>
          </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;