import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
	1: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P46-E1',
		audio: '',
		video: '',
		component: D1,
		checkDuplicated: true,
		exerciseKey: 'img/FriendsPlus/Page46/E1/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page46/E1/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page46/E1/2.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/3.jpg', input: true, answer: "cheered/find/bounce/bought/won" },
				{ url: 'img/FriendsPlus/Page46/E1/4.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/5.jpg', input: true, answer: "dinosaur/audience/skin/basketball/model/trophy" },
				{ url: 'img/FriendsPlus/Page46/E1/6.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/7.jpg', input: true, answer: "dinosaur/audience/skin/basketball/model/trophy" },
				{ url: 'img/FriendsPlus/Page46/E1/8.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page46/E1/9.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/10.jpg', input: true, answer: "cheered/find/bounce/bought/won" },
				{ url: 'img/FriendsPlus/Page46/E1/11.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/12.jpg', input: true, answer: "cheered/find/bounce/bought/won" },
				{ url: 'img/FriendsPlus/Page46/E1/13.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/14.jpg', input: true, answer: "dinosaur/audience/skin/basketball/model/trophy" },
				{ url: 'img/FriendsPlus/Page46/E1/15.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/16.jpg', input: true, answer: "dinosaur/audience/skin/basketball/model/trophy" },
				{ url: 'img/FriendsPlus/Page46/E1/17.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page46/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/19.jpg', input: true, answer: "cheered/find/bounce/bought/won" },
				{ url: 'img/FriendsPlus/Page46/E1/20.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/21.jpg', input: true, answer: "cheered/find/bounce/bought/won" },
				{ url: 'img/FriendsPlus/Page46/E1/22.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/23.jpg', input: true, answer: "dinosaur/audience/skin/basketball/model/trophy" },
				{ url: 'img/FriendsPlus/Page46/E1/24.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/25.jpg', input: true, answer: "dinosaur/audience/skin/basketball/model/trophy" },
				{ url: 'img/FriendsPlus/Page46/E1/26.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page46/E1/27.jpg' },
			],
		],
	},
	2: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P46-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: "img/FriendsPlus/Page46/E2/Key/answerKey.png",
		inputSize: 150,
		// titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
		titleQuestion: [{ num: '2', title: 'Write. Use the simple present, simple past, or present progressive.', color: "#5B5A5D" }],
		questionImage: [],
		questions: [
			{
				title: `
        <div style="line-height: 48px;">
					<div><b>1</b> Yesterday she ate (eat) sandwiches but today she’ s eating (eat) noodles.</div>
					<div><b>2</b> I usually # (go) to the movie theater but today I # (go) to</div>
					<div>the park.</div>
					<div><b>3</b> Last week we # (buy) a kitten and this week we # (play) with it.</div>
					<div><b>4</b> I always # (make) cakes with my mom and my sister, but today my</div>
					<div>brother # (help).</div>
        </div>
        `,
				answer: [
					"go", "'m going",
					"bought", "'re playing",
					"make", "is helping",
				],
			},
		],
	},
	3: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P46-E3',
		audio: '',
		video: '',
		component: Circle_Write,
		exerciseKey: 'img/FriendsPlus/Page46/E3/Key/answerKey.png',
		titleQuestion: [{ num: '3', title: 'Circle the odd-one-out.', color: "#5B5A5D" }],
		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {},
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { margin: '0 5px', border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
				selectWordStyle: { padding: 3, borderColor: '#4285F4' },
				limitSelect: 1,
				listWords: [
					"yesterday concert last_week two_days_ago", //0
					"bottle soup cup bowl", //1
					"drums violin gong scream", //2
					"plants usually never always",  //3
					"went saw made recorder", //4
				],
				answers: ['0-2', '1-2', '2-6', '3-0', '4-6'],
				initialValue: [],
			},
			Layout: `
      <div style="display: flex;flex-direction: row;font-size: 22px;justify-content: space-between;width: 900px; line-height: 58px;">
        <div>
          <div><b>1</b> team jacket <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">mine</span> trophy </div>
          <div><b>3</b> <input id='1' type='Circle' /></div>
          <div><b>5</b> <input id='3' type='Circle' /></div>
        </div>
        <div>
          <div><b>2</b> <input id='0' type='Circle' /></div>
          <div><b>4</b> <input id='2' type='Circle' /></div>
          <div><b>6</b> <input id='4' type='Circle' /></div>
        </div>
      </div>
      `,
		},
	},

}

export default json;