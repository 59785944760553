import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P31-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page31/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page31/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page31/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/3.jpg', input: true, answer: "rhythm" },
        { url: 'img/FriendsPlus/Page31/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page31/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page31/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/7.jpg', input: true, answer: "dragon" },
        { url: 'img/FriendsPlus/Page31/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/9.jpg', input: true, answer: "boom" },
        { url: 'img/FriendsPlus/Page31/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page31/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page31/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page31/E1/13.jpg', input: true, answer: "gong" },
        { url: 'img/FriendsPlus/Page31/E1/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page31/E1/15.jpg' },
      ],
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P31-E2',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page31/E2/Key/answerKey.png",
    inputSize: 100,
    titleImage: "img/FriendsPlus/Page31/E2/title.jpg",
    // titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height: 58px;'>
            <div>My brother plays the drums. I love watching his band. They play with (1) <u style="color:gray;">dragon</u> dancers.</div>
            <div>The dancers wear (2) # and red costumes. The band has a very loud drum. It</div>
            <div>plays a (3) # that keeps the whole band in time. A boy at the back hits the</div>
            <div>(4) # and it makes a big (5) # . The sound is so big but beautiful.</div>
          </div>
        `,
        answer: [
          "gold",
          "rhythm",
          "gong",
          "boom",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P31-E3',
    audio: "",
    video: "",
    component: T6,
    textAlign: 'center',
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/answerKey.png",
    inputSize: 80,
    // titleImage: "img/FriendsPlus/Page31/E3/title.jpg",
    titleQuestion: [{ num: '3', title: 'Read the poem in the Student Book. Write T (True) or F (False).', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="line-height: 58px;display: flex;flex-direction: row;justify-content: space-between;width: 700px;">
            <div>
              <div><b>1</b> The boy can hear the music from his bedroom.</div>
              <div><b>2</b> There are drums and gongs.</div>
              <div><b>3</b> People are not moving.</div>
              <div><b>4</b> The dragon sings on the street.</div>
              <div><b>5</b> The dragon’s body is gold and red.</div>
            </div>
            <div>
              <div><u style="color:gray; font-size: 26px;">&emsp; T &emsp;</u></div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
          </div>
        `,
        answer: [
          "T",
          "F",
          "F",
          "T",
        ],
      },
    ],
  },
}

export default json;