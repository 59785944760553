import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P28-E1',
    audio: 'img/FriendsPlus/Page28/Audio/audio-e1-p28.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page28/E1/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page28/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page28/Audio/tieude-e1-p28.mp3' },
        { url: 'img/FriendsPlus/Page28/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page28/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page28/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page28/E1/7.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page28/E1/8.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page28/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page28/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/12.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page28/E1/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E1/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page28/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page28/E1/16.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E1/17.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page28/E1/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page28/E1/19.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page28/E1/20.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'WB5-U4-P28-E2',
    audio: 'img/FriendsPlus/Page28/Audio/audio-e2-p28.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page28/E2/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page28/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page28/Audio/tieude-e2-p28.mp3' },
        { url: 'img/FriendsPlus/Page28/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page28/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page28/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page28/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page28/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/9.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page28/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/12.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page28/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page28/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page28/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/16.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page28/E2/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page28/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/21.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page28/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/23.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E2/24.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page28/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/26.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page28/E2/27.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/28.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page28/E2/29.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page28/E2/30.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/31.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page28/E2/32.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/33.jpg' },
        { url: 'img/FriendsPlus/Page28/E2/34.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page28/E2/35.jpg' },
      ],

    ],
  },

}

export default json;