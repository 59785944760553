import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P74-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page74/E1/Key/answerKey.png',
    inputSize: 200,
    // titleImage: "img/FriendsPlus/Page74/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read and circle the words.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" display: flex; flex-direction: row; ">
            <div style="line-height: 48px;width: 730px;">
              <div><b>1</b> When you switch on the TV, you can</div>
              <div>see lots of different # .</div>
              <div><b>2</b> A show for children with moving pictures. #</div>
              <div><b>3</b> This tells you about things you can buy. #</div>
              <div><b>4</b> To know what is happening in the world</div>
              <div>right now, you need to watch the # .</div>
              <div><b>5</b> This TV show gives you information. #</div>
              <div><b>6</b> You can use your # phone when you are out.</div>
              <div><b>7</b> To change the channel on the TV, you can</div>
              <div>use a remote # .</div>
              <div><b>8</b> There are lots of these on your TV. You choose</div>
              <div>one to watch with your remote control. #</div>
            </div>
            <div><img src='img/FriendsPlus/Page74/E1/1.jpg' /></div>
          </div>
        `,
        answer: [
          "shows",
          "cartoon",
          "advertisement",
          "news",
          "documentary",
          "cell",
          "control",
          "channel",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 11',
    id: 'WB5-U11-P74-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page74/E2/Key/answerKey.png',
    inputSize: 200,
    // titleImage: "img/FriendsPlus/Page74/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use the words above.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" line-height: 48px; position: relative; ">
            <div>Yesterday, we watched some different TV <sup>1</sup> <u style="color: gray;">&ensp; shows &ensp;</u>. First my sister and I watched a</div>
            <div><sup>2</sup> #  because my sister loves the funny drawings. When Mom came in, she</div>
            <div>picked up the remote <sup>3</sup> #  and changed the <sup>4</sup> #  . We watched a</div>
            <div><sup>5</sup> #  about history. It was very </div>
            <div>interesting. My dad was out, but he phoned from</div>
            <div>his <sup>6</sup> #  phone to say he was coming</div>
            <div>home soon. When Dad got home, he watched the</div>
            <div><sup>7</sup> #  , to see what was happening in the</div>
            <div>world. After dinner we all watched a DVD of our</div>
            <div>family vacation. Dad said this was the best TV to</div>
            <div>watch, because it doesn’t have any <sup>8</sup> #  !</div>

            <div style="position: absolute;top: 144px;left: 632px;">
              <img src='img/FriendsPlus/Page74/E2/1.jpg' />
            </div>
          </div>
        `,
        answer: [
          "cartoon",
          "control",
          "channel",
          "documentary",
          "cell",
          "news",
          "advertisements",
        ],
      },
    ],
  },
}

export default json;