import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P37-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    inputSize: 180,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '1', title: 'Read and write. Use the words from the word box. ', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; justify-content: space-around; line-height: 48px;">
          <div>
            <div>To visit the Son Doong, you have to climb down a rope from the</div>
            <div>(1) <u style="color:gray;"> opening &ensp;</u> to get down to the (2) # river.</div>
            <div>After you cross the river, you’ll walk within a very big</div>
            <div>(3) # . You will get very (4) # !</div>
            <div>It is not an easy trip for most (5) # .</div>
          </div>
          <div><img src='img/FriendsPlus/Page37/E1/1.jpg' /></div>
        </div>
        `,
        answer: [
          "underground",
          "jungle",
          "muddy",
          "tourists",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P37-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page37/E2/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page37/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page37/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page37/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page37/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page37/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page37/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page37/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page37/E2/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page37/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/13.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page37/E2/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page37/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/16.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page37/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/18.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page37/E2/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page37/E2/20.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/21.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page37/E2/22.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/23.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page37/E2/24.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page37/E2/25.jpg' },
        { url: 'img/FriendsPlus/Page37/E2/26.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page37/E2/27.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P37-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page37/E3/Key/answerKey.png",
    inputSize: 150,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Read and write. Use the words from the word box. ', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 58px;">
          <div><b>1</b> Son Doong is the world’s biggest <u style="color:gray;"> cave </u>.</div>
          <div><b>2</b> Ho Khanh found the opening of the cave in #.</div>
          <div><b>3</b> In 2009, some # finally found the cave again.</div>
          <div><b>4</b> # can now visit the cave, but it’s not an easy vacation.</div>
        </div>
        `,
        answer: [
          "1991",
          "scientists",
          "Tourists",
        ],
      },
    ],
  },

}

export default json;