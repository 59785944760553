import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P82-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page82/E1/Key/answerKey.png',
    inputSize: 30,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    textAlign: 'center',
    // titleImage: "img/FriendsPlus/Page82/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Match.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page82/E1/1.jpg' /> </div>
          <div style=" position: absolute; top: 1px; left: 623px; ">#</div>
          <div style=" position: absolute; top: 48px; left: 79px; ">#</div>
          <div style=" position: absolute; top: 48px; left: 653px; ">#</div>
          <div style=" position: absolute; top: 94px; left: 101px; ">#</div>
          <div style=" position: absolute; top: 95px; left: 644px; ">#</div>
          <div style=" position: absolute; top: 141px; left: 102px; ">#</div>
          <div style=" position: absolute; top: 141px; left: 624px; ">#</div>
        </div>
        `,
        answer: [
          "c",
          "a",
          "g",
          "d",
          "b",
          "h",
          "f",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P82-E2',
    audio: 'img/FriendsPlus/Page82/Audio/audio-e2-p82.mp3',
    video: '',
    component: UI,
    // titleQuestion: [{ num: '2', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page82/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page82/E2/2.jpg', audioUrl: "img/FriendsPlus/Page82/Audio/tieude-e2-p82.mp3" },
        { url: 'img/FriendsPlus/Page82/E2/3.jpg' },
      ],
      [
        // Column1
        { url: 'img/FriendsPlus/Page82/E2/4.jpg' },
      ],
    ],
  },
  3: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P82-E3',
    audio: '',
    video: '',
    component: T6,
    checkDuplicated: true,
    exerciseKey: 'img/FriendsPlus/Page82/E3/Key/answerKey.png',
    inputSize: 160,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    // titleImage: "img/FriendsPlus/Page82/E3/1.jpg",
    titleQuestion: [{ num: '1', title: 'Write the words in the correct boxes.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page82/E3/1.jpg' /> </div>
          <div style=" position: absolute; top: 101px; left: 237px; ">#</div>
          <div style=" position: absolute; top: 138px; left: 24px; ">#</div>
          <div style=" position: absolute; top: 138px; left: 237px; ">#</div>
          <div style=" position: absolute; top: 102px; left: 493px; ">#</div>
          <div style=" position: absolute; top: 101px; left: 705px; ">#</div>
          <div style=" position: absolute; top: 137px; left: 493px; ">#</div>
          <div style=" position: absolute; top: 138px; left: 705px; ">#</div>
        </div>
        `,
        answer: [
          "nurse/hurt/curtain",
          "nurse/hurt/curtain",
          "nurse/hurt/curtain",

          "shirt/circle/girl/bird",
          "shirt/circle/girl/bird",
          "shirt/circle/girl/bird",
          "shirt/circle/girl/bird",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P82-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page82/E4/Key/answerKey.png',
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page82/E4/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 48px; ">
          <div><b>1</b> (birthday) #</div>
          <div><b>2</b> (curtains) #</div>
          <div><b>3</b> (shirt) #</div>
          <div><b>4</b> (nurse) #</div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;