import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {

  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P25-E3',
    audio: '',
    video: '',
    component: D1,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page25/E3/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page25/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page25/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page25/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page25/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page25/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page25/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page25/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/18.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/19.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page25/E3/20.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/21.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page25/E3/23.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/24.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/25.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page25/E3/26.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/27.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/28.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page25/E3/29.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/30.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/31.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page25/E3/32.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/33.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/34.jpg' },
      ],
      [
        // Column13
        { url: 'img/FriendsPlus/Page25/E3/35.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/36.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/37.jpg' },
      ],
      [
        // Column14
        { url: 'img/FriendsPlus/Page25/E3/38.jpg' },
        { url: 'img/FriendsPlus/Page25/E3/39.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page25/E3/40.jpg' },
      ],
      [
        // Column15
        { url: 'img/FriendsPlus/Page25/E3/41.jpg' },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P25-E4',
    audio: "",
    video: "",
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page6/E1/Key/answerKey.jpg",
    inputSize: 800,
    // titleImage: "img/FriendsPlus/Page6/E1/title.jpg",
    titleQuestion: [{ num: '4', title: 'Write about food. Use your answers from 3 to write a paragraph.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height: 58px;'>
            <div><u style='color: gray;'>&emsp; My favorite foods are &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</u></div>            
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div><img src='img/FriendsPlus/Page25/E4/1.jpg' /></div>
          </div>
        `,
        answer: [
          "", "", "", "", "", "", "", "", ""
        ],
      },
    ],
  },
}

export default json;