import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P14-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page14/E1/Key/answerKey.png',
    inputSize: 200,
    titleImage: 'img/FriendsPlus/Page14/E1/title.jpg',
    // titleQuestion: [{ num: '2', title: 'Write about your bed.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div><b>1</b> You use this to clean your teeth. <u style='color: gray;'>a toothbrush</u></div>
          <div><b>2</b> This is usually white and in a tube. You use it to clean your teeth. #</div>
          <div><b>3</b> You put this on your skin to stop it going red in the sun. #</div>
          <div><b>4</b> You use this to dry your body. #</div>
          <div><b>5</b> You use this to wash your hands or your body. #</div>
          <div><b>6</b> You use this to wash your hair. #</div>
          <div><b>7</b> You put your things in this when you go on holiday. #</div>
          `,
        answer: [
          // "a toothbrush",
          "a toothpaste",
          "a sunscreen",
          "a towel",
          "a soap",
          "a shampoo",
          "a suitcase",
        ],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P14-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page14/E2/Key/answerKey.png',
    inputSize: 150,
    // titleImage: 'img/FriendsPlus/Page14/E2/title.jpg',
    titleQuestion: [{ num: '2', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div style=" display: flex; flex-direction: row; ">
              <div>
                <img src='img/FriendsPlus/Page14/E2/1.jpg' />
              </div>
            <div style=" line-height: 58px; ">
              <div><b>1</b> The <u style='color: gray;'>sunscreen</u>  is in the suitcase.</div>
              <div><b>2</b> The suitcase is between the towel and the #.</div>
              <div><b>3</b> The # is next to the shampoo.</div>
              <div><b>4</b> The # is under the soap.</div>
              <div><b>5</b> The # is behind the toothpaste.</div>
              <div><b>6</b> The # is on top of the towel.</div>
              <div><b>7</b> The # is in front of the toothbrush.</div>
            </div>
          </div>        
          `,
        answer: [
          // "sunscreen",
          "shampoo",
          "suitcase",
          "towel",
          "toothbrush",
          "soap",
          "toothpaste",
        ],
      },
    ]
  },

}

export default json;