import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P8-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page8/E1/Key/answerKey.png',
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page8/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page8/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/3.jpg', input: true, answer: "finish" },
        { url: 'img/FriendsPlus/Page8/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page8/E1/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page8/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/7.jpg', input: true, answer: "love" },
        { url: 'img/FriendsPlus/Page8/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/9.jpg', input: true, answer: "hate" },
        { url: 'img/FriendsPlus/Page8/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page8/E1/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page8/E1/12.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/13.jpg', input: true, answer: "want" },
        { url: 'img/FriendsPlus/Page8/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/15.jpg', input: true, answer: "use" },
        { url: 'img/FriendsPlus/Page8/E1/16.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/17.jpg', input: true, answer: "live" },
        { url: 'img/FriendsPlus/Page8/E1/18.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page8/E1/19.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P8-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page8/E2/Key/answerKey.png',
    inputSize: 100,
    titleImage: 'img/FriendsPlus/Page8/E2/title.jpg',
    // titleQuestion: [{ num: '2', title: 'Read the story. Choose a word from 1. Write the correct word next to numbers 1–5', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div>My name is Mai. I (1) <u style='font-weight:400;font-family:OneStrokeScriptStd-Bold;color:gray'> live</u> in Ho Chi Minh City. I (2)# school at</div>
          <div>seven o’clock in the morning, and (3)# school at 11 o’clock.</div> 
          <div>I (4)# my school because my teacher is good and I like my friends. After</div>         
          <div>school, I do my homework. Sometimes, I (5)# a computer</div>         
          <div>in a café.</div>
          `,
        answer: [
          // "live",
          "start",
          "finish",
          "love",
          "use",
        ],
      },
    ]
  },
}

export default json;