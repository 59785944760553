import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {

  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P11-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page11/E1/Key/answerKey.png',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/3.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page11/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/5.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page11/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/7.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page11/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page11/E1/9.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page11/E1/10.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page11/E1/11.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P11-E2',
    audio: 'img/FriendsPlus/Page11/Audio/audio-e2-p11.mp3',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page11/E2/Key/answerKey.png',
    inputSize: 100,
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [{ num: '2', title: 'Listen and write.<headphone name="01" src="img/FriendsPlus/Page11/Audio/tieude-e2-p11.mp3"></headphone>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <img src='img/FriendsPlus/Page11/E2/1.jpg' />
            </div>
            <div>
              <div>It’s Tuesday night,</div>
              <div>And I’m not at <sup>1</sup> <u style=" color: gray; ">school</u>.</div>
              <div>I have <sup>2</sup> # and <sup>3</sup># s,</div>
              <div>And paint that’s <sup>4</sup>#.</div>
              <div>I make a toy <sup>5</sup>#,</div>
              <div>I look up at the <sup>6</sup>#.</div>
              <div>It’s a hot <sup>7</sup># night,</div>
              <div>And I play a <sup>8</sup>#.</div>
            </div>
          </div>
          `,
        answer: [
          "glue",
          "tube",
          "blue",
          "flute",
          "moon",
          "June",
          "tune",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P11-E3',
    audio: 'img/FriendsPlus/Page11/Audio/audio-e3-p11.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page11/E3/Key/answerKey.png',
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page11/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page11/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page11/Audio/tieude-e3-p11.mp3' },
        { url: 'img/FriendsPlus/Page11/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page11/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page11/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page11/E3/6.jpg', input: true, answer: "flute" },
        { url: 'img/FriendsPlus/Page11/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page11/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page11/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page11/E3/10.jpg', input: true, answer: "boot" },
        { url: 'img/FriendsPlus/Page11/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page11/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page11/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page11/E3/14.jpg', input: true, answer: "tubes" },
        { url: 'img/FriendsPlus/Page11/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page11/E3/16.jpg', input: true, answer: "glue" },
        { url: 'img/FriendsPlus/Page11/E3/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page11/E3/18.jpg' },
      ],
    ],
  },
}

export default json;