import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P26-E1',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    inputSize: 200,
    // titleImage: "img/FriendsPlus/Page6/E1/title.jpg",
    titleQuestion: [{ num: '1', title: 'Look and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 58px;'>
          <div>I’m going on vacation next week. I’m going to put these things in my suitcase.</div>
          <div style=" display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; width: 800px; ">
            <div style=" width: 200px; "> <u style="color: gray; ">&emsp; &emsp; towel &emsp; &emsp;</u></div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
            <div>#</div>
          </div>
        <div><img src='img/FriendsPlus/Page26/E1/1.jpg' /></div>
      </div>
        `,
        answer: [
          "sunscreen/toothpaste/toothbrush/shampoo/soap",
          "sunscreen/toothpaste/toothbrush/shampoo/soap",
          "sunscreen/toothpaste/toothbrush/shampoo/soap",
          "sunscreen/toothpaste/toothbrush/shampoo/soap",
          "sunscreen/toothpaste/toothbrush/shampoo/soap",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P26-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page26/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Circle the odd-one-out.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 70, },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: 3, border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "light this afternoon tomorrow",//0
          "difficult easy soft suitcase",//1
          "finish love soon start",//2
          "heavy sunscreen toothbrush",//3
          "toast rice hood peanuts",//4
        ],
        answers: ['0-0', '1-6', '2-4', '3-0', '4-4'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row;font-size: 22px;justify-content: space-between;width: 850px; line-height: 58px; word-spacing: 10px;">
        <div>
          <div><b>1</b> shampoo <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">want</span> toothpaste </div>
          <div><b>3</b> <input id='1' type='Circle' /></div>
          <div><b>5</b> <input id='3' type='Circle' /></div>
        </div>
        <div>
          <div><b>2</b> <input id='0' type='Circle' /></div>
          <div><b>4</b> <input id='2' type='Circle' /></div>
          <div><b>6</b> <input id='4' type='Circle' /></div>
        </div>
      </div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P26-E3',
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/answerKey.png",
    inputSize: 250,
    // titleImage: "img/FriendsPlus/Page6/E1/title.jpg",
    titleQuestion: [{ num: '3', title: 'Write the correct form of the verb.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='line-height: 58px;'>
            <div><b>1</b> I <u style="color: gray; ">’m going to cook</u> (cook) lunch tomorrow.</div>
            <div><b>2</b> She # (play) volleyball last Saturday.</div>
            <div><b>3</b> We # (listen) to my new CD yesterday.</div>
            <div><b>4</b> He # (play) soccer next week.</div>
            <div><b>5</b> They # (watch) TV tonight.</div>
            <div><b>6</b> I # (cook) dinner last weekend.</div>
          </div>
        `,
        answer: [
          "played",
          "listened",
          "'s going to play",
          "'re going to watch",
          "cooked",
        ],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P26-E4',
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page26/E4/Key/answerKey.png",
    inputSize: 400,
    titleImage: "img/FriendsPlus/Page26/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
          <div> <img src='img/FriendsPlus/Page26/E4/2.jpg' /> </div>
          <div style=" margin: auto 0px ; ">(always) <u style=" color: gray; ">He always helps his mom.</u></div>
        </div>
        <div style=" display: flex; flex-direction: row; ">
          <div> <img src='img/FriendsPlus/Page26/E4/3.jpg' /> </div>
          <div style=" margin: auto 0px ; ">(today) #</div>
        </div>
        <div style=" display: flex; flex-direction: row; ">
          <div> <img src='img/FriendsPlus/Page26/E4/4.jpg' /> </div>
          <div style=" margin: auto 0px ; ">(sometimes) #</div>
        </div>
        <div style=" display: flex; flex-direction: row; ">
          <div> <img src='img/FriendsPlus/Page26/E4/5.jpg' /> </div>
          <div style=" margin: auto 0px ; ">(right now) #</div>
        </div>     
        `,
        answer: [
          "Today she's eating noodles.",
          "They sometimes play volleyball.",
          "She's playing piano right now.",
        ],
      },
    ],
  },
}

export default json;