import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";



const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P9-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page9/E1/Key/answerKey.png',
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page9/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page9/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/3.jpg', input: true, answer: "used" },
        { url: 'img/FriendsPlus/Page9/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page9/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/6.jpg', input: true, answer: "cooked" },
        { url: 'img/FriendsPlus/Page9/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page9/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/9.jpg', input: true, answer: "started" },
        { url: 'img/FriendsPlus/Page9/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page9/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/12.jpg', input: true, answer: "didn't" },
        { url: 'img/FriendsPlus/Page9/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page9/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page9/E1/15.jpg', input: true, answer: "finished" },
        { url: 'img/FriendsPlus/Page9/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page9/E1/17.jpg' },
      ],

    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P9-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page9/E2/Key/answerKey.png',
    inputSize: 200,
    titleImage: 'img/FriendsPlus/Page9/E2/title.jpg',
    // titleQuestion: [{ num: '2', title: 'Read the story. Choose a word from 1. Write the correct word next to numbers 1–5', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style='line-height: 58px;'>
            <div><b>1</b> Did he watch TV on Sunday ? <u style='color: gray;'><i>No, he didn't.</i></u></div>
            <div><b>2</b> Did he start reading a book on Saturday ? #</div>
            <div><b>3</b> Did he finish reading the book on Sunday ? #</div>
            <div><b>4</b> Did he cook in the kitchen on Sunday ? #</div>
            <div><b>5</b> Did he help his dad on Saturday ? #</div>
            <div><b>6</b> Did he play computer games on Sunday ? #</div>
          </div>
          `,
        answer: [
          // "No, he didn't.",
          "Yes, he did.",
          "Yes, he did.",
          "No, he didn't.",
          "No, he didn't.",
          "Yes, he did.",
        ],
      },
    ]
  },

}

export default json;