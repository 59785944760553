import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P61-E1',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page61/E1/Key/answerKey.png',
    inputSize: 400,
    titleImage: "img/FriendsPlus/Page61/E1/1.jpg",
    // titleQuestion: [{ num: '1', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; line-height: 58px; justify-content: space-between;width: 900px;">
          <div>
            <div><b>1</b> Will Ly take her lunch box tomorrow?</div>
            <div><b>2</b> Will she take her school bag?</div>
            <div><b>3</b> Will she take her raincoat?</div>
            <div><b>4</b> Will she wear her school shoes?</div>
            <div><b>5</b> Will she wear her school uniform?</div>
            <div><b>6</b> Will she take an umbrella?</div>
          </div>
          <div>
            <div><u style="color:gray;">Yes, she will.&ensp;</u></div>
            <div> # </div>
            <div> # </div>
            <div> # </div>
            <div> # </div>
            <div> # </div>
          </div>
        </div>
        `,
        answer: [
          "No, she won't.",
          "Yes, she will.",
          "No, she won't.",
          "No, she won't.",
          "Yes, she will.",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 9',
    id: 'WB5-U9-P61-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page61/E2/Key/answerKey.png',
    inputSize: 100,
    // titleImage: "img/FriendsPlus/Page61/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write will or won’t.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" display: flex; flex-direction: row; ">
          <div>
            <div>Next Thursday, Phong <sup>1</sup> <u style="color: gray;">&emsp; won’t &emsp;</u> go to school as usual.</div>
            <div>Instead he <sup>2</sup> #  play in an important concert.</div>
            <div>Phong <sup>3</sup> #  play the violin in the concert.</div>
            <div>He <sup>4</sup> #  stand on a stage and play music.</div>
            <div>He <sup>5</sup> #  wear his school uniform. Instead</div>
            <div>he <sup>6</sup> #  wear black pants and a white shirt.</div>
            <div>Phong hopes that one day he <sup>7</sup> #  play the</div>
            <div>violin in a professional orchestra.</div>
          </div>
          <div><img src='img/FriendsPlus/Page61/E2/1.jpg' /></div>
        </div>
        `,
        answer: [
          "will",
          "will",
          "will",
          "won't",
          "will",
          "will",
        ],
      },
    ],
  },
}

export default json;