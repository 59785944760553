import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P72-E1',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page72/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page72/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page72/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/3.jpg', input: true, answer: "472 Lincoln Street" },
        { url: 'img/FriendsPlus/Page72/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page72/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/6.jpg', input: true, answer: "Boston" },
        { url: 'img/FriendsPlus/Page72/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page72/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page72/E1/9.jpg', input: true, answer: "07842" },
        { url: 'img/FriendsPlus/Page72/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page72/E1/11.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P72-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page72/E2/Key/answerKey.png',
    stylesTextInput: { backgroundColor: 'transparent', border: 'none' },
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page72/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Read the letter and write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page72/E2/1.jpg' /> </div>
          <div style=" position: absolute; top: 280px; left: 143px; transform: rotate(3deg);">#</div>
          <div style=" position: absolute; top: 323px; left: 296px; transform: rotate(3deg);">#</div>
          <div style=" position: absolute; top: 354px; left: 263px; transform: rotate(3deg);">#</div>
          <div style=" position: absolute; top: 430px; left: 265px; transform: rotate(3deg);">#</div>
          <div style=" position: absolute; top: 471px; left: 426px; transform: rotate(3deg);">#</div>
          <div style=" position: absolute; top: 491px; left: 142px; transform: rotate(3deg);">#</div> 
        </div>
        `,
        answer: [
          "plane",
          "hotel",
          "pool",
          "vacation",
          "head",
          "hospital",
        ],
      },
    ],
  },
}

export default json;