import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P20-E1',
    audio: 'img/FriendsPlus/Page20/Audio/audio-e1-p20.mp3',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page20/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Listen and draw lines.<headphone name="05" src="img/FriendsPlus/Page20/Audio/tieude-e1-p20.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '35px', left: '232px', width: 100, height: 50, } },
          { boxMatchStyle: { position: 'absolute', top: '35px', left: '438px', width: 100, height: 50, }, },
          { boxMatchStyle: { position: 'absolute', top: '35px', left: '640px', width: 100, height: 50, }, },
          { boxMatchStyle: { position: 'absolute', top: '502px', left: '540px', width: 100, height: 50, }, },

          { boxMatchStyle: { position: 'absolute', top: '174px', left: '775px', width: 90, height: 90, border: 'dashed' }, },
          { boxMatchStyle: { position: 'absolute', top: '167px', left: '640px', width: 90, height: 120, border: 'dashed' }, },
          { boxMatchStyle: { position: 'absolute', top: '300px', left: '500px', width: 90, height: 90, border: 'dashed' }, },
          { boxMatchStyle: { position: 'absolute', top: '300px', left: '230px', width: 90, height: 140, border: 'dashed' }, },
        ],
        answers: ['0-5', '1-4', '2-6', '3-7'],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page20/E1/background.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />

          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
      `,
    },
  },

  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P20-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page20/E2/Key/answerKey.png',
    inputSize: 150,
    titleImage: 'img/FriendsPlus/Page20/E2/1.jpg',
    // titleQuestion: [{ num: '2', title: 'Read the story. Choose a word from 1. Write the correct word next to numbers 1–5', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div>My dad has a restaurant and he wears a green <sup>1</sup> <u style='color: gray;'>uniform</u>. He</div>
              <div>is the <sup>2</sup># . Lots of the <sup>3</sup># are children, and</div>
              <div>they really like the food. They look at the <sup>4</sup># and</div>
              <div>decide what to order. They can have a <sup>5</sup># of soup</div>
              <div>or a pizza. They usually order a <sup>6</sup># of juice or a</div>
              <div><sup>7</sup># of water. After dinner, the adults usually have</div>
              <div>a <sup>8</sup># of coffee.</div>
            </div>
            <div> <img src='img/FriendsPlus/Page20/E2/2.jpg' /> </div>
          </div>
          `,
        answer: [
          // "uniform",
          "server",
          "customers",
          "menu",
          "bowl",
          "glass",
          "bottle",
          "cup",
        ],
      },
    ]
  },
}

export default json;