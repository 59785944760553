import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P36-E1',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page36/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page36/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page36/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/3.jpg', input: true, answer: "d" },
        { url: 'img/FriendsPlus/Page36/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page36/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/6.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page36/E1/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page36/E1/8.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/9.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page36/E1/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page36/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/12.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page36/E1/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page36/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page36/E1/15.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page36/E1/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page36/E1/17.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P36-E2',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page36/E2/Key/answerKey.png",
    inputSize: 120,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '2', title: 'Complete the questions and answers. Use the past simple of the verbs.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div><img src='img/FriendsPlus/Page36/E2/1.jpg' /></div>
          <div style=" display: flex; flex-direction: row; justify-content: space-around; ">
            <div>
              <div><b>1</b> <u style="color:gray;"> Who &ensp;</u> did you see yesterday? </div>
              <div><b>2</b> # did you see them?</div>
              <div><b>3</b> # park was it?</div>
              <div><b>4</b> # did you do?</div>
              <div><b>5</b> # you have a good time?</div>
              <div><b>6</b> # I go with you next time?</div>
            </div>
            <div>
              <div>I <u style="color:gray;"> saw &ensp;</u> Robbie and Simon.</div>
              <div>I saw them at the #.</div>
              <div>It # Sunnyside Park.</div>
              <div>We # basketball.</div>
              <div>Yes, # did.</div>
              <div>Yes, # can.</div>            
            </div>
          </div>
        </div>
        `,
        answer: [
          "Where",
          "Which",
          "What",
          "Did",
          "Can",

          "park",
          "was",
          "played",
          "I",
          "you",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P36-E3',
    audio: 'img/FriendsPlus/Page36/Audio/audio-e3-p36.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page36/E3/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page36/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page36/Audio/tieude-e3-p36.mp3' },
        { url: 'img/FriendsPlus/Page36/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page36/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page36/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page36/E3/7.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/9.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E3/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page36/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/12.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page36/E3/13.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/15.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E3/16.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page36/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page36/E3/18.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page36/E3/19.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page36/E3/20.jpg' },
      ],
    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P36-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: "",
    inputSize: 700,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence using each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div><b>1</b> (best) # </div>
          <div><b>2</b> (wet) # </div>
          <div><b>3</b> (big) # </div>
          <div><b>4</b> (path) # </div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
}

export default json;