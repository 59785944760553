import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P83-E1',
    audio: '',
    video: '',
    component: UI,
    // titleQuestion: [{ num: '1', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E1/1.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P83-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page83/E2/Key/answerKey.png',
    inputSize: 150,
    // titleImage: "img/FriendsPlus/Page83/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Look and read. Choose the correct answer and write it on the line.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative;line-height: 48px;  ">
          <div><b>1</b> You do this to choose things on a computer.</div>
          <div><u style="color:gray;">&ensp; click on &ensp;</u></div>
          <div><b>2</b> You use this to control the computer.</div>
          <div>#</div>
          <div><b>3</b> You write this to say what your email is about.</div>
          <div>#</div>
          <div><b>4</b> You write this to say who you want the email to go to.</div>
          <div>#</div>
          <div><b>5</b> You click on this after you finish writing the email.</div>
          <div>#</div>
          <div><b>6</b> This is the biggest part of your email.</div>
          <div>#</div>
          <div style=" position: absolute; top: 84px; left: 622px; "><img src='img/FriendsPlus/Page83/E2/1.jpg' /></div>
        </div>
        `,
        answer: [
          "a mouse",
          "a subject",
          "an address",
          "send",
          "a message",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P83-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page83/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page83/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page83/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page83/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page83/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/6.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page83/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page83/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/9.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page83/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page83/E3/11.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page83/E3/12.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page83/E3/14.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/15.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page83/E3/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page83/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page83/E3/19.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/20.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page83/E3/21.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page83/E3/22.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/23.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page83/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page83/E3/25.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page83/E3/26.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page83/E3/27.jpg' },
      ],

    ],
  },

}

export default json;