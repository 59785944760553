import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';


const json = {
  1: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P23-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page23/E1/Key/answerKey.png',
    inputSize: 120,
    // titleImage: 'img/FriendsPlus/Page22/E2/title.jpg',
    titleQuestion: [{ num: '1', title: 'Read. Choose the correct word and write it on the line.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style=" display: flex; flex-direction: row; width: 900px ">
          <div>
            <div><b>1</b> These are small, brown, and hard. <u style=" color: gray; ">&ensp;peanuts &ensp;</u></div>
            <div><b>2</b> This is delicious hot water. You often cook it with noodles in Viet Nam.#</div>
            <div><b>3</b> This is made from water and rice. You cook it with chicken in</div>
            <div>Indonesia.#</div>
            <div><b>4</b> When cooked, these are small and round and soft.#</div>
            <div><b>5</b> This is bread. You cook it at a high temperature so that it becomes</div>
            <div>brown.#</div>
          </div>
          <div>
            <img src='img/FriendsPlus/Page23/E1/1.jpg' />
          </div>
        </div>                     
          `,
        answer: [
          "soup",
          "porridge",
          "beans",
          "toast",
        ],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P23-E2',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page23/E2/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page23/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page23/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/3.jpg', input: 1 },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page23/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/5.jpg', input: 1, isCorrect: true },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page23/E2/6.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/7.jpg', input: 1 },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page23/E2/8.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page23/E2/9.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/10.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page23/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/12.jpg', input: 3, isCorrect: true },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page23/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/14.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page23/E2/15.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/16.jpg', input: 3 },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page23/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/18.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page23/E2/19.jpg' },
        { url: 'img/FriendsPlus/Page23/E2/20.jpg', input: 3 },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 3',
    id: 'WB5-U3-P23-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page23/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Read the text in the Student Book. Circle the wrong word.<br/> Write the correct word.', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 120, },
        answers: ['breakfast', 'porridge', 'beans'],
        initialValue: []
      },
      Circle: {
        initialWordStyle: { border: 'solid 2px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 3, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          "Sayaka eats lunch at home.",//0
          "Harta eats a soup made from rice and chicken.",//1
          "Xoi are balls of sticky rice with eggs.",//2
        ],
        answers: ['0-4', '1-6', '2-14'],
        initialValue: [],
      },
      Layout: `
      <div style="display: flex;flex-direction: row; line-height: 58px;">
        <div>
          <div><b>1</b> Noodle soup is popular in <span style="border: 2px solid gray; border-radius: 50%; padding: 3px;">Mexico</span>. <u style="color: gray"> Viet Nam </u> </div>
          <div><b>2</b> <input id='0' type='Circle' /><input id='0' /></div>
          <div><b>3</b> <input id='1' type='Circle' /><input id='1' /></div>
          <div><b>4</b> <input id='2' type='Circle' /><input id='2' /></div>
        </div>
        <div>
        <img src='img/FriendsPlus/Page23/E3/1.jpg' />
      </div>
      `,
    },
  },

}

export default json;