import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  1: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P80-E1',
    audio: 'img/FriendsPlus/Page80/Audio/audio-e1-p80.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page80/E1/Key/answerKey.png',
    inputSize: 30,
    stylesTextInput: { backgroundColor: 'transparent', border: 'none', padding: 0 },
    textAlign: 'center',
    // titleImage: "img/FriendsPlus/Page80/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Listen. Write the correct letter in the box.<headphone name="24" src="img/FriendsPlus/Page80/Audio/tieude-e1-p80.mp3"></headphone>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page80/E1/1.jpg' /> </div>
          <div style=" position: absolute; top: 100px; left: 136px; ">#</div>
          <div style=" position: absolute; top: 100px; left: 330px; ">#</div>
          <div style=" position: absolute; top: 100px; left: 519px; ">#</div>
          <div style=" position: absolute; top: 245px; left: 234px; ">#</div>
          <div style=" position: absolute; top: 245px; left: 425px; ">#</div>
          <div style=" position: absolute; top: 245px; left: 616px; ">#</div>
          <div style=" position: absolute; top: 245px; left: 808px; ">#</div>
        </div>
        `,
        answer: [
          "d",
          "f",
          "c",
          "b",
          "g",
          "h",
          "e",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P80-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page80/E2/Key/answerKey.png',
    inputSize: 240,
    // titleImage: "img/FriendsPlus/Page80/E2/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write. Use the clues from the pictures from <b style="color:gray;">1</b>.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 48px; ">
          <div>Three children in my class have birthdays this week and I am going to their parties.</div>
          <div>Giang’s party is today. He loves singing, so we are going to <sup>1</sup><u style="color:gray;"> sing karaoke </u> at his house.</div>
          <div>It is also a <sup>2</sup> # , and I’m going to wear cat clothes and ears. I made him a</div>
          <div><sup>3</sup> # and wrote “Happy birthday” inside it.</div>
          <div>Mi’s party is tomorrow. She likes playing games, so she’s going to <sup>4</sup> # .</div>
          <div>After the party, we will eat the birthday cake and Mi will blow out the <sup>5</sup> # .</div>
          <div>On Saturday, it is my birthday! My party is at night, so we are going to <sup>6</sup> # in</div>
          <div>the garden against the dark sky. I love cars, so my present is going to be a <sup>7</sup> # .</div>
          <div>I will have to put <sup>8</sup> # in it and watch it go.</div>
        </div>
        `,
        answer: [
          "costume party",
          "birthday card",
          "go bowling",
          "candles",
          "have fireworks",
          "remote-control car",
          "batteries",
        ],
      },
    ],
  },
  3: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P80-E3',
    audio: 'img/FriendsPlus/Page80/Audio/audio-e3-p80.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page80/E3/Key/answerKey.png',
    inputSize: 420,
    // titleImage: "img/FriendsPlus/Page80/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Listen and write.<headphone name="25" src="img/FriendsPlus/Page80/Audio/tieude-e3-p80.mp3"></headphone>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 48px; ">
          <div><b>1</b> Whose birthday?<u style="color:gray;"> Lam’s </u></div>
          <div><b>2</b> How old is he?#</div>
          <div><b>3</b> Activities?#</div>
          <div><b>4</b> Time finish? After#</div>
          <div><b>5</b> Bring?# and #</div>
        </div>
        `,
        answer: [
          "Ten",
          "Races with remote–control cars.",
          "Lam blows his candles out",
          "A car",
          "batteries",
        ],
      },
    ],
  },
}

export default json;