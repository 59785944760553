import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  4: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P53-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page53/E4/Key/answerKey.png',
    inputSize: 600,
    // titleImage: "img/FriendsPlus/Page53/E4/1.jpg",
    titleQuestion: [{ num: '4', title: 'You are going to an event. Choose the type of event from the words in the box.<br/> Then answer the questions.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height: 48px;'>
          <div><img src='img/FriendsPlus/Page53/E4/1.jpg' /></div>
          <div>What is the event? #</div>
          <div>When is the event? #</div>
          <div>What will happen? #</div>
          <div>Where is the event? #</div>
          <div>When does it start? #</div>
          <div>When does it finish? #</div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
  5: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P53-E5',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: '',
    textareaStyle: { width: 800 },
    // titleImage: "img/FriendsPlus/Page53/E5/1.jpg",
    titleQuestion: [{ num: '5', title: 'Write a flyer for the event and draw a map. Use your answers from 4.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div> <textarea id="0" rows="9"></textarea> </div> 
        <div>Now check the imperative verbs.</div>
        `,
        answer: [
          "",
        ],
      },
    ],
  },
}

export default json;