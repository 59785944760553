import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P15-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page15/E1/Key/answerKey.png',
    inputSize: 450,
    // titleImage: 'img/FriendsPlus/Page15/E1/title.jpg',
    titleQuestion: [{ num: '1', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div style=" display: flex; flex-direction: row; ">
            <div style=" line-height: 58px; ">
              <div><b>1</b> Is he going to visit his cousins next week?</div>
              <div> Yes, <u style='color: gray;'>he is</u>.</div>
              <div><b>2</b> Are they going to swim in the ocean?</div>
              <div> No, #.</div>
              <div><b>3</b> Is it going to rain tomorrow?</div>
              <div> No, #.</div>
              <div><b>4</b> Are you going to take your shampoo on vacation?</div>
              <div> Yes, #.</div>
              <div><b>5</b> Is she going to buy sunscreen later?</div>
              <div> Yes, #.</div>
            </div>
          </div>        
          `,
        answer: [
          // "",
          "they aren't",
          "it isn't",
          "I am / we are",
          "she is",
        ],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'WB5-U2-P15-E2',
    audio: 'img/FriendsPlus/Page15/Audio/audio-e2-p15.mp3',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page15/E2/Key/answerKey.png',
    component: D1,
    textAlign: 'center',
    maxLength: 1,
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page15/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page15/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page15/Audio/tieude-e2-p15.mp3' },
        { url: 'img/FriendsPlus/Page15/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page15/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/6.jpg', input: true, answer: "e" },
        { url: 'img/FriendsPlus/Page15/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/8.jpg', input: true, answer: "c" },
        { url: 'img/FriendsPlus/Page15/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page15/E2/10.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/11.jpg', input: true, answer: "f" },
        { url: 'img/FriendsPlus/Page15/E2/12.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/13.jpg', input: true, answer: "a" },
        { url: 'img/FriendsPlus/Page15/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page15/E2/15.jpg', input: true, answer: "b" },
        { url: 'img/FriendsPlus/Page15/E2/16.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page15/E2/17.jpg' },
      ],

    ]
  },
}

export default json;