import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';


const json = {
  1: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P52-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
    titleQuestion: [{ num: '1', title: 'Underline the imperative verbs. Put the pictures in the correct order.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: { border: 'none' },
        inputStyle: { width: 35, textAlign: 'center', maxLength: 1 },
        answers: ['2', '4', '5', '3'],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { borderBottom: 'none', },
        selectWordStyle: { borderBottom: 'solid 2px', borderColor: '#2CBAEA' },
        limitSelect: 2,
        listWords: [
          "Stand in front of a white wall or screen.",  //0
          "Don't_put your body in front of the light.", //1
          "Put your hands in front of the light and make a shape with them.", //2
          "Move your hands to make the shape move.",  //3
        ],
        answers: ['0-0', '1-0', '2-0', '2-18', '3-0'],
        initialValue: [],
      },
      Layout: `
      <div style=" line-height: 58px; ">
        <div><b>1</b> <span style="border-bottom: 2px solid rgb(44, 186, 234);" > Turn on </span> a bright light.</div>
        <div><b>2</b> <input id='0' type='Circle' /> </div>
        <div><b>3</b> <input id='1' type='Circle' /> </div>
        <div><b>4</b> <input id='2' type='Circle' /> </div>
        <div><b>5</b> <input id='3' type='Circle' /> </div>
      </div>
      <div style=" position: relative; ">
        <div> <img src='img/FriendsPlus/Page52/E1/1.jpg' /> </div>
          <div style=" position: absolute; top: 108px; left: 149px; "><input id='0' /></div>
          <div style=" position: absolute; top: 108px; left: 517px; "><input id='1' /></div>
          <div style=" position: absolute; top: 108px; left: 703px; "><input id='2' /></div>
          <div style=" position: absolute; top: 108px; left: 880px; "><input id='3' /></div>
      </div>
      `,
    },
  },
  2: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P52-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page52/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Read the flyer and underline the imperative sentences.', color: "#5B5A5D" }],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { borderBottom: 'none', },
        selectWordStyle: { borderBottom: 'solid 2px', borderColor: '#2CBAEA' },
        // limitSelect: 1,
        listWords: [
          "June_5th", //0
          "WATER_PUPPET_SHOW",  //1
          "Come_and_see_our_great_water_puppet_show. We_have_real_Vietnamese_water",  //2
          "puppets_acting_out_new_and_old_stories. The_show_is_accompanied_by_live",  //3
          "singing_and_music. Don’t_miss_this_wonderful_opportunity!",  //4
          "Book_your_tickets_on_212_783_4322.", //5
          "The_tickets_cost_$5_for_children", //6
          "under_16,_and_$8_for_adults.", //7
          "The_show_starts_at_3pm_and", //8
          "finishes_at_5pm.", //9
          "The_Old_Town_Theater_is_near_the", //10
          "station. Go_to_the_station. Go_straight.", //11
          "Turn_left_at_the_gas_station. The_theater",  //12
          "is_on_the_left,_opposite_the_swimming_pool.",  //13
        ],
        answers: ['2-0', '4-2', '5-0'],
        initialValue: [],
      },
      Layout: `
      
      <div style=" position: relative; ">
        <div> <img src='img/FriendsPlus/Page52/E2/1.jpg' /> </div>
        <div style="position: absolute; top: 100px; left: 20px; font-size: 20px;">
          <div> <input id='0' type='Circle' /> </div>
          <div> <input id='1' type='Circle' /> </div>
          <div> <input id='2' type='Circle' /> </div>
          <div> <input id='3' type='Circle' /> </div>
          <div> <input id='4' type='Circle' /> </div>
          <div> <input id='5' type='Circle' /> </div>
          <div> <input id='6' type='Circle' /> </div>
          <div> <input id='7' type='Circle' /> </div>
          <div> <input id='8' type='Circle' /> </div>
          <div> <input id='9' type='Circle' /> </div>
          <div> <input id='10' type='Circle' /> </div>
          <div> <input id='11' type='Circle' /> </div>
          <div> <input id='12' type='Circle' /> </div>
          <div> <input id='13' type='Circle' /> </div>
        </div>
      </div>
      `,
    },
  },
  3: { // Exercise num
    unit: 'Unit 7',
    id: 'WB5-U7-P52-E3',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page52/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Now read the flyer again and draw the route from the station to the theater.', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          { boxMatchStyle: { position: 'absolute', top: '478px', left: '652px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //0
          { boxMatchStyle: { position: 'absolute', top: '396px', left: '653px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //1
          { boxMatchStyle: { position: 'absolute', top: '396px', left: '474px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //2
          { boxMatchStyle: { position: 'absolute', top: '424px', left: '474px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //3
          { boxMatchStyle: { position: 'absolute', top: '368px', left: '473px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //4
          { boxMatchStyle: { position: 'absolute', top: '353px', left: '610px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //5
          { boxMatchStyle: { position: 'absolute', top: '354px', left: '653px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //6
          { boxMatchStyle: { position: 'absolute', top: '477px', left: '689px', width: 20, height: 20, border: 'dotted', borderRadius: '50%' }, }, //7
        ],
        answers: ['0-1', '1-2', '2-3'],
        initialValue: [],
      },
      Layout: `
          <img src='img/FriendsPlus/Page52/E3/1.jpg' />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
      `,
    },
  },
}

export default json;