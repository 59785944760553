import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P70-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page70/E1/Key/answerKey.png',
    inputSize: 100,
    // titleImage: "img/FriendsPlus/Page70/E1/1.jpg",
    titleQuestion: [{ num: '1', title: 'Write some or any.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" line-height: 48px; position: relative; ">
            <div><b>Boy</b> Do you have <sup>1</sup> <u style="color: gray;">&ensp; any &ensp;</u> bread?</div>
            <div><b>Man</b> It’s very late. We don't have <sup>2</sup> # bread now. But we have <sup>3</sup> # cakes.</div>
            <div style=" margin-bottom: 20px; "><b>Mom</b> No, thank you. We don’t want <sup>4</sup> # cakes.</div>

            <div><b>Boy</b> Do you have <sup>5</sup> # meat?</div>
            <div><b>Man</b> We don’t have <sup>6</sup> # meat. It’s all gone.</div>
            <div style=" padding-left: 50px; "> But how about <sup>7</sup> # fish?</div>
            <div><b>Mom</b> No. We don’t want <sup>8</sup> # fish.</div>
            <div style=" padding-left: 50px; "> Do you have <sup>9</sup> # cheese?</div>
            <div><b>Man</b> Yes, we have <sup>10</sup> # cheese.</div>
            <div style=" margin-bottom: 20px; "><b>Mom</b> Great! We’ll have <sup>11</sup> #, please.</div>

            <div><b>Boy</b> Do you have <sup>12</sup> # comics?</div>
            <div><b>Man</b> Yes, we have <sup>13</sup> # comics. Which one would you like?</div>
            <div style=" position: absolute; top: 110px; left: 710px;">
              <img src='img/FriendsPlus/Page70/E1/1.jpg' />
            </div>
          </div>
        `,
        answer: [
          "any",
          "some",
          "any",
          "any",
          "any",
          "some",
          "any",
          "any",
          "some",
          "some",
          "any",
          "some",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P70-E2',
    audio: 'img/FriendsPlus/Page70/Audio/audio-e2-p70.mp3',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page70/E2/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page70/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/2.jpg', audioUrl: "img/FriendsPlus/Page70/Audio/tieude-e2-p70.mp3" },
        { url: 'img/FriendsPlus/Page70/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page70/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/5.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page70/E2/6.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page70/E2/7.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page70/E2/10.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page70/E2/11.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/12.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page70/E2/13.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/14.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page70/E2/15.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/16.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page70/E2/17.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/18.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/19.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page70/E2/20.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page70/E2/21.jpg' },
        { url: 'img/FriendsPlus/Page70/E2/22.jpg', input: 5, isCorrect: true },
        { url: 'img/FriendsPlus/Page70/E2/23.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page70/E2/24.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page70/E2/25.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 10',
    id: 'WB5-U10-P70-E3',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page70/E3/Key/answerKey.png',
    inputSize: 800,
    // titleImage: "img/FriendsPlus/Page70/E3/1.jpg",
    titleQuestion: [{ num: '2', title: 'Write a sentence for each word.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style="  line-height: 58px;">
            <div><b>1</b> (finished) #</div>
            <div><b>2</b> (painted) #</div>
            <div><b>3</b> (rained) #</div>
            <div><b>4</b> (showed) #</div>
            <div><b>5</b> (waited) #</div>
          </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },

}

export default json;