import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import TextEditer from '../../components/ExcerciseTypes/TextEditer';


const json = {

	// 1: { // Exercise num
	// 	unit: 'Unit 6',
	// 	id: 'WB5-U6-P44-E1',
	// 	audio: '',
	// 	video: '',
	// 	component: D1,
	// 	exerciseKey: '',
	// 	questionImage: [ // Row
	// 		[
	// 			// Column1
	// 			{ url: 'img/FriendsPlus/Page44/2.jpg' },

	// 		],
	// 	],
	// },
	1: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P44-E1',
		audio: '',
		video: '',
		component: TextEditer,
		exerciseKey: 'img/FriendsPlus/Page44/E1/Key/answerKey.png',
		// titleImage: "img/FriendsPlus/Page13/E1/1.jpg",
		titleQuestion: [{ num: '1', title: 'Write <b style=" border: 1px solid; width: 30px; height: 30px; display: inline-block; text-align: center; ">’</b> in the correct places.', color: "#5B5A5D" }],
		questionImage: [],
		question: {
			editer: {
				editerStyle: {},
				initialValue: "1 This player ' s bag is green. \n 2 Look at this bird. Its wings are really beautiful. \n 3 Janes team won at basketball. \n 4 The schools new soccer team is playing tonight. \n 5 The boys jacket is red and yellow. \n 6 Do you like my new bike? Its a racing bike. \n 7 James Naismiths new sport was named basketball.",
				answer: "1 This player ' s bag is green. \n 2 Look at this bird. Its wings are really beautiful. \n 3 Jane's team won at basketball. \n 4 The school's new soccer team is playing tonight. \n 5 The boy's jacket is red and yellow. \n 6 Do you like my new bike? It's a racing bike. \n 7 James Naismith's new sport was named basketball.",
			},
			Layout: `
        <div>
          <div style=" width: 700px; "><editer></editer></div>
        </div>
      `
		}
	},
	2: { // Exercise num
		unit: 'Unit 6',
		id: 'WB5-U6-P44-E2',
		audio: '',
		video: '',
		component: D1,
		exerciseKey: 'img/FriendsPlus/Page44/E2/Key/answerKey.png',
		questionImage: [ // Row
			[
				// Column1
				{ url: 'img/FriendsPlus/Page44/E2/1.jpg' },
			],
			[
				// Column2
				{ url: 'img/FriendsPlus/Page44/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page44/E2/3.jpg', input: true, answer: "court" },
				{ url: 'img/FriendsPlus/Page44/E2/4.jpg' },
			],
			[
				// Column3
				{ url: 'img/FriendsPlus/Page44/E2/5.jpg' },
			],
			[
				// Column4
				{ url: 'img/FriendsPlus/Page44/E2/6.jpg' },
				{ url: 'img/FriendsPlus/Page44/E2/7.jpg', input: true, answer: "five" },
				{ url: 'img/FriendsPlus/Page44/E2/8.jpg' },
			],
			[
				// Column5
				{ url: 'img/FriendsPlus/Page44/E2/9.jpg' },
			],
			[
				// Column6
				{ url: 'img/FriendsPlus/Page44/E2/10.jpg' },
				{ url: 'img/FriendsPlus/Page44/E2/11.jpg', input: true, answer: "circle" },
				{ url: 'img/FriendsPlus/Page44/E2/12.jpg' },
			],
			[
				// Column7
				{ url: 'img/FriendsPlus/Page44/E2/13.jpg' },
			],
			[
				// Column8
				{ url: 'img/FriendsPlus/Page44/E2/14.jpg' },
				{ url: 'img/FriendsPlus/Page44/E2/15.jpg', input: true, answer: "points" },
				{ url: 'img/FriendsPlus/Page44/E2/16.jpg' },
			],
			[
				// Column9
				{ url: 'img/FriendsPlus/Page44/E2/17.jpg' },
				{ url: 'img/FriendsPlus/Page44/E2/18.jpg', input: true, answer: "basket." },
				{ url: 'img/FriendsPlus/Page44/E2/19.jpg' },
			],
			[
				// Column10
				{ url: 'img/FriendsPlus/Page44/E2/20.jpg' },
			],
			[
				// Column11
				{ url: 'img/FriendsPlus/Page44/E2/21.jpg' },
				{ url: 'img/FriendsPlus/Page44/E2/22.jpg', input: true, answer: "outside" },
				{ url: 'img/FriendsPlus/Page44/E2/23.jpg' },
			],
			[
				// Column12
				{ url: 'img/FriendsPlus/Page44/E2/24.jpg' },
			],

		],
	},


}

export default json;