import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  3: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P85-E3',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page85/E3/Key/answerKey.png',
    inputSize: 600,
    titleImage: "img/FriendsPlus/Page85/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'You are having a party. Choose the kind of party from the words below.<br/> Then answer the questions.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" line-height: 48px; ">
          <div>When is the party?#</div>
          <div>What will you do at the party?#</div>
          <div>Where is the party?#</div>
          <div>What time does it start?#</div>
          <div>What time does it finish?#</div>
          <div>What do your friends need to wear? #</div>
        </div>
        `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 12',
    id: 'WB5-U12-P85-E4',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page85/E4/Key/answerKey.png',
    textareaStyle: { width: 820 },
    // titleImage: "img/FriendsPlus/Page85/E4/1.jpg",
    // titleQuestion: [{ num: '4', title: 'Write.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style=" position: relative; ">
          <div> <img src='img/FriendsPlus/Page85/E4/1.jpg' /> </div>
          <div style="position: absolute;top: 184px;left: 76px;"><textarea id="0" rows="6"></textarea></div> 
        </div>
        `,
        answer: [
          "",
        ],
      },
    ],
  },
}

export default json;