import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P10-E1',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page10/E1/Key/answerKey.png',
    inputSize: 300,
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [{ num: '1', title: 'Write answers that are true for you. Write <i>Yes, I did</i> or <i>No, I didn’t</i>', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style='line-height: 58px;'>
            <div><b>1</b> Did you play soccer last weekend? # </div>
            <div><b>2</b> Did you listen to music last weekend? # </div>
            <div><b>3</b> Did you watch television last weekend? # </div>
            <div><b>4</b> Did you play the guitar last weekend? # </div>
            <div><b>5</b> Did you cook last weekend? # </div>
            <div><b>6</b> Did you help your mom last weekend? # </div>
          </div>
          `,
        answer: [
          "",
          "",
          "",
          "",
          "",
          "",
        ],
      },
    ]
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P10-E2',
    audio: '',
    video: '',
    component: T6,
    hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page10/E2/Key/answerKey.png',
    inputSize: 300,
    // titleImage: 'img/FriendsPlus/Page10/E2/title.jpg',
    titleQuestion: [{ num: '2', title: 'Write things you did and didn’t do last weekend.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
          <div style='line-height: 58px;'>
            <div><b>1</b> I last # weekend.</div>
            <div><b>2</b> I last # weekend.</div>
            <div><b>3</b> I didn’t # last weekend.</div>
            <div><b>4</b> I didn’t # last weekend.</div>
          </div>
          `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P10-E3',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page10/E3/Key/answerKey.png',
    component: D1,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page10/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page10/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/3.jpg', input: true, answer: "work." },
        { url: 'img/FriendsPlus/Page10/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page10/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/6.jpg', input: true, answer: "I didn't swim." },
        { url: 'img/FriendsPlus/Page10/E3/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page10/E3/8.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/9.jpg', input: true, answer: "I didn’t read a book." },
        { url: 'img/FriendsPlus/Page10/E3/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page10/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/12.jpg', input: true, answer: "I didn’t play computer games." },
        { url: 'img/FriendsPlus/Page10/E3/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page10/E3/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page10/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/16.jpg', input: true, answer: "a party with my friends" },
        { url: 'img/FriendsPlus/Page10/E3/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page10/E3/18.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/19.jpg', input: true, answer: " sang and danced all day" },
        { url: 'img/FriendsPlus/Page10/E3/20.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page10/E3/21.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/22.jpg', input: false, answer: "" },
        { url: 'img/FriendsPlus/Page10/E3/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page10/E3/24.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/25.jpg', input: false, answer: "" },
        { url: 'img/FriendsPlus/Page10/E3/26.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page10/E3/27.jpg' },
        { url: 'img/FriendsPlus/Page10/E3/28.jpg', input: false, answer: "" },
        { url: 'img/FriendsPlus/Page10/E3/29.jpg' },
      ],
      [
        // Column12
        { url: 'img/FriendsPlus/Page10/E3/30.jpg' },
      ],
    ],
  },
}

export default json;