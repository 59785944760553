import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P12-E1',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 4,
    exerciseKey: 'img/FriendsPlus/Page12/E1/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E1/4.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page12/E1/5.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page12/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/7.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E1/8.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page12/E1/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page12/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/11.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page12/E1/12.jpg', input: 3, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E1/13.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page12/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page12/E1/15.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E1/16.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page12/E1/17.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page12/E1/18.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P12-E2',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page12/E2/Key/answerKey.png',
    inputSize: 200,
    hideBtnFooter: true,
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [{ num: '2', title: 'Write about your bed.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title:
          `
            <div>
              <img src='img/FriendsPlus/Page12/E2/1.jpg' />
            </div>
            <div style='line-height: 58px;'>
              <div><span><b>1</b> My bed is#.</span> <span style=" margin-left: 20px; "><b>2</b> It’s#.</span></div>
              <div><span><b>3</b> It isn’t#. </span> <span style=" margin-left: 60px; "><b>4</b> It isn’t#.</span></div>
            </div>            
          `,
        answer: [
          "",
          "",
          "",
          "",
        ],
      },
    ]
  },
  3: { // Exercise num
    unit: 'Unit 1',
    id: 'WB5-U1-P12-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 3,
    exerciseKey: 'img/FriendsPlus/Page12/E3/Key/answerKey.png',
    titleImage: '',
    isAllowSubmit: true,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page12/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page12/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/3.jpg', input: 1, isCorrect: true },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page12/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/5.jpg', input: 1 },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page12/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page12/E3/7.jpg', input: 1 },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page12/E3/8.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page12/E3/9.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page12/E3/10.jpg', input: 3 },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page12/E3/11.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page12/E3/12.jpg', input: 3, isCorrect: true },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page12/E3/13.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page12/E3/14.jpg', input: 3 },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page12/E3/15.jpg' },
      ],

    ],
  },
}

export default json;