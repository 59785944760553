import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  1: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P4-E1',
    audio: '',
    video: '',
    component: D1,
    checkDuplicated: true,
    checkUppercase: true,
    exerciseKey: 'img/FriendsPlus/Page4/E1/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page4/E1/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page4/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/3.jpg', input: true, answer: "camel/zebra/lizard" },
        { url: 'img/FriendsPlus/Page4/E1/4.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/5.jpg', input: true, answer: "noodles/bread/meat" },
        { url: 'img/FriendsPlus/Page4/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/7.jpg', input: true, answer: "fall/winter/summer" },
        { url: 'img/FriendsPlus/Page4/E1/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page4/E1/9.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/10.jpg', input: true, answer: "Australia/Mexico" },
        { url: 'img/FriendsPlus/Page4/E1/11.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/12.jpg', input: true, answer: "camel/zebra/lizard" },
        { url: 'img/FriendsPlus/Page4/E1/13.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/14.jpg', input: true, answer: "noodles/bread/meat" },
        { url: 'img/FriendsPlus/Page4/E1/15.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/16.jpg', input: true, answer: "fall/winter/summer" },
        { url: 'img/FriendsPlus/Page4/E1/17.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page4/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/19.jpg', input: true, answer: "Australia/Mexico" },
        { url: 'img/FriendsPlus/Page4/E1/20.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/21.jpg', input: true, answer: "camel/zebra/lizard" },
        { url: 'img/FriendsPlus/Page4/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/23.jpg', input: true, answer: "noodles/bread/meat" },
        { url: 'img/FriendsPlus/Page4/E1/24.jpg' },
        { url: 'img/FriendsPlus/Page4/E1/25.jpg', input: true, answer: "fall/winter/summer" },
        { url: 'img/FriendsPlus/Page4/E1/26.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page4/E1/27.jpg' },
      ],
    ],
  },
  5: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P4-E2',
    audio: '',
    video: '',
    component: D1,
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page4/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page4/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/3.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page4/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page4/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/6.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page4/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page4/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/9.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page4/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page4/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/12.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page4/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page4/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page4/E2/15.jpg', input: true, answer: "" },
        { url: 'img/FriendsPlus/Page4/E2/16.jpg' },
      ],
    ]
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'WB5-S-P4-E2',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page4/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Read the story. Choose a word from 1. Write the correct word next to numbers 1–5', color: "#5B5A5D" }],
    question: {
      Write: {
        inputStyle: { width: 430, },
        answers: ['', '', '', '', ''],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { border: 'solid 3px', borderRadius: '50%', borderColor: 'transparent' },
        selectWordStyle: { padding: 5, borderColor: '#4285F4' },
        limitSelect: 1,
        listWords: [
          'wider / concert / higher / deeper', //0
          'helicopter / motorcycle / taxi / museum', //1
          'actor / friendly / mean / worried', //2
          'surf / cave / sail / kayak', //3
          'path / flowers / shout / grass', //4
        ],
        answers: ['0-4', '1-12', '2-0', '3-4', '4-8'],
        initialValue: [],
      },
      Layout: `
      <div style=" display: flex; flex-direction: row; line-height: 58px; justify-content: space-between;width: 950px;">
        <div>
          <div><b>1</b> mountain / ocean / <span style=" border: 3px solid gray; border-radius: 50%; padding: 5px;">camera</span> / waterfall</div>
          <div><b>2</b> <input id='0' type='Circle' /></div>
          <div><b>3</b> <input id='1' type='Circle' /></div>
          <div><b>4</b> <input id='2' type='Circle' /></div>
          <div><b>5</b> <input id='3' type='Circle' /></div>
          <div><b>6</b> <input id='4' type='Circle' /></div>
        </div>
        <div>
          <div><u style="color:gray;"> I like taking photos with my camera. &ensp;</u></div>
          <div><input id='0' /></div>
          <div><input id='1' /></div>
          <div><input id='2' /></div>
          <div><input id='3' /></div>
          <div><input id='4' /></div>
        </div>
      </div>
      `,
    },
  },
}
export default json