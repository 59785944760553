import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P38-E1',
    audio: '',
    video: '',
    component: T6,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page38/E1/Key/answerKey.png",
    inputSize: 40,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '1', title: 'Write a period or an exclamation point.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 48px;">
          <div><b>1</b> Giang shouted, “Hurry up <u style="color:gray;">&nbsp; ! </u> ”</div>
          <div><b>2</b> Minh said, “It was a good day #”</div>
          <div><b>3</b> The man was angry. He said, “Don’t touch the models #”</div>
          <div><b>4</b> Ly said, “I watched TV yesterday #”</div>
          <div><b>5</b> “Mom, I passed all my tests #” Long said.</div>
          <div><b>6</b> “Don’t be late #” Dad said.</div>
        </div>
        `,
        answer: [
          ".",
          "!",
          ".",
          "!",
          "!",
        ],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P38-E2',
    audio: '',
    video: '',
    component: D1,
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page38/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Read the four sentences with exclamation points again.<br/> Write <i>F</i> for strong feelings or <i>I</i> for important instructions.', color: "#5B5A5D" }],
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page38/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page38/E2/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/3.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page38/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page38/E2/5.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/6.jpg', input: true, answer: "I" },
        { url: 'img/FriendsPlus/Page38/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page38/E2/8.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/9.jpg', input: true, answer: "none" },
        { url: 'img/FriendsPlus/Page38/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page38/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/12.jpg', input: true, answer: "F" },
        { url: 'img/FriendsPlus/Page38/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page38/E2/14.jpg' },
        { url: 'img/FriendsPlus/Page38/E2/15.jpg', input: true, answer: "I" },
        { url: 'img/FriendsPlus/Page38/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page38/E2/17.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P38-E3',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page38/E3/Key/answerKey.png",
    inputSize: 140,
    // titleImage: "img/FriendsPlus/Page32/E3/1.jpg",
    titleQuestion: [{ num: '3', title: 'Write the verbs in the simple past.', color: "#5B5A5D" }],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="line-height: 48px;display: flex;justify-content: space-around;flex-wrap: wrap;font-size: 26px;width: 900px;">
          <div style=" width: 200px; display: flex; justify-content: space-between; "><span>sits </span> <u style="color:gray;">&emsp;&emsp; sat &emsp;&emsp;</u></div>
          <div style=" width: 200px; display: flex; justify-content: space-between; "> be #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; "> wash #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; "> sing#</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">find #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">go #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">talk#</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">walk#</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">learn #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">see #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">sleep #</div>
          <div style=" width: 200px; display: flex; justify-content: space-between; ">eat #</div>
        </div>
        `,
        answer: [
          "was/were", "washed", "sang",
          "found", "went", "talked", "walked",
          "learned", "saw", "slept", "ate",
        ],
      },
    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'WB5-U5-P38-E4',
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page38/E4/Key/answerKey.png',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page38/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page38/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/3.jpg', input: true, answer: "sat" },
        { url: 'img/FriendsPlus/Page38/E4/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page38/E4/5.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/6.jpg', input: true, answer: "sang" },
        { url: 'img/FriendsPlus/Page38/E4/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page38/E4/8.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/9.jpg', input: true, answer: "talked" },
        { url: 'img/FriendsPlus/Page38/E4/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page38/E4/11.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/12.jpg', input: true, answer: "saw" },
        { url: 'img/FriendsPlus/Page38/E4/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page38/E4/14.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page38/E4/15.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/16.jpg', input: true, answer: "found" },
        { url: 'img/FriendsPlus/Page38/E4/17.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page38/E4/18.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/19.jpg', input: true, answer: "ate" },
        { url: 'img/FriendsPlus/Page38/E4/20.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/21.jpg', input: true, answer: "walked" },
        { url: 'img/FriendsPlus/Page38/E4/22.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page38/E4/23.jpg' },
      ],
      [
        // Column10
        { url: 'img/FriendsPlus/Page38/E4/24.jpg' },
        { url: 'img/FriendsPlus/Page38/E4/25.jpg', input: true, answer: "slept" },
        { url: 'img/FriendsPlus/Page38/E4/26.jpg' },
      ],
      [
        // Column11
        { url: 'img/FriendsPlus/Page38/E4/27.jpg' },
      ],

    ],
  },
}

export default json;